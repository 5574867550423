import clsx from 'clsx'

const VideoAdModalRewardIcon = ({ url, className }) => {
  if (!url) {
    return null
  }

  return (
    <div
      className={clsx('flex justify-center items-center flex-col', className)}
    >
      <img
        src={url}
        alt='rewardIcon'
        className='w-[25vw] h-[25vw] object-contain'
      />
    </div>
  )
}

export default VideoAdModalRewardIcon
