import clsx from 'clsx'

const VideoAdModalDescription = ({ description, className, style }) => {
  return (
    <div className='relative w-full flex justify-center items-center'>
      <div
        className={clsx(
          'flex justify-center items-center flex-col text-[4.4vw] break-words text-[#442B22] text-center whitespace-pre-line',
          className,
        )}
        style={style}
      >
        {description}
      </div>
    </div>
  )
}

export default VideoAdModalDescription
