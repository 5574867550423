import { observer } from 'mobx-react-lite'
import moment from 'moment'
import React, { useState } from 'react'
import InitModalStore from 'store/InitModalStore'
import UserStore from 'store/UserStore'
import backendApis from 'utils/backendApis'
import centerConfetti from '../../../json/gifJson/confetti/centerConfetti.json'
import LottieGif from '../LottieGif'

const InitModal = observer(
  ({ farmData, setFarmData, setShowPopup, setResourceType, setAddWater }) => {
    const [isClicked, setIsClicked] = useState(false)
    const initModalList = InitModalStore?.isInitModal
    const currentModal = initModalList?.[initModalList?.length - 1]

    if (initModalList?.length <= 0) return

    const timeFormat = moment(new Date()).format('HH')
    const cheerupGganbuTime = timeFormat >= '07' && timeFormat < '22'

    if (currentModal?.type === 'cheerupGganbu' && !cheerupGganbuTime) return
    if (currentModal?.type === 'purchaseReward') {
      const rewardTypeKOR = [
        {
          rewardType: 'fertilizerSet',
          rewardTypeKOR: '고급비료',
          imgSrc: '/icon/fertilizerSet.png',
        },
        {
          rewardType: 'fertilizer',
          rewardTypeKOR: '일반비료',
          imgSrc: '/icon/fertilizer.png',
        },
        { rewardType: 'water', rewardTypeKOR: '물', imgSrc: '/icon/water.png' },
      ]

      if (
        !UserStore?.purchaseRewardNotification?.find(
          (item) => item._id.toString() === currentModal?.title?.toString(),
        )?.rewardList ||
        // rewardTypeKOR에 있는 rewardType이 아닌 경우
        UserStore?.purchaseRewardNotification
          ?.find(
            (item) => item._id.toString() === currentModal?.title?.toString(),
          )
          ?.rewardList?.some(
            (item) =>
              !rewardTypeKOR?.find(
                (reward) => reward.rewardType === item.rewardType,
              ),
          )
      ) {
        return
      }

      // rewardList에서 보상 정보를 추출
      const rewards =
        UserStore?.purchaseRewardNotification?.find(
          (item) => item._id.toString() === currentModal?.title?.toString(),
        )?.rewardList || []

      // 문구 설정
      const rewardText = rewards
        ?.map((item) => {
          const rewardInfo = rewardTypeKOR?.find(
            (reward) => reward.rewardType === item.rewardType,
          )
          if (item.rewardType === 'water') {
            return `${rewardInfo?.rewardTypeKOR} ${item.rewardAmount}g`
          } else {
            return `${rewardInfo?.rewardTypeKOR} ${item.rewardAmount}개`
          }
        })
        .join(', ')

      // 마지막 보상 종류에 따른 조사 처리
      const lastReward = rewards[rewards.length - 1]
      const lastRewardText = rewardText.endsWith('g')
        ? '을 받았어요!'
        : '를 받았어요!'

      // 이미지 필터링
      const rewardImages = rewards.map((item) => {
        const rewardInfo = rewardTypeKOR?.find(
          (reward) => reward?.rewardType === item?.rewardType,
        )
        return rewardInfo?.imgSrc
      })

      return (
        <>
          <div className='flex absolute top-0 left-0 w-full h-full z-[140]'>
            <div className='bg-black absolute w-full h-full opacity-80' />
            <LottieGif
              animationData={centerConfetti}
              className='absolute z-[140] top-[50vw]'
            />
            <img
              className='absolute w-[100vw] top-[80vw] left-[50vw] transform translate-x-[-50%] translate-y-[-50%] p-[4vw]'
              src='/modal/purchaseRewardModal.png'
              alt='purchaseReward'
            />
            <div className='absolute w-[60vw] h-[55vw] top-[107vw] left-[50%] transform translate-x-[-50%] translate-y-[-50%] p-[4vw]'>
              <div className='flex w-full justify-center'>
                {/* 보상 이미지 출력 */}
                {rewardImages.map((src, index) => (
                  <>
                    <img
                      key={index}
                      className='w-[18vw] mt-[4vw] mx-[1vw]'
                      alt='reward'
                      src={src}
                    />
                  </>
                ))}
              </div>
              <div className='text-black text-center text-[4.8vw] mt-[6vw]'>
                {/* 보상 텍스트 출력 */}
                {rewardText + lastRewardText}
              </div>
            </div>
            <button
              className='absolute w-[60vw] h-[12vw] top-[145vw] left-[50%] transform translate-x-[-50%] translate-y-[-50%] flex justify-center z-[150]'
              onClick={async () => {
                await backendApis.updatePurchaseRewardNotifiedAt({
                  logId: currentModal?.title,
                })

                InitModalStore?.setIsInitModal(
                  [...InitModalStore?.isInitModal].slice(0, -1),
                )
                await backendApis.updateSpecificField({
                  fieldName: 'initModalList',
                  value: [],
                })
              }}
            >
              <img
                className='w-[80%] h-auto'
                src='/icon/greenConfirmButton.png'
                alt='confirm'
              />
            </button>
          </div>
        </>
      )
    }

    return (
      <>
        <div
          style={{
            flex: 1,
            position: 'absolute',
            left: '0',
            top: '0',
            bottom: '0',
            right: '0',
            zIndex: 30000,
          }}
        >
          <div
            style={{
              backgroundColor: 'black',
              position: 'absolute',
              overlay: {
                background: 'rgba(0,0,0,0)',
              },
              width: '100%',
              height: '100%',
              opacity: '0.8',
            }}
          />
          <div
            style={{
              background:
                'linear-gradient(180deg, #FFF0BB 0%, #FFE176 3.65%, #FFBC39 94.79%, #F99219 100%)',
              width: '70vw',
              zIndex: 110,
              borderRadius: '4vw',
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%,-50%)',
              padding: '4vw',
            }}
          >
            <div
              style={{
                color: '#402C24',
                fontFamily: 'maplestory',
                fontSize: '4.5vw',
                zIndex: 30001,
                textAlign: 'center',
                lineHeight: '150%',
                background: 'white',
                borderRadius: '2vw',
                padding: '4vw',
                flexDirection: 'column',
                display: 'flex',
                wordBreak: 'keep-all',
                wordWrap: 'break-word',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              {currentModal?.title ?? '오류가 발생했어요'}

              {(currentModal?.type === 'potatoPetGift' ||
                currentModal?.type === 'rewardedAdfertilizerSet') && (
                <img
                  style={{
                    width: '20vw',
                    marginTop: '4vw',
                  }}
                  alt='pet gift'
                  src='/icon/fertilizerSet.png'
                />
              )}

              {(currentModal?.type === 'kindergardenPetGift' ||
                currentModal?.type === 'rewardedAdWater' ||
                currentModal?.type === 'eventPetGift' ||
                currentModal?.type === 'browsingCPCVAd') && (
                <img
                  style={{
                    width: '20vw',
                    marginTop: '4vw',
                  }}
                  alt='pet gift'
                  src='/icon/water.png'
                />
              )}

              <br />
              {currentModal?.description ?? '재접속을 시도해주세요'}

              {currentModal?.type === 'alranchReward' && (
                <div className='flexCol text-black'>
                  <br />

                  <div className='text-black'>
                    올목장 종료 및 올팜 통합에 따른 보상 지급 안내드립니다.
                  </div>

                  <div className='flexCol bg-[#F6F3E9] w-full pt-[2vw] rounded-[4vw] gap-[2vw] px-[2vw] text-black my-[2vw]'>
                    <div className=' text-[#8F8F91] text-[3.5vw]'>
                      올목장 경험치
                    </div>
                    <div className=' text-black'>
                      {Math.min(currentModal.milk / 100).toFixed(2)}%
                    </div>
                  </div>
                  <div className='flexCol bg-[#F6F3E9] w-full pt-[2vw] rounded-[4vw] gap-[3vw] px-[2vw]'>
                    <div className=' text-[#8F8F91] text-[3.5vw]'>
                      보상 지급 내역
                    </div>
                    <div className='flex justify-center items-center gap-[2vw]'>
                      <div className='flexCol gap-[1vw]'>
                        <div className='w-[16vw] h-[16vw] bg-white flexCol rounded-xl'>
                          <img src='/icon/water.png' className='h-[vw]' />
                        </div>
                        <div className='text-[4vw] text-black'>
                          {currentModal.water + 'g'}
                        </div>
                      </div>
                      <div className='flexCol gap-[1vw]'>
                        <div className='w-[16vw] h-[16vw] bg-white flexCol rounded-xl'>
                          <img src='/icon/fertilizer.png' className='h-[vw]' />
                        </div>
                        <div className='text-[4vw] text-black'>
                          {currentModal.fertilizer + '개'}
                        </div>
                      </div>
                      <div className='flexCol gap-[1vw]'>
                        <div className='w-[16vw] h-[16vw] bg-white flexCol rounded-xl'>
                          <img
                            src='/icon/fertilizerSet.png'
                            className='h-[vw]'
                          />
                        </div>
                        <div className='text-[4vw] text-black'>
                          {currentModal.fertilizerSet + '개'}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
              <button
                style={{
                  justifyContent: 'center',
                  textAlign: 'center',
                  width: '50%',
                  height: '14vw',
                  marginTop: '8%',
                  zIndex: 999,
                  borderRadius: '444vw',
                  background:
                    'linear-gradient(180deg, #FFE99A 0%, #F7D046 31.77%, #F6CB45 64.58%, #F09236 100%)',
                  fontFamily: 'maplestory',
                  color: '#402C24',
                  fontSize: '4vw',

                  marginBottom: '0vw',
                }}
                onClick={async () => {
                  if (currentModal?.type === 'cheerupGganbu') {
                    if (isClicked) return
                    setIsClicked(true)
                    const result = await backendApis.fightingGganbu()
                    if (result?.data?.success) {
                      let tmpFarmData = farmData
                      tmpFarmData.water += result?.data?.water
                      setAddWater(result?.data?.water)
                      setResourceType('water')
                      setShowPopup(true)
                      setFarmData(tmpFarmData)
                    }
                  }

                  InitModalStore?.setIsInitModal(
                    [...InitModalStore?.isInitModal].slice(0, -1),
                  )
                  await backendApis.updateSpecificField({
                    fieldName: 'initModalList',
                    value: [],
                  })
                }}
              >
                <div
                  style={{
                    color: '#402C24',
                    fontFamily: 'maplestory',
                    fontSize: '5vw',
                  }}
                >
                  확인
                </div>
              </button>
            </div>
          </div>
        </div>
      </>
    )
  },
)

export default InitModal
