import React, { useState, useEffect } from 'react'
import dayjs from 'dayjs'
import { observer } from 'mobx-react-lite'
import Log from '../../utils/log'
import backendApis from '../../utils/backendApis'
import WhiteTextTimer from '../WhiteTextTimer'
import Assets from '../../utils/assets'
import useCheckCodePushVersion from '../../utils/customHooks/useConvertCodePushVersion'
import BottomSheetStore from '../../store/BottomSheetStore'
import ModalStore from '../../store/ModalStore'
import TimeStore from '../../store/TimeStore'
import AB from '../../utils/ab_v3'
import { useNavigate } from 'react-router-dom'
import UserStore from '../../store/UserStore'
import DealAutomationStore from 'store/DealAutomationStore'
import BottomSheetPurchaseQuestFertilizer from 'comps/molecules/BottomSheetPurchaseQuestFertilizer'
import ABv2 from 'utils/abv2'
import BottomSheetHeader from 'comps/molecules/BottomSheetHeader'
import ImageText from 'utils/imageText'
import ABStore from 'store/ABStore'
import { AdBannerSection } from '@wetripod/alwayz-frontend-shared'

const FertilizerBottomsheet = observer(
  ({
    farmData,
    setFarmData,
    token,
    setShowPopup,
    singleDealInfo,
    setResourceType,
    setAddFertilizer,
    setAddFertilizerSet,
    giveWaterCount,
    userCodePushVersion,
    openIndicator,
    setOpenIndicator,
    gganbuData,
    setGganbuData,
    setCanClickGiveWater,
    setCanClickSellerFarm,
    setLackWaterEngageType,
    altoonData,
    setAltoonData,
    altoonFertMissionList,
    dramaData,
    setDramaData,
    dramaFertMissionList,
  }) => {
    const [
      fertilizerBottomSheetInfoChecker,
      setFertilizerBottomSheetInfoChecker,
    ] = useState()
    const [canClickButton, setCanClickButton] = useState(true)
    const [canClickGiveFert, setCanClickGiveFert] = useState(true)
    const [canClickGiveFertSet, setCanClickGiveFertSet] = useState(true)
    const [checkInCount, setCheckInCount] = useState(0)
    const [canCheckIn, setCanCheckIn] = useState(false)
    const [lastCheckedAt, setLastCheckedAt] = useState(new Date())
    const [isButtonPressed35, setIsButtonPressed35] = useState(false)
    const [isButtonPressed10, setIsButtonPressed10] = useState(false)
    const [isLoadingToItemScreen, setIsLoadingToItemScreen] = useState(false)
    const [gganbuLength, setGganbuLength] = useState(0)
    const [clearedPurchaseRewardCount, setClearedPurchaseRewardCount] =
      useState(0)
    const [isLoading, setIsLoading] = useState(true)
    const [clearedInviteGganbuFertFirst, setClearedInviteGganbuFertFirst] =
      useState(false)
    const [clearedInviteGganbuFertSecond, setClearedInviteGganbuFertSecond] =
      useState(false)
    const [scrollIndicator, setScrollIndicator] = useState(false)
    const [adItemBrowsingFertilizerList, setAdItemBrowsingFertilizerList] =
      useState(0)
    const [
      adItemBrowsingFertilizerAvailable,
      setAdItemBrowsingFertilizerAvailable,
    ] = useState(false)
    const [showLatencyModal, setShowLatencyModal] = useState(false)
    const [level, setLevel] = useState('')
    const [canClickGganbuButton, setCanClickGganbuButton] = useState('false')
    const [clearedAltoonMissionOne, setClearedAltoonMissionOne] = useState(
      altoonData?.missionOneClearedAt,
    )
    const [clearedAltoonMissionTwo, setClearedAltoonMissionTwo] = useState(
      altoonData?.missionTwoClearedAt,
    )
    const [clearedAltoonMissionFive, setClearedAltoonMissionFive] = useState(
      altoonData?.missionFiveClearedAt,
    )

    const [clearedDramaMissionOne, setClearedDramaMissionOne] = useState(
      dramaData?.missionOneClearedAt,
    )
    const [clearedDramaMissionTwo, setClearedDramaMissionTwo] = useState(
      dramaData?.missionTwoClearedAt,
    )
    const [clearedDramaMissionFive, setClearedDramaMissionFive] = useState(
      dramaData?.missionFiveClearedAt,
    )

    const [
      newbiePurchaseRewardFertilizersetAvailable,
      setNewbiePurchaseRewardFertilizersetAvailable,
    ] = useState(false)
    const [isLoaded, setIsLoaded] = useState(false)
    const [styleSelect, setStyleSelect] = useState(true)
    const [showInfoIcon, setShowInfoIcon] = useState(false)
    const [infoContent, setInfoContent] = useState()

    const [is60CherryPickingUser, setIs60CherryPickingUser] = useState(false)

    useEffect(() => {
      const isCherryPickUser = async () => {
        const MS_PER_DAY = 1000 * 60 * 60 * 24
        const SIXTY_DAYS = MS_PER_DAY * 60

        const lastPurchaseDate =
          UserStore?.purchaseHistorys?.[UserStore?.purchaseHistorys?.length - 1]
            ?.purchasedAt
        const timeSinceLastPurchase = lastPurchaseDate
          ? new Date() - new Date(lastPurchaseDate)
          : null
        const timeSinceCreation = new Date() - new Date(farmData?.createdAt)

        const isEligible = !farmData?.cherryPickPurchased
        const hasNoPurchases = !UserStore?.purchaseHistorys

        // 60일 체리피커 체크
        if (
          isEligible &&
          ((timeSinceLastPurchase && timeSinceLastPurchase > SIXTY_DAYS) ||
            (hasNoPurchases && timeSinceCreation > SIXTY_DAYS))
        ) {
          setIs60CherryPickingUser(true)
        }
      }
      isCherryPickUser()
    }, [farmData?.cherryPickPurchased, farmData?.createdAt])

    const codePushVersionAvailable = useCheckCodePushVersion()

    const naverWinnerDealLogic = AB(farmData?.userId, 'naverWinnerDealLogic', [
      50,
    ])

    let showDramaFertMission = null
    if (dramaFertMissionList) {
      const keyForAB = dramaFertMissionList?.keyForAB || 'showDramaMission1010'
      const abRatio = dramaFertMissionList?.abRatio || [5, 6, 100]
      showDramaFertMission = AB(
        farmData?.userId,
        keyForAB, // AB_v3임
        abRatio, // a: 5%, b: 1%, c: 94%
      )
    }

    const navigate = useNavigate()

    const shareKakao = () => {
      closeBottomSheet()
      const inviteData = {
        shareType: 'AlfarmGganbuInvite',
        // referralCode: farmData?.referralCode,
        title: '[올팜] 우리 맞팜해요!',
        description: '서로의 농장에서 물과 비료를 받을 수 있어요.',
        bannerImage:
          'https://assets.ilevit.com/7ab3f811-7cfb-4108-a3b9-d9b3bfc13867.png',
      }
      window.location.href = `#openAlfarmRemovedDependencyInviteModal.${JSON.stringify(
        inviteData,
      )}`
    }

    useEffect(() => {
      setTimeout(() => {
        setStyleSelect(false)
      }, 650)

      const headerRef = document.querySelector('[data-rsbs-header="true"]')
      const headerStyle = {
        height: headerRef.style.height,
        marginTop: headerRef.style.marginTop,
        boxShadow: headerRef.style.boxShadow,
      }
      headerRef.style.height = '5vw'
      headerRef.style.marginTop = '-50vw'
      headerRef.style.boxShadow = 'none'

      const contentRef = document.querySelector('[data-rsbs-content="true"]')
      const contentStyle = {
        marginTop: contentRef.style.marginTop,
      }

      contentRef.style.marginTop = '50vw'

      return () => {
        headerRef.style.height = headerStyle.height
        headerRef.style.marginTop = headerStyle.marginTop
        headerRef.style.boxShadow = headerStyle.boxShadow
        contentRef.style.marginTop = contentStyle.marginTop
      }
    }, [styleSelect, BottomSheetStore?.isBottomSheetOpen])

    useEffect(() => {
      let observer = new IntersectionObserver((e) => {
        if (e?.[0].isIntersecting) {
          setScrollIndicator(false)
        } else {
          setScrollIndicator(true)
        }
      })
      let div = document.querySelector('#fertBottomSheetEnd')
      observer?.observe(div)
    }, [])

    useEffect(() => {
      if (BottomSheetStore.isBottomSheetOpen === 'fertilizer') {
        dailyCheckInFertilizerQuest(token)
        getFertilizerBottomSheetInfo()
        getGganbuList()
      }
    }, [BottomSheetStore.isBottomSheetOpen])

    const getFertilizerBottomSheetInfo = async () => {
      setIsLoaded(false)
      const result = await backendApis.getFertilizerBottomSheetInfo(
        token,
        'GET',
        {
          isFrom: 'bottomSheet',
        },
      )

      setFertilizerBottomSheetInfoChecker(
        result?.fertilizerBottomSheetInfoChecker,
      )

      let clearedPurchaseRewardCount
      if (result?.dailyPurchaseRewardFertClear === undefined) {
        clearedPurchaseRewardCount = 0
      } else if (
        result?.fertilizerBottomSheetInfoChecker?.purchaseRewardAvailable ===
        false
      ) {
        clearedPurchaseRewardCount = result?.dailyPurchaseRewardFertClear.length
      } else {
        clearedPurchaseRewardCount = 0
      }

      setAdItemBrowsingFertilizerList(
        result?.fertilizerBottomSheetInfoChecker?.adItemBrowsingFertilizerList
          ?.length,
      )
      setAdItemBrowsingFertilizerAvailable(
        new Date() -
          new Date(
            result?.fertilizerBottomSheetInfoChecker
              ?.adItemBrowsingFertilizerList[
              result?.fertilizerBottomSheetInfoChecker
                ?.adItemBrowsingFertilizerList?.length - 1
            ]?.clearedAt ?? '2022-01-01',
          ) >
          1000 * 60 * 60 * 4,
      )

      setClearedPurchaseRewardCount(clearedPurchaseRewardCount)
      let tempFarmData = { ...farmData }
      tempFarmData.fertilizer = result?.fertilizer
      tempFarmData.fertilizerSet = result?.fertilizerSet
      tempFarmData.dailyPurchaseRewardFertClear =
        result?.dailyPurchaseRewardFertClear
      tempFarmData.questLogFromRecommendItemLatestCheckedAt =
        result?.questLogFromRecommendItemLatestCheckedAt
      tempFarmData.newbiePurchaseRewardFertilizerset =
        result?.newbiePurchaseRewardFertilizerset
      tempFarmData.rewardAdClearedAt = result?.rewardAdClearedAt
      setFarmData(tempFarmData)
      if (!result?.newbiePurchaseRewardFertilizerset) {
        if (
          giveWaterCount > 15 &&
          codePushVersionAvailable(userCodePushVersion, '1.6.36') &&
          TimeStore.currentNewDate - new Date(farmData?.createdAt) <=
            1000 * 60 * 60 * 24 * 3 &&
          farmData?.isNewUser
        ) {
          setNewbiePurchaseRewardFertilizersetAvailable(true)
        } else setNewbiePurchaseRewardFertilizersetAvailable(false)
      } else if (!!result?.newbiePurchaseRewardFertilizerset) {
        setNewbiePurchaseRewardFertilizersetAvailable(false)
      }
      setIsLoaded(true)
    }
    const getGganbuList = async () => {
      const result = await backendApis.getGganbuList(token)

      if (!result?.data?.gganbuList) {
        setGganbuLength(0)
      } else if (result?.data?.gganbuList) {
        setGganbuLength(result?.data?.gganbuList?.length)
        setGganbuData(result?.data)
        window.localStorage.setItem('gganbuData', JSON.stringify(result?.data))
      }

      if (result?.data?.inviteFertFirst) {
        setClearedInviteGganbuFertFirst(true)
      }

      if (result?.data?.inviteFertSecond) {
        setClearedInviteGganbuFertSecond(true)
      }
      if (!result?.data?.inviteFertFirst) {
        setLevel('fertFirst')
        setCanClickGganbuButton(true)
      } else if (
        result?.data?.inviteFertFirst &&
        !result?.data?.inviteFertSecond
      ) {
        setLevel('fertSecond')
        setCanClickGganbuButton(true)
      } else {
        setLevel('')
      }
    }

    const giveFert = async () => {
      if (canClickGiveFert) {
        setCanClickGiveFert(false)
        const result = await backendApis.giveFert(token)

        let tmpFarmData = farmData
        tmpFarmData.fertilizer -= result?.data?.point
        tmpFarmData.nutriment += result?.data?.nutriment

        setFarmData(tmpFarmData)
        UserStore.set('farmData.nutriment', tmpFarmData.nutriment)

        Log.send({
          action: 'giveFert',
          user_id: farmData?.userId,
        })
        setOpenIndicator(false)
        setCanClickGiveFert(true)
      }
    }

    const giveFertSet = async () => {
      if (canClickGiveFertSet) {
        setCanClickGiveFertSet(false)
        const result = await backendApis.giveFertSet(token)
        let tmpFarmData = farmData
        tmpFarmData.fertilizerSet -= result?.data?.point
        tmpFarmData.nutriment += result?.data?.nutriment
        UserStore.set('farmData.nutriment', tmpFarmData.nutriment)

        if (tmpFarmData.fertilizerSet < 0) {
          //팝업
          let random = Math.random()
          if (random > 0.5) {
            setLackWaterEngageType('vipCard')
            ModalStore.setIsModalOpen('lackWaterEngageModal')
          }
        }
        setFarmData(tmpFarmData)

        Log.send({
          action: 'giveFertSet',
          user_id: farmData?.userId,
        })
        setCanClickGiveFertSet(true)
      }
    }

    const closeBottomSheet = () => {
      setCanClickGiveWater(false)
      setCanClickSellerFarm(false)
      BottomSheetStore.setIsbottomSheetOpen('basic')
      setTimeout(() => {
        setCanClickGiveWater(true)
        setCanClickSellerFarm(true)
      }, 1000)
    }

    const FertLevelIcon = () => {
      const content = () => {
        return (
          <div className='flex flex-col justify-center items-center pt-[2vw]'>
            <div className='text-[5vw] text-[#fff] w-full text-center mb-[-1vw]'>
              양분
            </div>
            <div className='text-[8vw] text-[#fff] w-full text-center'>
              {farmData?.nutriment}
            </div>
          </div>
        )
      }

      if (farmData?.nutriment < 75 && farmData?.nutriment > 1) {
        return (
          <div
            className={`bg-[#7ED321] border-[1.5vw] bordet-[#FFEAB5] w-[100%] h-[25vw] rounded-[100vw]`}
          >
            {content()}
          </div>
        )
      }
      if (farmData?.nutriment >= 75) {
        return (
          <div className='bg-gradient-to-b from-[#F2BA73] to-[#ED7732] border-[1.5vw] border-[#FFEAB5] w-[100%] h-[25vw] rounded-[100vw]'>
            {content()}
          </div>
        )
      }

      if (farmData?.nutriment <= 1) {
        return (
          <div
            className={`bg-[#A4ACB4] border-[1.5vw] bordet-[#FFEAB5] w-[100%] h-[25vw] rounded-[100vw]`}
          >
            {content()}
          </div>
        )
      }
    }

    const dailyCheckInFertilizerQuest = async () => {
      const result = await backendApis.dailyCheckInFertilizerQuestData(token)
      setLastCheckedAt(new Date(result?.data?.lastCheckAt))
      setCheckInCount(result?.data?.length)
      setCanCheckIn(result?.data?.success)
      setIsLoading(false)
    }

    const attendanceDailyFert = async () => {
      if (!canCheckIn || checkInCount >= 3) {
        return false
      }

      const result = await backendApis.questDailyAttendanceFert(token)

      if (result?.status === 200) {
        let tmpFarmData = farmData
        tmpFarmData.fertilizer += result?.data?.addedValue?.addedFertilizer
        if (tmpFarmData?.questLogForAttendanceFert) {
          const tmpLoginLogs = [
            ...tmpFarmData?.questLogForAttendanceFert,
            TimeStore.currentDayJS,
          ]
          tmpFarmData.questLogForAttendanceFert = tmpLoginLogs
        } else {
          tmpFarmData.questLogForAttendanceFert = [TimeStore.currentDayJS]
        }
        closeBottomSheet()

        setResourceType('fertilizer')
        setAddFertilizer(result?.data?.addedValue?.addedFertilizer)
        setShowPopup(true)
        setFarmData(tmpFarmData)
        if (checkInCount <= 1 && farmData?.resurrectBuonus) {
          ModalStore.setIsModalOpen('resurrectBonusAttendanceEvent', {
            imgType: 'fertToday',
          })
        } else if (farmData?.resurrectBuonus) {
          ModalStore.setIsModalOpen('resurrectBonusAttendanceEvent', {
            imgType: 'fertTomorrow',
          })
        }
      }
    }

    const recommendQuest = async () => {
      if (canClickButton) {
        setCanClickButton(false)
        const questRecommend = {
          screen: 'AlFarmQuestLinkRecommendItem',
        }
        if (fertilizerBottomSheetInfoChecker?.recommendQuestAvailable) {
          closeBottomSheet()

          window.location.href = `#questRecommend.${JSON.stringify(
            questRecommend,
          )}`
        }
        setCanClickButton(true)
      }
    }

    const adItemBrowsingQuest = async () => {
      if (isLoadingToItemScreen) return
      setTimeout(() => {
        setIsLoadingToItemScreen(false)
      }, 5000)
      const result = await backendApis.getBrowsingAdItem(token)
      setIsLoadingToItemScreen(true)
      if (result?.status === 200) {
        const adItemBrowsing = {
          text: '올팜',

          isTimerOn: true,
          timerText: `초간 구경하면 일반비료 1개를 받아요`,
          isTimerEndText: '축하합니다! 물 10g을 받았어요',

          itemId: result?.data?._id,
          enteringComponent: 'Alfarm_browsingAdQuest',
          source:
            'https://assets.ilevit.com/dc0816b9-b6a8-40cd-9a9e-2f6bb0a218c7.png',
          isAdItem: result?.data?.adInfo?.isAdItem,
          itemInfo: result?.data,
        }

        if (
          adItemBrowsingFertilizerList < 3 &&
          adItemBrowsingFertilizerAvailable
        ) {
          closeBottomSheet()
          window.location.href = `#adItemBrowsing.${JSON.stringify(
            adItemBrowsing,
          )}`
        }
      } else {
        setShowLatencyModal(true)
        setTimeout(() => {
          setShowLatencyModal(false)
        }, 3500)
      }
    }

    const DailyPurchaseRewardFert = async () => {
      const purchaseRewardFert = {
        screen: 'AlfarmPurchaseRewardScreen',
        rewardType: 'fert',
      }
      closeBottomSheet()
      window.location.href = `#purchaseRewardFert.${JSON.stringify(
        purchaseRewardFert,
      )}`
    }

    const clearedInviteGganbuQuestFert = async (level) => {
      if (!canClickGganbuButton) {
        return false
      }
      if (level === '') {
        return false
      }
      if (canClickGganbuButton) {
        setCanClickGganbuButton(false)
        const result = await backendApis.clearedInviteGganbuQuestFert(
          token,
          'POST',
          {
            level: level,
          },
        )
        if (result.status === 200) {
          setCanClickGganbuButton(false)
          let tmpFarmData = farmData
          tmpFarmData.fertilizerSet += result?.data?.fertilizerSet
          closeBottomSheet()
          setResourceType('fertilizerSet')
          setAddFertilizerSet(result?.data?.fertilizerSet)
          setShowPopup(true)
          setFarmData(tmpFarmData)
          getGganbuList()
        }
        setCanClickGganbuButton(true)
      }
    }

    const newbiePurchaseRewardFertilizerset = async () => {
      const purchaseData = {
        enteringComponent: 'Alfarm_newbiePurchaseRewardFertilizerset',
        text: '팜린이 고급비료 혜택관',
        source:
          'https://assets.ilevit.com/e152d0ab-825b-4e0a-a45f-2677e7a8d010.png',
        rewardAmount: 10,
        rewardType: '고급비료',
        rewardText: `상품을 구매하면 고급비료 10개를 받아요`,
      }

      window.location.href = `#commonPurchaseReward.${JSON.stringify(
        purchaseData,
      )}`
      closeBottomSheet()
    }

    const ImageSpriteWinnerDeal1 = () => {
      if (
        TimeStore.currentDayJS >
          dayjs(DealAutomationStore.firstWinnerDealData.timeRangeStart) &&
        TimeStore.currentDayJS <
          dayjs(DealAutomationStore.firstWinnerDealData.timeRangeEnd)
      ) {
        return {
          modalImage: DealAutomationStore.firstWinnerDealData.background,
          purchaseScreenBanner:
            DealAutomationStore.firstWinnerDealData.purchaseScreenBanner,
          purchaseScreenText:
            DealAutomationStore.firstWinnerDealData.purchaseScreenText,
        }
      }
    }

    const ImageSpriteWinnerDeal2 = () => {
      if (
        TimeStore.currentDayJS >
          dayjs(DealAutomationStore.secondWinnerDealData.timeRangeStart) &&
        TimeStore.currentDayJS <
          dayjs(DealAutomationStore.secondWinnerDealData.timeRangeEnd)
      ) {
        return {
          modalImage: DealAutomationStore.secondWinnerDealData.background,
          purchaseScreenBanner:
            DealAutomationStore.secondWinnerDealData.purchaseScreenBanner,
          purchaseScreenText:
            DealAutomationStore.secondWinnerDealData.purchaseScreenText,
        }
      }
    }

    const ImageSpriteWinnerDeal3 = () => {
      if (
        TimeStore.currentDayJS >
          dayjs(DealAutomationStore.thirdWinnerDealData.timeRangeStart) &&
        TimeStore.currentDayJS <
          dayjs(DealAutomationStore.thirdWinnerDealData.timeRangeEnd)
      ) {
        return {
          modalImage: DealAutomationStore.thirdWinnerDealData.background,
          purchaseScreenBanner:
            DealAutomationStore.thirdWinnerDealData.purchaseScreenBanner,
          purchaseScreenText:
            DealAutomationStore.thirdWinnerDealData.purchaseScreenText,
        }
      }
    }

    const NavigateButton = observer(
      ({ buttonText = '이동하기', token, itemType }) => {
        const [buttonClicked, setButtonClicked] = useState(false)
        const navigate = useNavigate()

        return (
          <button
            className={`right-0 absolute py-[2vw] text-[#fff] font-bold text-[4vw] rounded-[2vw] translate-y-[-50%] top-[50%] z-[100] mr-[4vw] w-[21%]
            ${buttonClicked ? `bg-[#ABE066]` : `bg-[#7ED321]`}`}
            onPointerDown={() => {
              setButtonClicked(true)
            }}
            onPointerCancel={() => {
              setButtonClicked(false)
            }}
            onPointerUp={() => {
              setButtonClicked(false)
            }}
            onClick={(event) => {
              event.preventDefault()
              event.stopPropagation()
              navigate(`../friendGarden?token=${token}&itemType=${itemType}`)
            }}
          >
            {buttonText}
          </button>
        )
      },
    )

    const ModalOpenButton = observer(
      ({ buttonText = '구매하기', modalName = 'weeklyBingoPurchase' }) => {
        const [buttonClicked, setButtonClicked] = useState(false)

        return (
          <button
            className={`right-0 absolute py-[2vw] text-[#fff] font-bold text-[4vw] rounded-[2vw] translate-y-[-50%] top-[50%] z-[100] mr-[4vw] w-[21%] ${
              buttonClicked ? `bg-[#ABE066]` : `bg-[#7ED321]`
            }`}
            onPointerDown={() => {
              setButtonClicked(true)
            }}
            onPointerCancel={() => {
              setButtonClicked(false)
            }}
            onPointerUp={() => {
              setButtonClicked(false)
            }}
            onClick={(event) => {
              event.preventDefault()
              event.stopPropagation()

              ModalStore.setIsModalOpen(modalName)
            }}
          >
            {buttonText}
          </button>
        )
      },
    )

    const DailyFertButton = () => {
      const [buttonClicked, setButtonClicked] = useState(false)

      if (canCheckIn) {
        return (
          <button
            className={`right-0 absolute w-[21%] z-[111] font-bold mr-[4vw] py-[2vw] text-[4vw] text-[#fff] rounded-[2vw] translate-y-[-50%] top-[50%] ${
              buttonClicked ? `bg-[#FFDA75]` : `bg-[#FFC700]`
            }`}
            onPointerDown={() => {
              setButtonClicked(true)
            }}
            onPointerCancel={() => {
              setButtonClicked(false)
            }}
            onPointerUp={() => {
              setButtonClicked(false)
              attendanceDailyFert()
            }}
            // onClick={}
          >
            받기
          </button>
        )
      } else if (!canCheckIn && checkInCount >= 1 && checkInCount <= 2) {
        return (
          <>
            <button
              className={`right-0 absolute w-[21%] z-[111] mr-[4vw] py-[2vw] text-[4vw] text-[#fff] rounded-[2vw] translate-y-[-50%] top-[50%]`}
            >
              <div className='text-[#442b22]'>
                {!isLoading && (
                  <div>
                    <WhiteTextTimer
                      onTimeEnd={() => {
                        dailyCheckInFertilizerQuest()
                        getFertilizerBottomSheetInfo()
                        getGganbuList()
                      }}
                      timeStamp={lastCheckedAt}
                      timerMinutes={30}
                      color='#442b22'
                    />
                  </div>
                )}
                <div className='text-[#442b22]'>
                  {3 - checkInCount}번 더 가능
                </div>
              </div>
            </button>
          </>
        )
      } else if (checkInCount > 2) {
        return (
          <button
            className={`right-0 absolute py-[2vw] text-[#442b22] text-[4vw] rounded-[2vw] translate-y-[-50%] top-[50%] z-[100] mr-[4vw] w-[21%]`}
          >
            <div className='text-[#442b22]'>내일 가능</div>
          </button>
        )
      } else {
        return (
          <button
            className={`right-0 absolute py-[2vw] text-[#442b22] text-[4vw] rounded-[2vw] translate-y-[-50%] top-[50%] z-[100] mr-[4vw] w-[21%]`}
          >
            <div className='text-[#442b22]'>내일 가능</div>
          </button>
        )
      }
    }

    const RecommendQuestButton = () => {
      const [buttonClicked, setButtonClicked] = useState(false)

      if (fertilizerBottomSheetInfoChecker?.recommendQuestAvailable) {
        return (
          <button
            className={`right-0 absolute py-[2vw] text-[#fff] font-bold text-[4vw] rounded-[2vw] translate-y-[-50%] top-[50%] z-[100] mr-[4vw] w-[21%]
          ${buttonClicked ? `bg-[#ABE066]` : `bg-[#7ED321]`}`}
            onPointerDown={() => {
              setButtonClicked(true)
            }}
            onPointerCancel={() => {
              setButtonClicked(false)
            }}
            onPointerUp={() => {
              setButtonClicked(false)
              recommendQuest()
            }}
            // onClick={}
          >
            구경하기
          </button>
        )
      } else {
        return (
          <button
            className={`right-0 absolute py-[2vw] text-[#442b22] text-[4vw] rounded-[2vw] translate-y-[-50%] top-[50%] z-[100] mr-[4vw] w-[21%]`}
          >
            <div className='text-[#442b22]'>내일 가능</div>
          </button>
        )
      }
    }

    const FastArrivalShopScreen = () => {
      const [buttonClicked, setButtonClicked] = useState(false)

      return (
        <button
          className={`right-0 absolute py-[2vw] text-[#fff] font-bold text-[4vw] rounded-[2vw] translate-y-[-50%] top-[50%] z-[100] mr-[4vw] w-[21%]
        ${buttonClicked ? `bg-[#ABE066]` : `bg-[#7ED321]`}`}
          onPointerDown={() => {
            setButtonClicked(true)
          }}
          onPointerCancel={() => {
            setButtonClicked(false)
          }}
          onPointerUp={() => {
            setButtonClicked(false)
          }}
          onClick={() => {
            window.location.href = `#generalNavigator.${JSON.stringify({
              screen: 'AlfarmFastArrivalShopScreen',
              params: {
                dealType: '',
                title: '바로도착 전용관',
                image:
                  'https://assets.ilevit.com/e1a15b79-764b-47bb-a969-5315feaeffbd.png',
                ratio: 99 / 360,
                enteringComponent: 'Alfarm_fastArrivalShopScreen',
                enteringLocation: 'fert_bottomSheet',
              },
            })}`
          }}
        >
          구매하기
        </button>
      )
    }

    // tenMinDeal
    const TenMinDealButton = () => {
      const [buttonClicked, setButtonClicked] = useState(false)

      return (
        <button
          className={`right-0 absolute py-[2vw] text-[#fff] font-bold text-[4vw] rounded-[2vw] translate-y-[-50%] top-[50%] z-[100] mr-[4vw] w-[21%]
        ${buttonClicked ? `bg-[#ABE066]` : `bg-[#7ED321]`}`}
          onPointerDown={() => {
            setButtonClicked(true)
          }}
          onPointerCancel={() => {
            setButtonClicked(false)
          }}
          onPointerUp={() => {
            setButtonClicked(false)
          }}
          onClick={() => {
            window.location.href = `#generalNavigator.${JSON.stringify({
              screen: 'TenMinDealScreen',
              params: {
                imageBannerUri:
                  'https://assets.ilevit.com/7fce66c5-cbfc-48ca-a939-ffe2e46772d1.png',
                ratio: 99 / 360,
                enteringComponent: 'Alfarm_tenMinDealScreen',
                initialComponent: 'Alfarm_tenMinDealScreen',
                isFrom: 'Alfarm_tenMinDealScreen',
              },
            })}`
          }}
        >
          구매하기
        </button>
      )
    }

    const AdItemBrowsingQuestButton = () => {
      const [buttonClicked, setButtonClicked] = useState(false)

      if (
        adItemBrowsingFertilizerList < 3 &&
        adItemBrowsingFertilizerAvailable
      ) {
        return (
          <button
            className={`right-0 absolute py-[2vw] text-[#fff] font-bold text-[4vw] rounded-[2vw] translate-y-[-50%] top-[50%] z-[100] mr-[4vw] w-[21%]
          ${buttonClicked ? `bg-[#ABE066]` : `bg-[#7ED321]`}`}
            onPointerDown={() => {
              setButtonClicked(true)
            }}
            onPointerCancel={() => {
              setButtonClicked(false)
            }}
            onPointerUp={() => {
              setButtonClicked(false)
              adItemBrowsingQuest()
            }}
          >
            {isLoadingToItemScreen ? <> 로딩중..</> : <> 구경하기</>}
          </button>
        )
      } else if (
        adItemBrowsingFertilizerList < 3 &&
        !adItemBrowsingFertilizerAvailable
      ) {
        return (
          <>
            <button
              className={`right-0 absolute py-[2vw] text-[#442b22] text-[4vw] rounded-[2vw] translate-y-[-50%] top-[50%] z-[100] mr-[4vw] w-[21%]`}
            >
              <div className='text-[#442b22] '>
                {!isLoading && (
                  <div>
                    <WhiteTextTimer
                      onTimeEnd={() => {
                        setAdItemBrowsingFertilizerAvailable(true)
                      }}
                      timeStamp={
                        farmData?.adItemBrowsingFertilizer
                          ? new Date(
                              farmData?.adItemBrowsingFertilizer[
                                farmData?.adItemBrowsingFertilizer?.length - 1
                              ]?.clearedAt,
                            )
                          : new Date()
                      }
                      timerMinutes={60 * 4}
                      color='#442b22'
                    />
                  </div>
                )}
                <div className='text-[#442b22]'>
                  {3 - adItemBrowsingFertilizerList}번 더 가능
                </div>
              </div>
            </button>
          </>
        )
      } else if (adItemBrowsingFertilizerList >= 3) {
        return (
          <button
            className={`right-0 absolute py-[2vw] text-[#442b22] text-[4vw] rounded-[2vw] translate-y-[-50%] top-[50%] z-[100] mr-[4vw] w-[21%]`}
          >
            <div className='text-[#442b22]'>내일 가능</div>
          </button>
        )
      } else {
        return (
          <button
            className={`right-0 absolute py-[2vw] text-[#442b22] text-[4vw] rounded-[2vw] translate-y-[-50%] top-[50%] z-[100] mr-[4vw] w-[21%]`}
          >
            <div className='text-[#442b22]'>내일 가능</div>
          </button>
        )
      }
    }
    const AlfarmDealPurchaseButton = () => {
      const [buttonClicked, setButtonClicked] = useState(false)

      return (
        <button
          className={`right-0 absolute py-[2vw] text-[#fff] font-bold text-[4vw] rounded-[2vw] translate-y-[-50%] top-[50%] z-[100] mr-[4vw] w-[21%]
        ${buttonClicked ? `bg-[#ABE066]` : `bg-[#7ED321]`}`}
          onPointerDown={() => {
            setButtonClicked(true)
          }}
          onPointerCancel={() => {
            setButtonClicked(false)
          }}
          onPointerUp={() => {
            setButtonClicked(false)
            ModalStore.setIsModalOpen('alfarmDeal')
          }}
          // onClick={}
        >
          구매하기
        </button>
      )
    }

    const DailyPurchaseRewardFertButton = () => {
      const [buttonClicked, setButtonClicked] = useState(false)

      if (clearedPurchaseRewardCount >= 2) {
        return (
          <button
            className={`right-0 absolute py-[2vw] text-[#442b22] text-[4vw] rounded-[2vw] translate-y-[-50%] top-[50%] z-[100] mr-[4vw] w-[21%]`}
          >
            <div className='text-[#442b22]'>내일 가능</div>
          </button>
        )
      } else if (clearedPurchaseRewardCount < 2) {
        return (
          <button
            className={`right-0 absolute py-[2vw] text-[#fff] font-bold text-[4vw] rounded-[2vw] translate-y-[-50%] top-[50%] z-[100] mr-[4vw] w-[21%]
            ${buttonClicked ? `bg-[#ABE066]` : `bg-[#7ED321]`}`}
            onPointerDown={() => {
              setButtonClicked(true)
            }}
            onPointerCancel={() => {
              setButtonClicked(false)
            }}
            onPointerUp={() => {
              setButtonClicked(false)
              DailyPurchaseRewardFert()
            }}
            // onClick={}
          >
            구매하기
          </button>
        )
      } else {
        return (
          <button
            className={`right-0 absolute py-[2vw] text-[#442b22] text-[4vw] rounded-[2vw] translate-y-[-50%] top-[50%] z-[100] mr-[4vw] w-[21%]`}
          >
            <div className='text-[#442b22]'>내일 가능</div>
          </button>
        )
      }
    }

    const QuestComponent = ({
      iconSrc,
      title,
      subtitle,
      buttonComponent,
      subtitleIconSrc,
      infoContent = '',
    }) => {
      return (
        <div className='relative flex px-[4vw] pt-[3vw] pb-[4vw] bg-white border-b-[0.1vw] border-[#D3D7DD]'>
          <div className='flex items-center'>
            <img
              src={iconSrc}
              alt=''
              className='mr-[2vw] w-[10.6vw] h-[10.6vw]'
            />
            <div>
              <div className='flex flex-row gap-[1.6vw] justify-center items-center '>
                <div className='text-[4.4vw] text-[#525964]'>{title}</div>
                {infoContent !== '' && (
                  <div
                    onClick={(event) => {
                      event.preventDefault()
                      event.stopPropagation()
                      setInfoContent(`${infoContent}`)
                      setShowInfoIcon(true)
                      setTimeout(() => {
                        setShowInfoIcon(false)
                      }, 5000)
                    }}
                  >
                    <img
                      className='w-[5vw] h-[5vw] mb-[1vw]'
                      src='/icon/fertInformation.png'
                      alt=''
                    />
                  </div>
                )}
              </div>
              <div className='flex flex-row items-center'>
                {subtitleIconSrc && (
                  <img
                    src={subtitleIconSrc}
                    alt=''
                    className='mr-[1vw] w-[4vw] h-full'
                  />
                )}
                <div className='text-[3.6vw] text-[#6D7680] '>{subtitle}</div>
              </div>
            </div>
          </div>
          {buttonComponent}
        </div>
      )
    }

    const AltoonMission = ({ missionType, missionCount }) => {
      const [buttonClicked, setButtonClicked] = useState(false)

      const giveMissionReward = async () => {
        if (canClickButton) {
          setCanClickButton(false)
          const result = await backendApis.rewardAltoonFertMissionReward(
            token,
            'PUT',
            {
              missionType,
            },
          )
          if (result?.data?.success === true) {
            let tmpFarmData = farmData
            let tmpAltoonData = altoonData
            tmpFarmData.fertilizer += result?.data?.addedValue?.addedFertilizer

            tmpAltoonData[`${missionType}ClearedAt`] = new Date()
            closeBottomSheet()
            setResourceType('fertilizer')
            setAddFertilizer(result?.data?.addedValue?.addedFertilizer)
            setShowPopup(true)
            setFarmData(tmpFarmData)

            setAltoonData(tmpAltoonData)
          } else {
            // setInviteResultStatus('errorAlert')
            setCanClickButton(true)
            return false
          }
          setCanClickButton(true)
          return true
        } else {
          setCanClickButton(true)
          return false
        }
      }

      if (altoonData?.[`${missionType}ClearedAt`]) {
        return (
          <button
            className={`right-0 absolute w-[21%] z-[111] mr-[4vw] py-[2vw] text-[4vw] text-[#442B22] rounded-[2vw] translate-y-[-50%] top-[50%]`}
          >
            완료
          </button>
        )
      } else if (altoonData?.altoonReadLog?.length >= missionCount) {
        return (
          <button
            className={`right-0 absolute w-[21%] z-[111] font-bold mr-[4vw] py-[2vw] text-[4vw] text-[#fff] rounded-[2vw] translate-y-[-50%] top-[50%] ${
              buttonClicked ? `bg-[#FFDA75]` : `bg-[#FFC700]`
            }`}
            onPointerDown={() => {
              setButtonClicked(true)
            }}
            onPointerCancel={() => {
              setButtonClicked(false)
            }}
            onPointerUp={() => {
              setButtonClicked(false)
              const rewardGiven = giveMissionReward()
              if (rewardGiven === true) {
                if (missionCount === 1) {
                  setClearedAltoonMissionOne(true)
                } else if (missionCount === 2) {
                  setClearedAltoonMissionTwo(true)
                } else if (missionCount === 5) {
                  setClearedAltoonMissionFive(true)
                }
              }
              closeBottomSheet()
            }}
          >
            보상받기
          </button>
        )
      } else {
        return (
          <button
            className={`right-0 absolute py-[2vw] text-[#fff] font-bold text-[4vw] rounded-[2vw] translate-y-[-50%] top-[50%] z-[100] mr-[4vw] w-[21%] ${
              buttonClicked ? `bg-[#ABE066]` : `bg-[#7ED321]`
            }`}
            onPointerDown={() => {
              setButtonClicked(true)
            }}
            onPointerCancel={() => {
              setButtonClicked(false)
            }}
            onPointerUp={() => {
              setButtonClicked(false)
              // const webUrl = "http://172.30.1.77:3001/altoon-main";
              const webUrl = `https://alwayzwebscreen.ilevit.com/stack/altoon-main`
              const redirectSearchParams = new URLSearchParams({
                code: 'alfarmFertMission',
                missionToonId: altoonFertMissionList?.toonId,
                randomString: Math.random().toString(36).slice(2, 10),
              })
              const prop = {
                screen: 'WebScreen',
                prop: {
                  eventPageMapping: {
                    redirectUrl: `${webUrl}/?${redirectSearchParams}`,
                  },
                },
              }
              window.location.href = `#navigate.${JSON.stringify(prop)}`
            }}
          >
            바로가기
          </button>
        )
      }
    }

    const DramaMission = ({ missionType, missionCount }) => {
      const [buttonClicked, setButtonClicked] = useState(false)

      const giveMissionReward = async () => {
        if (canClickButton) {
          setCanClickButton(false)
          const result = await backendApis.rewardDramaFertMissionReward(
            token,
            'PUT',
            {
              missionType,
            },
          )
          if (result?.data?.success === true) {
            let tmpFarmData = farmData
            let tmpDramaData = dramaData
            tmpFarmData.fertilizer += result?.data?.addedValue?.addedFertilizer

            tmpDramaData[`${missionType}ClearedAt`] = new Date()
            closeBottomSheet()
            setResourceType('fertilizer')
            setAddFertilizer(result?.data?.addedValue?.addedFertilizer)
            setShowPopup(true)
            setFarmData(tmpFarmData)

            setDramaData(tmpDramaData)
          } else {
            // setInviteResultStatus('errorAlert')
            setCanClickButton(true)
            return false
          }
          setCanClickButton(true)
          return true
        } else {
          setCanClickButton(true)
          return false
        }
      }

      if (dramaData?.[`${missionType}ClearedAt`]) {
        return (
          <button
            className={`right-0 absolute w-[21%] z-[111] mr-[4vw] py-[2vw] text-[4vw] text-[#442B22] rounded-[2vw] translate-y-[-50%] top-[40%]`}
          >
            완료
          </button>
        )
      } else if (dramaData?.dramaWatchLog?.length >= missionCount) {
        return (
          <button
            className={`right-0 absolute w-[21%] z-[111] font-bold mr-[4vw] py-[2vw] text-[4vw] text-[#fff] rounded-[2vw] translate-y-[-50%] top-[40%] ${
              buttonClicked ? `bg-[#FFDA75]` : `bg-[#FFC700]`
            }`}
            onPointerDown={() => {
              setButtonClicked(true)
            }}
            onPointerCancel={() => {
              setButtonClicked(false)
            }}
            onPointerUp={() => {
              setButtonClicked(false)
              const rewardGiven = giveMissionReward()
              if (rewardGiven === true) {
                if (missionCount === 1) {
                  setClearedDramaMissionOne(true)
                } else if (missionCount === 2) {
                  setClearedDramaMissionTwo(true)
                } else if (missionCount === 5) {
                  setClearedDramaMissionFive(true)
                }
              }
              closeBottomSheet()
            }}
          >
            보상받기
          </button>
        )
      } else {
        return (
          <button
            className={`right-0 absolute py-[2vw] text-[#fff] font-bold text-[4vw] rounded-[2vw] translate-y-[-50%] top-[40%] z-[100] mr-[4vw] w-[21%] ${
              buttonClicked ? `bg-[#ABE066]` : `bg-[#7ED321]`
            }`}
            onPointerDown={() => {
              setButtonClicked(true)
            }}
            onPointerCancel={() => {
              setButtonClicked(false)
            }}
            onPointerUp={() => {
              setButtonClicked(false)
              const webUrl = 'https://alwayz-drama-front.ilevit.com'
              const data = {
                code: 'alfarmFertMission',
                missionDramaId: dramaFertMissionList?.dramaId,
                randomString: Math.random().toString(36).slice(2, 10),
              }
              const redirectSearchParams = new URLSearchParams(data)
              const prop = {
                screen: 'Drama',
                prop: {
                  isFrom: 'alfarm',
                  eventPageMapping: {
                    redirectUrl: `${webUrl}/?${redirectSearchParams}`,
                  },
                },
              }
              window.location.href = `#navigate.${JSON.stringify(prop)}`
            }}
          >
            바로가기
          </button>
        )
      }
    }

    const InviteGganbuQuestFertOne = () => {
      const [buttonClicked, setButtonClicked] = useState(false)
      if (gganbuLength >= 2 && !clearedInviteGganbuFertFirst) {
        return (
          <button
            className={`right-0 absolute w-[21%] z-[111] font-bold mr-[4vw] py-[2vw] text-[4vw] text-[#fff] rounded-[2vw] translate-y-[-50%] top-[50%]
          ${buttonClicked ? `bg-[#FFDA75]` : `bg-[#FFC700]`}            
          `}
            onPointerDown={() => {
              setButtonClicked(true)
            }}
            onPointerCancel={() => {
              setButtonClicked(false)
            }}
            onPointerUp={() => {
              setButtonClicked(false)
              clearedInviteGganbuQuestFert(level)
            }}
          >
            받기
          </button>
        )
      } else if (!clearedInviteGganbuFertFirst) {
        return (
          <button
            className={`right-0 absolute py-[2vw] text-[#fff] font-bold text-[4vw] rounded-[2vw] translate-y-[-50%] top-[50%] z-[100] mr-[4vw] w-[21%]
          ${buttonClicked ? `bg-[#ABE066]` : `bg-[#7ED321]`}`}
            onPointerDown={() => {
              setButtonClicked(true)
            }}
            onPointerCancel={() => {
              setButtonClicked(false)
            }}
            onPointerUp={() => {
              setButtonClicked(false)
            }}
            onClick={shareKakao}
          >
            맞팜하기
          </button>
        )
      }
    }

    const InviteGganbuQuestFertSecond = () => {
      const [buttonClicked, setButtonClicked] = useState(false)

      if (
        gganbuLength >= 3 &&
        clearedInviteGganbuFertFirst &&
        !clearedInviteGganbuFertSecond
      ) {
        return (
          <button
            className={`right-0 absolute w-[21%] z-[111] font-bold mr-[4vw] py-[2vw] text-[4vw] text-[#fff] rounded-[2vw] translate-y-[-50%] top-[50%]
          ${buttonClicked ? `bg-[#FFDA75]` : `bg-[#FFC700]`}            
          `}
            onPointerDown={() => {
              setButtonClicked(true)
            }}
            onPointerCancel={() => {
              setButtonClicked(false)
            }}
            onPointerUp={() => {
              setButtonClicked(false)
              clearedInviteGganbuQuestFert(level)
            }}
          >
            받기
          </button>
        )
      } else if (
        clearedInviteGganbuFertFirst &&
        !clearedInviteGganbuFertSecond
      ) {
        return (
          <button
            className={`right-0 absolute py-[2vw] text-[#fff] font-bold text-[4vw] rounded-[2vw] translate-y-[-50%] top-[50%] z-[100] mr-[4vw] w-[21%]
          ${buttonClicked ? `bg-[#ABE066]` : `bg-[#7ED321]`}`}
            onPointerDown={() => {
              setButtonClicked(true)
            }}
            onPointerCancel={() => {
              setButtonClicked(false)
            }}
            onPointerUp={() => {
              setButtonClicked(false)
            }}
            onClick={shareKakao}
          >
            맞팜하기
          </button>
        )
      }
    }

    const NewbiePurchaseRewardFertilizersetButton = () => {
      const [buttonClicked, setButtonClicked] = useState(false)

      return (
        <button
          className={`right-0 absolute py-[2vw] text-[#fff] font-bold text-[4vw] rounded-[2vw] translate-y-[-50%] top-[50%] z-[100] mr-[4vw] w-[21%]
        ${buttonClicked ? `bg-[#ABE066]` : `bg-[#7ED321]`}`}
          onPointerDown={() => {
            setButtonClicked(true)
          }}
          onPointerCancel={() => {
            setButtonClicked(false)
          }}
          onPointerUp={() => {
            setButtonClicked(false)
          }}
          onClick={newbiePurchaseRewardFertilizerset}
        >
          구매하기
        </button>
      )
    }

    return (
      <div className='max-h-[70vh]'>
        {BottomSheetStore.isBottomSheetOpen === 'fertilizer' &&
        is60CherryPickingUser ? (
          ABStore.badaham1107 ? (
            <div className='absolute top-[-24vw] left-[5vw] w-[90vw] z-[1000] bg-[#f5f7f9] rounded-[4vw]'>
              <AdBannerSection
                product='ALFARM'
                placement='FERTILIZER_BANNER'
                platform={
                  UserStore.offerwallInfo.platform === 'A' ? 'android' : 'ios'
                }
                targetpickTargetId='alfarm-fertilizer-targetpick-ad-container'
                namAdUnitId={`Alwayz_Alfarm_Fertilizer_WebNative_${
                  UserStore.offerwallInfo.platform === 'A' ? 'Android' : 'iOS'
                }-N256497692`}
                namAdSlotElementId='alfarm-fertilizer-nam-ad-container'
                appVersion={userCodePushVersion}
                ifa={UserStore.offerwallInfo.ifa}
                ifv={UserStore.offerwallInfo.ifv}
                enableInternal
                enableBuzzvil={false}
                enableNaver
                enableTargetpick
                adCount={1}
              />
            </div>
          ) : null
        ) : (
          <BottomSheetPurchaseQuestFertilizer
            setFarmData={setFarmData}
            token={token}
            farmData={farmData}
            setResourceType={setResourceType}
            setShowPopup={setShowPopup}
            setAddFertilizerSet={setAddFertilizerSet}
          />
        )}

        <BottomSheetHeader historyModalType={'fertilizerHistory'} title='' />
        <div className='relative w-full max-h-[65vh] overflow-y-scroll flex flex-col'>
          <div className='w-[25%] relative z-[1] self-center flex flex-col justify-center items-center'>
            <FertLevelIcon />
          </div>

          <div className='rounded-[20px] text-center'>
            <div className='h-[1vw]' />
          </div>
          {farmData?.nutriment < 75 ? (
            <div className='gauge-bg-fert'>
              <div
                className='gauge-bar-fert'
                style={{
                  width: `${farmData?.nutriment}%`,
                }}
              />
            </div>
          ) : (
            <div className='gauge-bg-fert75'>
              <div
                className='gauge-bar-fert75'
                style={{
                  width: `${farmData?.nutriment}%`,
                }}
              />
            </div>
          )}
          <div className='flex-row flex-1'>
            <div className='w-[30vw] ml-[54vw]'>
              <img src='/icon/expTwiceBox.png' alt='' />
            </div>
          </div>
          <div className='h-[8px]' />

          <div className='relative flex flex-row w-full h-auto'>
            {/* 고급비료 상태창 */}
            <div
              className={`bg-[#fff4d7] w-[47%] h-auto rounded-[15px] ml-[8px] z-[${
                openIndicator &&
                farmData?.fertilizer === 0 &&
                farmData.fertilizerSet > 0
                  ? 1111
                  : 0
              }]`}
            >
              <div className='flex flex-row flex-1'>
                <div className='relative w-[33%] pt-[16px] ml-[12px]'>
                  <img src={Assets.fertButton.fertSetIcon} alt='' />

                  <div
                    className={`bg-[#EA3323] right-[-10px] text-center rounded-[100vw] py-[0vw] px-[2vw] absolute bottom-[2vw] maplestory text-[${
                      farmData?.fertilizerSet >= 100 ? '4vw' : '5vw'
                    }]`}
                  >
                    x {farmData?.fertilizerSet}
                  </div>
                </div>
                <div>
                  <div className='ml-[4vw] mt-[3vw] text-[5vw] bottom-fert-text'>
                    양분 + 35
                  </div>

                  {farmData?.fertilizerSet < 1 ? (
                    <button
                      style={{
                        background: isButtonPressed35
                          ? 'linear-gradient(180deg, #c2f159 0%, #4fcc2f 100%)'
                          : 'linear-gradient(180deg, #c2f159 0%, #4fcc2f 100%)',
                        boxShadow: isButtonPressed35
                          ? 'inset 3px 3px 4px rgba(79, 19, 0, 0.15)'
                          : '',
                        color: 'white',
                        fontSize: '4vw',
                        paddingLeft: '4vw',
                        paddingRight: '4vw',
                        paddingTop: '2vw',
                        paddingBottom: '2vw',
                        borderRadius: '100vw',
                        marginTop: '2vw',
                        marginLeft: '5vw',
                      }}
                      className='bottom-fert-text'
                      onPointerDown={() => {
                        setIsButtonPressed35(true)
                      }}
                      onPointerCancel={() => {
                        setIsButtonPressed35(false)
                      }}
                      onPointerUp={() => {
                        setIsButtonPressed35(false)
                        if (!farmData?.tutorialClearedAt) {
                          return
                        }
                        let inviteFlag = Math.random()
                        let random = Math.random()

                        if (inviteFlag >= 0.5) {
                          if (random < 0.3) {
                            setLackWaterEngageType('vipCard')
                          } else if (random < 0.8) {
                            setLackWaterEngageType('friendGarden')
                          } else {
                            setLackWaterEngageType('dailyInvite')
                          }
                        } else {
                          if (random < 0.5 && clearedPurchaseRewardCount < 2) {
                            setLackWaterEngageType('normalFertPurchase')
                          } else {
                            setLackWaterEngageType('normalFertPurchase')
                          }
                        }
                        ModalStore.setIsModalOpen('lackWaterEngageModal')
                      }}
                    >
                      얻기
                    </button>
                  ) : (
                    <button
                      style={{
                        background: isButtonPressed35
                          ? 'linear-gradient(180deg, #F2BA73 0%, #ED7732 100%)'
                          : 'linear-gradient(180deg, #F2BA73 0%, #ED7732 100%)',
                        boxShadow: isButtonPressed35
                          ? 'inset 3px 3px 4px rgba(79, 19, 0, 0.15)'
                          : '',

                        color: 'white',
                        fontSize: '4vw',
                        paddingLeft: '4vw',
                        paddingRight: '4vw',
                        paddingTop: '2vw',
                        paddingBottom: '2vw',
                        borderRadius: '100vw',
                        marginTop: '2vw',
                        marginLeft: '5vw',
                      }}
                      className='bottom-fert-text'
                      // onClick={}
                      onPointerDown={() => {
                        setIsButtonPressed35(true)
                      }}
                      onPointerCancel={() => {
                        setIsButtonPressed35(false)
                      }}
                      onPointerUp={() => {
                        setIsButtonPressed35(false)
                        giveFertSet()
                      }}
                    >
                      사용
                    </button>
                  )}
                </div>
              </div>
            </div>
            {/* 일반비료 상태창 */}
            <div className='relative bg-[#fff4d7] w-[47%] h-auto rounded-[15px] ml-[8px] z-[4]'>
              <div
                style={{
                  display: 'flex',
                  flex: 1,
                  flexDirection: 'row',
                }}
              >
                <div
                  style={{
                    width: '33%',
                    paddingTop: '16px',
                    marginLeft: '12px',
                    position: 'relative',
                  }}
                >
                  <img src={Assets.fertButton.fertIcon} alt='' />

                  <div
                    className={`bg-[#EA3323] right-[-10px] text-center rounded-[100vw] py-[0vw] px-[2vw] absolute bottom-[2vw] maplestory text-[${
                      farmData?.fertilizer >= 100 ? '4vw' : '5vw'
                    }]`}
                  >
                    x {farmData?.fertilizer}
                  </div>
                </div>
                <div>
                  <div
                    style={{
                      marginLeft: '4vw',
                      marginTop: '3vw',
                      fontSize: '5vw',
                    }}
                    className='bottom-fert-text'
                  >
                    양분 + 10
                  </div>

                  {farmData?.fertilizer < 1 ? (
                    <button
                      style={{
                        background: isButtonPressed10
                          ? 'linear-gradient(180deg, #c2f159 0%, #4fcc2f 100%)'
                          : 'linear-gradient(180deg, #c2f159 0%, #4fcc2f 100%)',
                        boxShadow: isButtonPressed10
                          ? 'inset 3px 3px 4px rgba(79, 19, 0, 0.15)'
                          : '',
                        color: 'white',
                        fontSize: '4vw',
                        paddingLeft: '4vw',
                        paddingRight: '4vw',
                        paddingTop: '2vw',
                        paddingBottom: '2vw',
                        borderRadius: '100vw',
                        marginTop: '2vw',
                        marginLeft: '5vw',
                      }}
                      className='bottom-fert-text'
                      onPointerDown={() => {
                        setIsButtonPressed10(true)
                      }}
                      onPointerCancel={() => {
                        setIsButtonPressed10(false)
                      }}
                      onPointerUp={() => {
                        setIsButtonPressed10(false)
                        if (!farmData?.tutorialClearedAt) {
                          return
                        }
                        let random = Math.random()
                        if (random < 0.3) {
                          setLackWaterEngageType('vipCard')
                          ModalStore.setIsModalOpen('lackWaterEngageModal')
                        } else if (random < 0.8) {
                          setLackWaterEngageType('friendGarden')
                          ModalStore.setIsModalOpen('lackWaterEngageModal')
                        } else {
                          setLackWaterEngageType('dailyInvite')
                          ModalStore.setIsModalOpen('lackWaterEngageModal')
                        }
                      }}
                    >
                      얻기
                    </button>
                  ) : (
                    <>
                      <button
                        style={{
                          background: isButtonPressed10
                            ? 'linear-gradient(180deg, #F2BA73 0%, #ED7732 100%)'
                            : 'linear-gradient(180deg, #F2BA73 0%, #ED7732 100%)',
                          boxShadow: isButtonPressed10
                            ? 'inset 3px 3px 4px rgba(79, 19, 0, 0.15)'
                            : '',
                          color: 'white',
                          fontSize: '4vw',
                          paddingLeft: '4vw',
                          paddingRight: '4vw',
                          paddingTop: '2vw',
                          paddingBottom: '2vw',
                          borderRadius: '100vw',
                          marginTop: '2vw',
                          marginLeft: '5vw',
                        }}
                        className='bottom-fert-text'
                        // onClick={giveFert}
                        onPointerDown={() => {
                          setIsButtonPressed10(true)
                        }}
                        onPointerCancel={() => {
                          setIsButtonPressed10(false)
                        }}
                        onPointerUp={() => {
                          setIsButtonPressed10(false)
                          giveFert()
                        }}
                      >
                        사용
                      </button>
                    </>
                  )}
                </div>
              </div>

              <div className='h-[1vw]' />
            </div>
          </div>

          <div className='h-[1vw]' />
          <div className='relative'>
            {isLoaded && newbiePurchaseRewardFertilizersetAvailable && (
              <div
                style={{
                  marginTop: '2vw',
                  paddingTop: '2vw',
                  paddingBottom: '2vw',
                  display: 'flex',
                  flexDirection: 'row',
                  backgroundColor: '#3087EE',
                  alignItems: 'center',
                  justifyContent: 'center',
                  fontFamily: 'Maplestory',
                }}
              >
                <WhiteTextTimer
                  onTimeEnd={() => {
                    setFarmData({
                      ...farmData,
                      newbiePurchaseRewardFertilizerset: new Date(),
                    })
                  }}
                  timeStamp={new Date(farmData?.createdAt)}
                  timerMinutes={60 * 24 * 3}
                  interval={1000}
                  startFromMinutes
                  color='white'
                  style={{
                    color: 'yellow',
                    fontFamily: 'Maplestory',
                    fontWeight: 'bold',
                  }}
                  noDays={true}
                />
                <div
                  style={{
                    color: 'white',
                    fontSize: '4vw',
                    fontFamily: 'maplestory',
                    marginLeft: '2vw',
                  }}
                >
                  뒤에 팜린이 전용 구매 혜택이 종료돼요
                </div>
              </div>
            )}

            {isLoaded && newbiePurchaseRewardFertilizersetAvailable && (
              <div
                style={{
                  position: 'relative',
                }}
              >
                <img
                  style={{
                    zIndex: 101,
                    width: '100%',
                  }}
                  src='/icon/newbiePurchaseRewardFertilizerset.png'
                  alt=''
                />

                <NewbiePurchaseRewardFertilizersetButton />
              </div>
            )}
            {singleDealInfo && giveWaterCount > 200 && (
              <div
                style={{
                  position: 'relative',
                }}
              >
                <img
                  style={{
                    zIndex: 2,
                    width: '100%',
                  }}
                  src='/fertilizerBottomSheet/alfarmDeal.png'
                  alt=''
                />
                <AlfarmDealPurchaseButton />
              </div>
            )}
            {giveWaterCount > 100 && !clearedInviteGganbuFertFirst && (
              <div
                style={{
                  position: 'relative',
                }}
              >
                <img
                  style={{
                    zIndex: 1,
                    width: '100%',
                    // position: "absolute",
                  }}
                  src='/fertilizerBottomSheet/gganbuQuestOne.png'
                  alt=''
                />
                {TimeStore.currentDayJS?.format('YYYY-MM-DD') <=
                  '2022-10-23' && (
                  <div
                    style={{
                      zIndex: 1,
                      position: 'absolute',
                      bottom: '20vw',
                      left: '48vw',
                      width: '6%',
                    }}
                    className='wobble-ver-left'
                  >
                    <img src='/icon/newBadge.png' alt='' />
                  </div>
                )}
                <InviteGganbuQuestFertOne />
              </div>
            )}
            {giveWaterCount > 100 &&
              clearedInviteGganbuFertFirst &&
              !clearedInviteGganbuFertSecond && (
                <div
                  style={{
                    position: 'relative',
                  }}
                >
                  <img
                    style={{
                      zIndex: 2,
                      width: '100%',
                      position: 'relative',
                    }}
                    src='/fertilizerBottomSheet/gganbuQuestTwo.png'
                    alt=''
                  />

                  <InviteGganbuQuestFertSecond />
                </div>
              )}

            {/* 매일 출석하기 미션 */}
            <div className='relative flex flex-row justify-between'>
              <img
                className='z-[2] w-full relative'
                src='/fertilizerBottomSheet/attendance.png'
                alt=''
              />
              <DailyFertButton />
            </div>
            {/* tenMinDeal */}
            {giveWaterCount > 50 &&
              farmData?.userId &&
              ABv2(farmData?.userId, 'tenMin1110', [95]) === 'a' &&
              DealAutomationStore.isTenMinDealOpen() &&
              codePushVersionAvailable(userCodePushVersion, '4.3.14') && (
                <div
                  style={{
                    position: 'relative',
                  }}
                >
                  <img
                    style={{
                      zIndex: 2,
                      width: '100%',
                    }}
                    src='/waterBottomSheet/itemBanner/waterBottomSheetQuest-tenMinDeal-ferti.png'
                    alt=''
                  />
                  <TenMinDealButton />
                </div>
              )}
            <div className='relative'>
              <img
                className='z-[101] w-full'
                src='/fertilizerBottomSheet/purchase.png'
                alt=''
              />

              <DailyPurchaseRewardFertButton />
            </div>

            {/* 상품 구경하기 60초 */}

            <div
              style={{
                position: 'relative',
              }}
            >
              <img
                style={{
                  zIndex: 2,
                  width: '100%',
                }}
                src='/fertilizerBottomSheet/browsing.png'
                alt=''
              />
              <RecommendQuestButton />
            </div>

            {/* 15초 상품 구경하기 */}
            {giveWaterCount > 200 &&
              codePushVersionAvailable(userCodePushVersion, '2.2.44') && (
                <div
                  style={{
                    position: 'relative',
                  }}
                >
                  <img
                    style={{
                      zIndex: 101,
                      width: '100%',
                    }}
                    src='/fertilizerBottomSheet/adItemBrowsingQuest.png'
                    alt=''
                  />

                  <AdItemBrowsingQuestButton />
                </div>
              )}
            {/* 리워드 광고 */}
            {/* {giveWaterCount > 80 &&
              ABStore.googleAdTester &&
              codePushVersionAvailable(userCodePushVersion, '4.8.0') && (
                <div
                  style={{
                    position: 'relative',
                  }}
                >
                  <img
                    style={{
                      zIndex: 101,
                      width: '100%',
                    }}
                    src='/fertilizerBottomSheet/rewardAdBanner.png'
                    alt=''
                  />

                  <RewardAdQuestButton />
                </div>
              )} */}

            {/* 빠른 배송 */}
            {codePushVersionAvailable(userCodePushVersion, '5.1.17') &&
              giveWaterCount >= 1000 && (
                <QuestComponent
                  iconSrc='/waterBottomSheet/itemBanner/fertilizerBottomSheetQuest-fastArrival.png'
                  title={`빠른 배송 상품 1개 구매하기`}
                  subtitle='고급비료 7개'
                  subtitleIconSrc='/icon/fertSetIcon.png'
                  buttonComponent={<FastArrivalShopScreen />}
                />
              )}
          </div>

          {codePushVersionAvailable(userCodePushVersion, '2.1.0') &&
            giveWaterCount > 20 && (
              <div
                style={{
                  position: 'relative',
                }}
              >
                <img
                  style={{
                    zIndex: 0,
                    width: '100%',
                    position: 'relative',
                  }}
                  src={'/fertilizerBottomSheet/friendGarden.png'}
                  alt=''
                />
                <NavigateButton
                  buttonText={'이동하기'}
                  token={token}
                  itemType={UserStore?.itemType}
                />
              </div>
            )}

          {codePushVersionAvailable(userCodePushVersion, '2.1.0') &&
            giveWaterCount > 20 && (
              <div
                style={{
                  position: 'relative',
                }}
              >
                <img
                  style={{
                    zIndex: 0,
                    width: '100%',
                    position: 'relative',
                  }}
                  src={'/fertilizerBottomSheet/VIPCard.png'}
                  alt=''
                />
                <ModalOpenButton
                  buttonText={'초대하기'}
                  modalName={'vipCard'}
                />
              </div>
            )}
          {/* 숏드라마 미션 */}
          {!!dramaFertMissionList &&
            codePushVersionAvailable(userCodePushVersion, '6.5.18') &&
            (showDramaFertMission === 'a' ||
              ['61a5a1313a1787613ebc7f2f'].includes(farmData?.userId)) &&
            !clearedDramaMissionOne && (
              <QuestComponent
                iconSrc='/waterBottomSheet/itemBanner/waterBottomSheetQuestIcon-drama.png'
                title={`숏드라마 1화 시청하기 (${Math.min(
                  dramaData?.dramaWatchLog?.length || 0,
                  1,
                )}/1)`}
                subtitle='일반비료 1개'
                subtitleIconSrc='/icon/fertIcon.png'
                buttonComponent={
                  <DramaMission missionType='missionOne' missionCount={1} />
                }
                infoContent={`- 영상 한 편을 끝까지 봐야만 시청한 것으로 인정돼요
              - 본 미션은 예고없이 종료될 수 있어요`}
              />
            )}
          {!!dramaFertMissionList &&
            dramaData?.dramaWatchLog?.length >= 1 &&
            !clearedDramaMissionTwo &&
            clearedDramaMissionOne && (
              <QuestComponent
                iconSrc={
                  '/waterBottomSheet/itemBanner/waterBottomSheetQuestIcon-drama.png'
                }
                title={`숏드라마 2화 시청하기 (${Math.min(
                  dramaData?.dramaWatchLog?.length || 0,
                  2,
                )}/2)`}
                subtitle={'일반비료 1개'}
                subtitleIconSrc='/icon/fertIcon.png'
                buttonComponent={
                  <DramaMission missionType='missionTwo' missionCount={2} />
                }
                infoContent={`- 영상 한 편을 끝까지 봐야만 시청한 것으로 인정돼요'
                - 본 미션은 예고없이 종료될 수 있어요`}
              />
            )}
          {!!dramaFertMissionList &&
            dramaData?.dramaWatchLog?.length >= 2 &&
            !clearedDramaMissionFive &&
            clearedDramaMissionTwo && (
              <QuestComponent
                iconSrc='/waterBottomSheet/itemBanner/waterBottomSheetQuestIcon-drama.png'
                title={`숏드라마 5화 시청하기 (${Math.min(
                  dramaData?.dramaWatchLog?.length || 0,
                  5,
                )}/5)`}
                subtitle='일반비료 3개'
                subtitleIconSrc='/icon/fertIcon.png'
                buttonComponent={
                  <DramaMission missionType='missionFive' missionCount={5} />
                }
                infoContent={`- 영상 한 편을 끝까지 봐야만 시청한 것으로 인정돼요
                  - 본 미션은 예고없이 종료될 수 있어요`}
              />
            )}
          {/* 올툰 미션 */}
          {!clearedAltoonMissionOne && (
            <QuestComponent
              iconSrc='/waterBottomSheet/itemBanner/waterBottomSheetQuestIcon-altoon.png'
              title={`올툰 만화 1화 읽기 (${Math.min(
                altoonData?.altoonReadLog?.length || 0,
                1,
              )}/1)`}
              subtitle='일반비료 1개'
              subtitleIconSrc='/icon/fertIcon.png'
              buttonComponent={
                <AltoonMission missionType='missionOne' missionCount={1} />
              }
              infoContent={`[${
                altoonData?.toonTitle?.length > 10
                  ? altoonData?.toonTitle?.slice(0, 10) + '..'
                  : altoonData?.toonTitle
              }]의 회차를 
              맨 아래까지 읽어야 인정돼요
                (읽은 회차: ${
                  altoonData?.altoonReadLog
                    ? altoonData?.altoonReadLog
                        ?.slice(0, 5)
                        .map((log) => log.chapterTitle)
                        .join(', ')
                    : '없음'
                })

                * 본 미션은 예고없이 종료될 수 있어요
               `}
            />
          )}
          {altoonData?.altoonReadLog?.length >= 1 &&
            !clearedAltoonMissionTwo &&
            clearedAltoonMissionOne && (
              <QuestComponent
                iconSrc='/waterBottomSheet/itemBanner/waterBottomSheetQuestIcon-altoon.png'
                title={`올툰 만화 2화 읽기 (${Math.min(
                  altoonData?.altoonReadLog?.length || 0,
                  2,
                )}/2)`}
                subtitle='일반비료 1개'
                subtitleIconSrc='/icon/fertIcon.png'
                buttonComponent={
                  <AltoonMission missionType='missionTwo' missionCount={2} />
                }
                infoContent={`[${
                  altoonData?.toonTitle?.length > 10
                    ? altoonData?.toonTitle?.slice(0, 10) + '..'
                    : altoonData?.toonTitle
                }]의 회차를 
              맨 아래까지 읽어야 인정돼요
                (읽은 회차: ${
                  altoonData?.altoonReadLog
                    ? altoonData?.altoonReadLog
                        ?.slice(0, 5)
                        .map((log) => log.chapterTitle)
                        .join(', ')
                    : '없음'
                })

                * 본 미션은 예고없이 종료될 수 있어요
               `}
              />
            )}
          {altoonData?.altoonReadLog?.length >= 2 &&
            !clearedAltoonMissionFive && (
              <QuestComponent
                iconSrc='/waterBottomSheet/itemBanner/waterBottomSheetQuestIcon-altoon.png'
                title={`올툰 만화 5화 읽기 (${Math.min(
                  altoonData?.altoonReadLog?.length || 0,
                  5,
                )}/5)`}
                subtitle='일반비료 3개'
                subtitleIconSrc='/icon/fertIcon.png'
                buttonComponent={
                  <AltoonMission missionType='missionFive' missionCount={5} />
                }
                infoContent={`[${
                  altoonData?.toonTitle?.length > 10
                    ? altoonData?.toonTitle?.slice(0, 10) + '..'
                    : altoonData?.toonTitle
                }]의 회차를 
              맨 아래까지 읽어야 인정돼요
                (읽은 회차: ${
                  altoonData?.altoonReadLog
                    ? altoonData?.altoonReadLog
                        ?.slice(0, 5)
                        .map((log) => log.chapterTitle)
                        .join(', ')
                    : '없음'
                })

                * 본 미션은 예고없이 종료될 수 있어요
               `}
              />
            )}

          <div id='fertBottomSheetEnd' />

          <div style={{ height: '4vw' }} />

          {scrollIndicator && (
            <div
              className='bounce-top'
              style={{
                width: 35,
                height: 35,
                // backgroundColor: "red",
                position: 'fixed',
                bottom: 36,
                zIndex: 4,
                left: '50%',
                // transform: "translate(-50%)",
                backgroundImage: 'url(../waterBottomSheet/scrollIndicator.png)',
                backgroundSize: 'contain',
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'center',
              }}
            />
          )}

          {showLatencyModal && (
            <div
              style={{
                flex: 1,
                position: 'fixed',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%,-50%)',
                zIndex: 2000,
                width: '85%',
                background: 'rgba(0,0,0,0.8)',
                borderRadius: '4vw',
                fontFamily: 'maplestory',
                fontSize: '5vw',
                textAlign: 'center',
                paddingTop: '8vw',
                paddingBottom: '8vw',
                paddingRight: '4vw',
                paddingLeft: '4vw',
                lineHeight: '150%',
              }}
            >
              이용자가 많아 이용이 어려워요
              <br />
              잠시 후 다시 시도해주세요
            </div>
          )}

          {showInfoIcon && infoContent && (
            <div
              style={{
                flex: 1,
                position: 'fixed',
                left: '7.5%',
                top: '50vw',
                zIndex: 2000,
                width: '85%',
                background: 'rgba(0,0,0,0.8)',
                borderRadius: '4vw',
                fontFamily: 'maplestory',
                fontSize: '5vw',
                textAlign: 'center',
                paddingTop: '8vw',
                paddingBottom: '8vw',
                paddingRight: '4vw',
                paddingLeft: '4vw',
                lineHeight: '150%',
              }}
            >
              <ImageText
                className='font-thin'
                fontSize={5}
                style={{
                  color: '#8E8D9B',
                }}
                text={infoContent}
              />
            </div>
          )}
        </div>
      </div>
    )
  },
)
export default FertilizerBottomsheet
