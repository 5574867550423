import React, { useEffect, useState } from 'react'
import { CPM_ENTERING_COMPONENTS } from 'utils/alfarmCPMConstant/cpmAdTypes'
import YutThrowAnimation from './YutThrowAnimation'
import ModalStore from 'store/ModalStore'

const CPMAnimationWrapper = ({
  enteringComponent,
  children,
  animationDuration,
}) => {
  const [showAnimation, setShowAnimation] = useState(false)

  useEffect(() => {
    if (!ModalStore.showCPMAdAnimation) return
    setShowAnimation(true)

    // 애니메이션이 완료된 후에 상태 변경
    const timer = setTimeout(() => {
      setShowAnimation(false)
      ModalStore.setShowCPMAdAnimation(false)
    }, animationDuration || 2300) // YutThrowAnimation의 전체 재생 시간과 맞춤

    return () => clearTimeout(timer)
  }, [animationDuration])

  if (!showAnimation) {
    return children
  }

  if (enteringComponent === CPM_ENTERING_COMPONENTS.YUTNORI_AD) {
    return (
      <div className='fixed inset-0 z-[5004] flex items-center justify-center bg-black/80'>
        <div className='relative'>
          <YutThrowAnimation />
        </div>
      </div>
    )
  }

  if (enteringComponent === CPM_ENTERING_COMPONENTS.FORTUNE_COOKIE_AD) {
    return (
      <div className='fixed inset-0 z-[5004] bg-black/80 flex items-center justify-center'>
        <div className='wobble-hor-bottom w-[50vw] mt-[32%] ml-0'>
          <img src='/eggBreakers/breakingFortuneCookie.png' alt='' />
        </div>
      </div>
    )
  }

  if (enteringComponent === CPM_ENTERING_COMPONENTS.QUIZ_AD) {
    return (
      <div className='fixed inset-0 z-[5004] bg-black/80 flex items-center justify-center'>
        <img
          src='/quiz/questionMark.png'
          alt='Question Mark'
          className='w-[25vw] z-[5005] wobble-hor-bottom'
        />
      </div>
    )
  }

  return null
}

export default CPMAnimationWrapper
