import { useQueries } from '@tanstack/react-query'
import {
  REWARD_STATUS,
  REWARD_TYPE,
} from 'comps/organisms/SansinEventModal/components/MissionRewardButton'
import {
  CPM_ENTERING_COMPONENTS,
  CPM_MISSION_TYPES,
} from 'utils/alfarmCPMConstant/cpmAdTypes'
import backendApis from 'utils/backendApis'
import timeChecker from 'utils/timeChecker'
import { checkIsTodayWithISODate } from 'utils/utils'

const AD_TYPES = [
  ...Object.values(CPM_ENTERING_COMPONENTS).filter(
    (ad) => ad !== CPM_ENTERING_COMPONENTS.RECOMMENDED_PRODUCT_AD,
  ),
]

const isClearedToday = (userAdStatus) => {
  if (!userAdStatus) {
    return false
  }

  const todayClearedCount = checkIsTodayWithISODate(userAdStatus.clearedAt)
    ? userAdStatus.clearedCount
    : 0

  return todayClearedCount > 0
}

const useGetMissionEventStatus = ({ token }) => {
  const queries = useQueries({
    queries: [
      {
        queryKey: ['userAdStatus', AD_TYPES],
        queryFn: async () => {
          const result = await backendApis.getMultipleCPMAdStatusesByTypes({
            types: AD_TYPES,
          })

          return result
        },
        staleTime: 0,
        select: (data) => {
          const userMissionAdStatus = Object.values(CPM_MISSION_TYPES).map(
            (missionType) => {
              const missionAdStatus = data?.data?.find(
                (adStatus) => adStatus.type === `Alfarm_${missionType}`,
              )

              if (!missionAdStatus) {
                return {
                  missionType,
                  isClearedToday: false,
                  adStatus: null,
                }
              }

              return {
                missionType,
                isClearedToday: isClearedToday(missionAdStatus),
                adStatus: missionAdStatus,
              }
            },
          )

          return userMissionAdStatus
        },
      },
      {
        queryKey: ['rewardLogForSanSinEvent', token],
        queryFn: async () => {
          const result = await backendApis.getRewardLogForSellerAdPromoting(
            token,
          )

          return result.data?.rewardLogForSellerAdPromoting || []
        },
      },
      {
        queryKey: ['checkCPMAdSetExist'],
        queryFn: () => backendApis.checkCPMAdSetExist(),
      },
    ],
    combine: ([userAdStatus, rewardLogForSanSinEvent, checkCPMAdSetExist]) => {
      const userAdStatusData = userAdStatus?.data || []
      const rewardLogForSanSinEventData = rewardLogForSanSinEvent?.data || []

      const getRewardStatus = (count, keyName) => {
        const isClearedTodayCount =
          userAdStatusData.filter((data) => data.isClearedToday)?.length || 0

        if (isClearedTodayCount < count) {
          return REWARD_STATUS.LOCKED
        }

        const isRewarded = (rewardType) =>
          rewardLogForSanSinEventData.some(
            (event) => !timeChecker(event?.[rewardType]),
          )

        if (isRewarded(keyName)) {
          return REWARD_STATUS.CLAIMED
        }

        return REWARD_STATUS.CLAIMABLE
      }

      const missionRewards = Object.values(REWARD_TYPE).map((rewardType) => {
        if (rewardType === REWARD_TYPE.FIRST) {
          return {
            type: rewardType,
            status: getRewardStatus(1, 'getFirstRewardAt'),
          }
        }

        if (rewardType === REWARD_TYPE.SECOND) {
          return {
            type: rewardType,
            status: getRewardStatus(3, 'getSecondRewardAt'),
          }
        }

        return {
          type: rewardType,
          status: getRewardStatus(5, 'getThirdRewardAt'),
        }
      })

      return [
        {
          ...userAdStatus,
          data: userAdStatusData,
        },
        {
          ...rewardLogForSanSinEvent,
          data: missionRewards,
        },
        {
          ...checkCPMAdSetExist,
          data:
            checkCPMAdSetExist?.data?.status === 200
              ? checkCPMAdSetExist?.data?.data
              : false,
        },
      ]
    },
  })

  return queries
}

export default useGetMissionEventStatus
