import {
  CPM_VIEWER_TYPES,
  CPM_ENTERING_COMPONENTS,
  CPM_PURCHASE_FREQUENCY_TYPES,
  CPM_GOODS_MODAL_TYPES,
  CPM_VIDEO_MODAL_TYPES,
} from './cpmAdTypes'

// 구매 빈도에 따른 configs
const PURCHASE_FREQUENCY_AD_CONFIGS = {
  [CPM_PURCHASE_FREQUENCY_TYPES.LOW]: {
    viewerType: CPM_VIEWER_TYPES.VIDEO, // goods, video, none
    maxViewCount: 2, // 최대 광고 볼 수 있는 횟수
    intervalHours: 6, // 광고 보기 간격
    textMaxRewardAmount: 20,
    browsingSeconds: 30,
  },
  [CPM_PURCHASE_FREQUENCY_TYPES.MEDIUM]: {
    viewerType: CPM_VIEWER_TYPES.GOODS,
    maxViewCount: 2,
    intervalHours: 6,
    textMaxRewardAmount: 50,
    browsingSeconds: 30,
  },
  [CPM_PURCHASE_FREQUENCY_TYPES.HEAVY]: {
    viewerType: CPM_VIEWER_TYPES.GOODS,
    maxViewCount: 3,
    intervalHours: 4,
    textMaxRewardAmount: 50,
    browsingSeconds: 30,
  },
}

// viewerType에 따른 configs
const VIEWER_TYPE_AD_CONFIGS = {
  [CPM_VIEWER_TYPES.GOODS]: {
    buttonTitleText: '추천 상품 광고 보기',
    navigationModal: CPM_GOODS_MODAL_TYPES.RECOMMENDED_PRODUCT_AD,
  },
  [CPM_VIEWER_TYPES.VIDEO]: {
    buttonTitleText: '추천 영상 광고 보기',
    navigationModal: CPM_VIDEO_MODAL_TYPES.RECOMMENDED_PRODUCT_AD,
  },
  [CPM_VIEWER_TYPES.NONE]: {
    buttonTitleText: '',
    navigationModal: '',
  },
}

// 버튼 PROPS를 동적으로 반환
const getButtonProps = (userPurchaseFrequencyType) => {
  const purchaseConfig =
    PURCHASE_FREQUENCY_AD_CONFIGS[userPurchaseFrequencyType]

  const viewerType = purchaseConfig.viewerType

  const viewerConfig = VIEWER_TYPE_AD_CONFIGS[viewerType]

  const BUTTON_PROPS = {
    codePushVersionLimit: '6.8.37', // 버전 제한
    buttonMainIconImageUrl:
      'https://assets.ilevit.com/f1e5c27e-8522-4082-82cd-d14e765c9557.png',
    buttonSubtitleIconType: 'water', // 버튼 내 리워드 아이콘 - water, fertilizer, null
    viewerType,
    maxViewCount: purchaseConfig.maxViewCount,
    intervalHours: purchaseConfig.intervalHours,
    textMaxRewardAmount: purchaseConfig.textMaxRewardAmount,
    buttonTitleText: viewerConfig.buttonTitleText,
    navigationModal: viewerConfig.navigationModal,
    buttonSubtitleText: `최대 ${purchaseConfig.textMaxRewardAmount}g, 하루 ${purchaseConfig.maxViewCount}번 가능`,
  }

  return BUTTON_PROPS
}

// 상품 모달 PROPS를 동적으로 반환
const getGoodsModalProps = (userPurchaseFrequencyType) => {
  const purchaseConfig =
    PURCHASE_FREQUENCY_AD_CONFIGS[userPurchaseFrequencyType]

  const GOODS_MODAL_COMMON_PROPS = {
    BROWSING_TIME_SEC: purchaseConfig.browsingSeconds,
    FIRST_BUTTON_TEXT: '상품 추천 중',
    FINAL_BUTTON_TEXT: '상품 구경하기',
    MODAL_IMAGE_URL:
      'https://assets.ilevit.com/36a066c9-0235-448b-a1ab-d5d576bb8659.png',
    CLOSE_BUTTON_POSITION: { top: '18%', right: '15%' },
    ENTERING_COMPONENT: CPM_ENTERING_COMPONENTS.RECOMMENDED_PRODUCT_AD,
    MODAL_TEXT_1: '추천 상품을 구경하면',
    MODAL_TEXT_2: `물 최대 ${purchaseConfig.textMaxRewardAmount}g`,
    MODAL_TEXT_3: '을 받아요',
  }

  const CPM_PURCHASE_SCREEN_PROPS = {
    title: '추천 상품 구경하기',
    image: 'https://assets.ilevit.com/2cfa0fa4-f146-429f-a44c-5a5375f48bf5.png',
    ratio: 99 / 360,
    enteringComponent: CPM_ENTERING_COMPONENTS.RECOMMENDED_PRODUCT_AD,
    questTimer: purchaseConfig.browsingSeconds / 60,
    isTimerOn: true,
    userPurchaseFrequencyType: userPurchaseFrequencyType,
    questRewardText: `초간 구경하면 물을 최대 ${purchaseConfig.textMaxRewardAmount}g 받아요!`,
    scrollQuestReward: `물 최대 ${purchaseConfig.textMaxRewardAmount}g`,
    buyingReward: '고급 비료 5개, 물 1,000g',
  }

  const GOODS_MODAL_PROPS = {
    GOODS_MODAL_COMMON_PROPS,
    CPM_PURCHASE_SCREEN_PROPS,
  }

  return GOODS_MODAL_PROPS
}

const getVideoModalProps = (userPurchaseFrequencyType) => {
  const purchaseConfig =
    PURCHASE_FREQUENCY_AD_CONFIGS[userPurchaseFrequencyType]

  return {
    VIDEO_MODAL_COMMON_PROPS: {
      ...purchaseConfig,
      viewerType: CPM_VIEWER_TYPES.VIDEO,
    },
    CPM_PURCHASE_SCREEN_PROPS: {
      ...VIEWER_TYPE_AD_CONFIGS[CPM_VIEWER_TYPES.VIDEO],
      enteringComponent: CPM_ENTERING_COMPONENTS.RECOMMENDED_PRODUCT_AD,
      userPurchaseFrequencyType: userPurchaseFrequencyType,
    },
  }
}

export { getGoodsModalProps, getButtonProps, getVideoModalProps }
