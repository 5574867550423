import { CPM_VIEWER_TYPES } from './cpmAdTypes'

export const getTesterViewerType = ({ isCPMAdViewer, viewerType }) => {
  if (viewerType !== CPM_VIEWER_TYPES.NONE) {
    return viewerType
  }

  return isCPMAdViewer ? CPM_VIEWER_TYPES.VIDEO : CPM_VIEWER_TYPES.NONE
}

const CPM_AB_TEST_SCHEDULES = [
  {
    date: '2024-12-10T19:00:00',
    ratio: [80, 100],
  },
  {
    date: '2024-12-11T19:00:00',
    ratio: [60, 100],
  },
  {
    date: '2024-12-12T19:00:00',
    ratio: [40, 100],
  },
  {
    date: '2024-12-13T19:00:00',
    ratio: [20, 100],
  },
  {
    date: '2024-12-14T19:00:00',
    ratio: [0, 100],
  },
  // 여기에 새로운 날짜와 비율을 추가할 수 있습니다
]

export const getCPMAdViewerABTestRatio = () => {
  const now = new Date()
  const defaultRatio = [100, 100]

  // 날짜를 내림차순으로 정렬
  const sortedSchedules = [...CPM_AB_TEST_SCHEDULES].sort(
    (a, b) => new Date(b.date) - new Date(a.date),
  )

  // 현재 시간보다 이전인 가장 최근 스케줄의 비율을 반환
  const currentSchedule = sortedSchedules.find(
    (schedule) => now >= new Date(schedule.date).getTime(),
  )

  const returnABRatio = currentSchedule?.ratio || defaultRatio

  return returnABRatio
}
