const Dimmed = ({ children, style }) => {
  return (
    <div
      style={style}
      className='fixed top-0 left-0 w-full h-[100vh] bg-black bg-opacity-80 z-[1100] flex-1'
    >
      {children}
    </div>
  )
}

export default Dimmed
