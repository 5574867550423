import {
  CPM_VIEWER_TYPES,
  CPM_ENTERING_COMPONENTS,
  CPM_PURCHASE_FREQUENCY_TYPES,
  CPM_GOODS_MODAL_TYPES,
  CPM_VIDEO_MODAL_TYPES,
} from './cpmAdTypes'
import { returnTodayDealAdImageInfo } from '../utils'

const TODAY_DEAL_IMAGE_INFO = returnTodayDealAdImageInfo()

// 구매 빈도에 따른 configs
const PURCHASE_FREQUENCY_AD_CONFIGS = {
  [CPM_PURCHASE_FREQUENCY_TYPES.LOW]: {
    viewerType: CPM_VIEWER_TYPES.VIDEO, // goods, video, none
    maxViewCount: 2, // 최대 광고 볼 수 있는 횟수
    intervalHours: 6, // 광고 보기 간격
    textMaxRewardAmount: 20,
    browsingSeconds: 30,
  },
  [CPM_PURCHASE_FREQUENCY_TYPES.MEDIUM]: {
    viewerType: CPM_VIEWER_TYPES.GOODS,
    maxViewCount: 2,
    intervalHours: 6,
    textMaxRewardAmount: 50,
    browsingSeconds: 30,
  },
  [CPM_PURCHASE_FREQUENCY_TYPES.HEAVY]: {
    viewerType: CPM_VIEWER_TYPES.GOODS,
    maxViewCount: 3,
    intervalHours: 4,
    textMaxRewardAmount: 50,
    browsingSeconds: 30,
  },
}

// viewerType에 따른 configs
const VIEWER_TYPE_AD_CONFIGS = {
  [CPM_VIEWER_TYPES.GOODS]: {
    buttonTitleText: '오늘의 특가 상품 구경하기',
    navigationModal: CPM_GOODS_MODAL_TYPES.TODAY_DEAL_AD,
  },
  [CPM_VIEWER_TYPES.VIDEO]: {
    buttonTitleText: '오늘의 특가 대체 미션',
    navigationModal: CPM_VIDEO_MODAL_TYPES.TODAY_DEAL_AD,
  },
  [CPM_VIEWER_TYPES.NONE]: {
    buttonTitleText: '',
    navigationModal: '',
  },
}

// 버튼 PROPS를 동적으로 반환
const getButtonProps = (userPurchaseFrequencyType) => {
  const purchaseConfig =
    PURCHASE_FREQUENCY_AD_CONFIGS[userPurchaseFrequencyType]

  const viewerType = purchaseConfig.viewerType

  const viewerConfig = VIEWER_TYPE_AD_CONFIGS[viewerType]

  // 버튼 PROPS
  const BUTTON_PROPS = {
    codePushVersionLimit: '6.8.37', // 버전 제한
    buttonMainIconImageUrl:
      'https://assets.ilevit.com/c953e03b-b18d-4f47-9c38-ae33943e29fb.png',
    buttonSubtitleIconType: 'water', // 버튼 내 리워드 아이콘 - water, fertilizer, null
    viewerType,
    maxViewCount: purchaseConfig.maxViewCount,
    intervalHours: purchaseConfig.intervalHours,
    textMaxRewardAmount: purchaseConfig.textMaxRewardAmount,
    buttonTitleText: viewerConfig.buttonTitleText,
    navigationModal: viewerConfig.navigationModal,
    buttonSubtitleText: `최대 ${purchaseConfig.textMaxRewardAmount}g, 하루 ${purchaseConfig.maxViewCount}번 가능`,
  }

  return BUTTON_PROPS
}

// 상품 모달 PROPS
const getGoodsModalProps = (userPurchaseFrequencyType) => {
  const purchaseConfig =
    PURCHASE_FREQUENCY_AD_CONFIGS[userPurchaseFrequencyType]

  const GOODS_MODAL_COMMON_PROPS = {
    BROWSING_TIME_SEC: purchaseConfig.browsingSeconds,
    FIRST_BUTTON_TEXT: '상품 추천 중',
    FINAL_BUTTON_TEXT: `상품 보고 물 최대 ${purchaseConfig.textMaxRewardAmount}g 받기`,
    MODAL_IMAGE_URL:
      TODAY_DEAL_IMAGE_INFO?.modalImage ||
      'https://assets.ilevit.com/f4b5e495-a501-47e5-87a4-19c8ca7817c2.png',
    CLOSE_BUTTON_POSITION: { top: '18%', right: '15%' },
    ENTERING_COMPONENT: CPM_ENTERING_COMPONENTS.TODAY_DEAL_AD,
  }

  const CPM_PURCHASE_SCREEN_PROPS = {
    title: TODAY_DEAL_IMAGE_INFO?.purchaseScreenText || '오늘의 특가 구경하기',
    image:
      TODAY_DEAL_IMAGE_INFO?.purchaseScreenBanner ||
      'https://assets.ilevit.com/a7ed0b94-bc25-4e62-8d5e-3c46d542a085.png',
    ratio: 99 / 360,
    enteringComponent: CPM_ENTERING_COMPONENTS.TODAY_DEAL_AD,
    questTimer: purchaseConfig.browsingSeconds / 60,
    isTimerOn: true,
    userPurchaseFrequencyType: userPurchaseFrequencyType,
    questRewardText: `초간 구경하면 물을 최대 ${purchaseConfig.textMaxRewardAmount}g 받아요!`,
    scrollQuestReward: `물 최대 ${purchaseConfig.textMaxRewardAmount}g`,
    buyingReward: '고급 비료 7개',
  }

  const GOODS_MODAL_PROPS = {
    GOODS_MODAL_COMMON_PROPS,
    CPM_PURCHASE_SCREEN_PROPS,
  }
  return GOODS_MODAL_PROPS
}

const getVideoModalProps = (userPurchaseFrequencyType) => {
  const purchaseConfig =
    PURCHASE_FREQUENCY_AD_CONFIGS[userPurchaseFrequencyType]

  return {
    VIDEO_MODAL_COMMON_PROPS: {
      ...purchaseConfig,
      viewerType: CPM_VIEWER_TYPES.VIDEO,
    },
    CPM_PURCHASE_SCREEN_PROPS: {
      ...VIEWER_TYPE_AD_CONFIGS[CPM_VIEWER_TYPES.VIDEO],
      enteringComponent: CPM_ENTERING_COMPONENTS.TODAY_DEAL_AD,
      userPurchaseFrequencyType: userPurchaseFrequencyType,
    },
  }
}

export { getGoodsModalProps, getButtonProps, getVideoModalProps }
