import { CPM_ENTERING_COMPONENTS } from 'utils/alfarmCPMConstant/cpmAdTypes'

const styles = {
  titleStyle: {
    width: '50%',
    top: '-34vw',
    left: '24%',
    zIndex: '101',
    position: 'absolute',
    display: 'flex',
  },
}

const CPMGoodsModalBodySection = ({
  enteringComponent,
  goodsModalCommonProps,
}) => {
  const RecommendedProductAdBody = () => {
    return (
      <>
        <img
          className='w-[80vw]'
          src={goodsModalCommonProps.MODAL_IMAGE_URL}
          alt=''
        />
        <div className='absolute left-1/2 top-[45%] text-[4.8vw] font-bold -translate-x-1/2 -translate-y-1/2 w-[90%] text-[#402C24] text-center leading-[150%] mt-[1vw] pb-[20vw]'>
          {goodsModalCommonProps.MODAL_TEXT_1}
          <br />
          <span className='text-red-500'>
            {goodsModalCommonProps.MODAL_TEXT_2}
          </span>
          {goodsModalCommonProps.MODAL_TEXT_3}
        </div>
      </>
    )
  }

  // TODO: configs 받을 수 있게 수정해야 함
  const TodayDealAdBody = () => {
    return (
      <img
        className='w-[80vw]'
        src={goodsModalCommonProps.MODAL_IMAGE_URL}
        alt=''
      />
    )
  }

  const LeavesAdBody = () => {
    return (
      <>
        <img
          className='w-[80vw]'
          src={goodsModalCommonProps.MODAL_IMAGE_URL}
          alt=''
        />
        <div className='absolute left-1/2 top-[45%] font-bold -translate-x-1/2 -translate-y-1/2 w-[90%] text-[#402C24] text-[4vw] text-center leading-[150%] pb-[20vw]'>
          {goodsModalCommonProps.MODAL_TEXT_1}
          <br />
          {goodsModalCommonProps.MODAL_TEXT_2}
          <div className='absolute font-bold text-[8vw] left-1/2 top-[55%] -translate-x-1/2 translate-y-[14vw]'>
            {goodsModalCommonProps.MAX_REWARD_AMOUNT}
          </div>
          {/* 낙엽 상품을 구매하면 <br />물 1,000g과 고급비료 5개를 드려요 */}
        </div>
      </>
    )
  }

  const FortuneCookieAdBody = () => {
    return (
      <div>
        <img
          className='w-[80vw] mb-[20vw]'
          src={goodsModalCommonProps.MODAL_IMAGE_URL}
          alt=''
        />
        <div className='absolute left-1/2 top-[45%] font-medium -translate-x-1/2 -translate-y-1/2 w-[90%] text-[#402C24] text-[4.4vw] text-center leading-[150%] pb-[35vw]'>
          {goodsModalCommonProps.MODAL_TEXT_1}
          <br />
          <span className='font-bold text-[#402C24]'>
            {goodsModalCommonProps.MODAL_TEXT_2}
          </span>
          {goodsModalCommonProps.MODAL_TEXT_3}
        </div>
      </div>
    )
  }

  const QuizAdBody = () => {
    return (
      <>
        <img
          className='w-[80vw]'
          src={goodsModalCommonProps.MODAL_IMAGE_URL}
          alt=''
        />
        <div className='absolute left-1/2 top-[45%] -translate-x-1/2 -translate-y-1/2 w-[90%] text-[#402C24] text-[4.4vw] text-center leading-[150%] mt-[6vw]'>
          {goodsModalCommonProps.MODAL_TEXT_1}
          <br />
          {goodsModalCommonProps.MODAL_TEXT_2}
          <img
            className='w-[25vw] ml-[32%] mt-[2vw] mb-[6vw]'
            src='/quiz/rewardImage.png'
            alt='abc'
          />
          {/* 퀴즈 상품을 구매하면 <br />물 1,000g과 고급비료 5개를 드려요 */}
        </div>
      </>
    )
  }

  const YutnoriAdBody = () => {
    const yutImages = {
      도: '/minigame/yutnori/yutResultDo.png',
      개: '/minigame/yutnori/yutResultGae.png',
      걸: '/minigame/yutnori/yutResultGeol.png',
      윷: '/minigame/yutnori/yutResultYut.png',
      모: '/minigame/yutnori/yutResultMo.png',
    }

    return (
      <>
        <div style={{ ...styles.titleStyle }}>
          <img src='/minigame/yutnori/yutnoriTitle.png' alt='' />
        </div>
        <div
          className={`absolute w-[90vw] left-1/2 top-[-6%] -translate-x-1/2 -translate-y-1/2 bg-white/90 px-8 py-4 rounded-2xl text-[#7C5C35] text-[5vw] whitespace-pre-line text-center shadow-[inset_0_-4px_0_0_rgba(151,176,195,0.34)] z-[100] ${
            !goodsModalCommonProps.REWARD_STATUS ? 'invisible' : 'visible'
          }`}
        >
          {goodsModalCommonProps.REWARD_STATUS &&
            `'${goodsModalCommonProps.REWARD_STATUS}' 나왔어요!\n상품을 구경하면 물 ${goodsModalCommonProps.REWARD_AMOUNT}g을 드려요!`}
        </div>
        <div className='relative'>
          <img
            className='w-[80vw]'
            src={goodsModalCommonProps.MODAL_IMAGE_URL}
            alt='윷판'
          />
          {goodsModalCommonProps.REWARD_STATUS && (
            <img
              className='absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 w-[30vw]'
              src={yutImages[goodsModalCommonProps.REWARD_STATUS]}
              alt={goodsModalCommonProps.REWARD_STATUS}
            />
          )}
        </div>
      </>
    )
  }

  const MODAL_BODY_COMPONENTS = {
    [CPM_ENTERING_COMPONENTS.RECOMMENDED_PRODUCT_AD]: RecommendedProductAdBody,
    [CPM_ENTERING_COMPONENTS.TODAY_DEAL_AD]: TodayDealAdBody,
    [CPM_ENTERING_COMPONENTS.LEAVES_AD]: LeavesAdBody,
    [CPM_ENTERING_COMPONENTS.FORTUNE_COOKIE_AD]: FortuneCookieAdBody,
    [CPM_ENTERING_COMPONENTS.QUIZ_AD]: QuizAdBody,
    [CPM_ENTERING_COMPONENTS.YUTNORI_AD]: YutnoriAdBody,
  }

  const ModalBodyComponent = MODAL_BODY_COMPONENTS[enteringComponent]

  return <>{ModalBodyComponent && <ModalBodyComponent />}</>
}

export default CPMGoodsModalBodySection
