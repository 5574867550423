// 공통으로 사용되는 타입 상수

const CPM_PURCHASE_FREQUENCY_TYPES = {
  LOW: 'LOW',
  MEDIUM: 'MEDIUM',
  HEAVY: 'HEAVY',
}

const CPM_VIEWER_TYPES = {
  GOODS: 'goods',
  VIDEO: 'video',
  NONE: 'none',
}

const CPM_ENTERING_COMPONENTS = {
  RECOMMENDED_PRODUCT_AD: 'Alfarm_recommendedProductAd',
  TODAY_DEAL_AD: 'Alfarm_todayDealAd',
  YUTNORI_AD: 'Alfarm_yutnoriAd',
  LEAVES_AD: 'Alfarm_leavesAd',
  FORTUNE_COOKIE_AD: 'Alfarm_fortuneCookieAd',
  QUIZ_AD: 'Alfarm_quizAd',
}

// NOTE - 산신령 미션 (순서 변경 X)
const CPM_MISSION_TYPES = {
  YUTNORI_AD: 'yutnoriAd',
  FORTUNE_COOKIE_AD: 'fortuneCookieAd',
  LEAVES_AD: 'leavesAd',
  QUIZ_AD: 'quizAd',
  TODAY_DEAL_AD: 'todayDealAd',
}

const CPM_GOODS_MODAL_TYPES = {
  RECOMMENDED_PRODUCT_AD: 'recommendedProductAd_goods',
  TODAY_DEAL_AD: 'todayDealAd_goods',
  YUTNORI_AD: 'yutnoriAd_goods',
  LEAVES_AD: 'leavesAd_goods',
  FORTUNE_COOKIE_AD: 'fortuneCookieAd_goods',
  QUIZ_AD: 'quizAd_goods',
}

const CPM_READY_MODAL_TYPES = {
  YUTNORI_AD: 'yutnoriAd_ready',
  FORTUNE_COOKIE_AD: 'fortuneCookieAd_ready',
  QUIZ_AD: 'quizAd_ready',
}

const CPM_VIDEO_MODAL_TYPES = {
  RECOMMENDED_PRODUCT_AD: 'recommendedProductAd_video',
  TODAY_DEAL_AD: 'todayDealAd_video',
  YUTNORI_AD: 'yutnoriAd_video',
  LEAVES_AD: 'leavesAd_video',
  FORTUNE_COOKIE_AD: 'fortuneCookieAd_video',
  QUIZ_AD: 'quizAd_video',
}

export {
  CPM_VIEWER_TYPES,
  CPM_ENTERING_COMPONENTS,
  CPM_PURCHASE_FREQUENCY_TYPES,
  CPM_MISSION_TYPES,
  CPM_GOODS_MODAL_TYPES,
  CPM_VIDEO_MODAL_TYPES,
  CPM_READY_MODAL_TYPES,
}
