import { useEffect, useState } from 'react'

import backendApis from 'utils/backendApis'
import ModalStore from 'store/ModalStore'
import WhiteTextTimer from 'comps/WhiteTextTimer'
import {
  checkIsTodayWithISODate,
  createDefaultCPMAdDocument,
} from 'utils/utils'
import {
  CPM_ENTERING_COMPONENTS,
  CPM_VIEWER_TYPES,
} from 'utils/alfarmCPMConstant/cpmAdTypes'
import BottomSheetQuestComponent from 'comps/atoms/BottomSheetQuestComponent'
import BottomSheetArcadeComponent from './BottomSheetArcadeComponent'
import UserStore from 'store/UserStore'

const BUTTON_STATES = {
  PREPARING: '준비 중',
  COMPLETED: '완료',
  SOLD_OUT: '광고 마감',
  WAITING: '대기 중',
  AVAILABLE: '구경하기',
  PLAY: '플레이',
}

const CPMBottomSheetButton = ({
  ENTERING_COMPONENT,
  BUTTON_PROPS,
  closeBottomSheet,
}) => {
  const {
    codePushVersionLimit,
    buttonMainIconImageUrl,
    buttonSubtitleIconType,
    viewerType,
    maxViewCount,
    intervalHours,
    buttonTitleText,
    buttonSubtitleText,
    readyModal,
    navigationModal,
  } = BUTTON_PROPS

  const [isAdDisplayable, setIsAdDisplayable] = useState(false) // 광고 노출 여부
  const [adConditions, setAdConditions] = useState({
    hasRemainingViewCounts: null, // 광고 시청 횟수
    isIntervalTimeReady: null, // 광고 시간 간격
    hasAdStock: null, // db 광고 잔여 재고
    modalPath: null, // 모달 경로
  })
  const [isAdAvailable, setIsAdAvailable] = useState(false) // final result
  const [timerState, setTimerState] = useState({
    isTimerOn: false,
    remainedIntervalTime: 0,
  })
  const [buttonText, setButtonText] = useState(BUTTON_STATES.PREPARING)
  const [buttonClicked, setButtonClicked] = useState(false)

  useEffect(() => {
    checkCanShowAd() // 광고 노출 여부를 결정
    setupAdConditions() // 광고 상태 확인
  }, [])

  useEffect(() => {
    determineAdAvailability() // 광고 상태 설정
  }, [adConditions])

  const checkCanShowAd = () => {
    if (
      viewerType === CPM_VIEWER_TYPES.NONE ||
      !ENTERING_COMPONENT ||
      !BUTTON_PROPS ||
      !navigationModal ||
      UserStore.codePushVersionFromAlwayzApp < codePushVersionLimit // 버전 제한 + 경험치/뉴유저/물양 제한
    )
      return

    setIsAdDisplayable(true)
  }

  const setupAdConditions = async () => {
    const userAdStatus = await getUserAdStatus()

    checkViewCounts(userAdStatus)
    checkIntervalTime(userAdStatus)
    checkModalPath(userAdStatus)
    await checkAdStock()
  }

  const determineAdAvailability = () => {
    const {
      hasRemainingViewCounts,
      hasAdStock,
      isIntervalTimeReady,
      modalPath,
    } = adConditions

    if (
      hasRemainingViewCounts == null ||
      hasAdStock == null ||
      isIntervalTimeReady == null ||
      modalPath == null
    ) {
      setButtonText(BUTTON_STATES.PREPARING)
      return
    }

    if (hasRemainingViewCounts === false) {
      setButtonText(BUTTON_STATES.COMPLETED)
      return
    }

    if (hasAdStock === false) {
      setButtonText(BUTTON_STATES.SOLD_OUT)
      return
    }

    if (isIntervalTimeReady === false) {
      setButtonText(BUTTON_STATES.WAITING)
      setTimerState((prevState) => ({
        ...prevState,
        isTimerOn: true,
      }))
      return
    }

    if (
      isIntervalTimeReady === true &&
      hasAdStock === true &&
      hasRemainingViewCounts === true
    ) {
      setButtonText(
        ENTERING_COMPONENT === CPM_ENTERING_COMPONENTS.YUTNORI_AD
          ? BUTTON_STATES.PLAY
          : BUTTON_STATES.AVAILABLE,
      )
      setIsAdAvailable(true)
    }
  }

  const getUserAdStatus = async () => {
    try {
      const result = await backendApis.getCPMAdStatusByType({
        type: ENTERING_COMPONENT,
      })
      if (result?.status === 200) {
        return result?.data
      } else if (result?.status === 404) {
        const defaultAdStatus = await createDefaultCPMAdDocument({
          type: ENTERING_COMPONENT,
        })
        return defaultAdStatus
      }
      return null
    } catch (error) {
      console.error('Failed to get ad status:', error)
      return null
    }
  }

  const checkViewCounts = (userAdStatus) => {
    if (!userAdStatus) return

    const todayClearedCount = checkIsTodayWithISODate(userAdStatus.clearedAt)
      ? userAdStatus.clearedCount
      : 0
    const hasRemainingViewCounts = todayClearedCount < maxViewCount
    setAdConditions((prevState) => ({
      ...prevState,
      hasRemainingViewCounts,
    }))
  }

  const checkIntervalTime = (userAdStatus) => {
    if (!userAdStatus) return

    const lastClearedTime = new Date(userAdStatus.clearedAt)
    const timeDifference = new Date().getTime() - lastClearedTime.getTime()
    const isIntervalTimeReady = timeDifference >= intervalHours * 60 * 60 * 1000
    const remainedIntervalTime = new Date(
      new Date(userAdStatus.clearedAt).getTime() +
        intervalHours * 60 * 60 * 1000,
    )
    setAdConditions((prevState) => ({
      ...prevState,
      isIntervalTimeReady,
    }))
    setTimerState((prevState) => ({
      ...prevState,
      remainedIntervalTime,
    }))
  }

  const checkModalPath = (userAdStatus) => {
    if (!userAdStatus) return

    const shouldShowReadyModal =
      readyModal &&
      (!userAdStatus.rewardDeterminedAt ||
        !checkIsTodayWithISODate(userAdStatus.rewardDeterminedAt) ||
        userAdStatus.rewardDeterminedAt <= userAdStatus.clearedAt)

    setAdConditions((prevState) => ({
      ...prevState,
      modalPath: shouldShowReadyModal ? readyModal : navigationModal,
    }))
  }

  const checkAdStock = async () => {
    if (viewerType === CPM_VIEWER_TYPES.GOODS) {
      const result = await backendApis.checkCPMAdSetExist()
      if (result?.status === 200) {
        setAdConditions((prevState) => ({
          ...prevState,
          hasAdStock: result?.data,
        }))
      }
    }
    if (viewerType === CPM_VIEWER_TYPES.VIDEO) {
      // video viewer는 cpm 재고 검사 하지 않음
      setAdConditions((prevState) => ({
        ...prevState,
        hasAdStock: true,
      }))
    }
  }

  const openModal = () => {
    ModalStore.setIsModalOpen(adConditions.modalPath)
    closeBottomSheet()
  }

  const baseButtonClasses = `right-0 absolute pt-[2vw] pb-[2vw] text-[4vw] rounded-[2vw] translate-y-[-55%] top-[50%] z-[100] mr-[4vw] w-[21%]`

  const yutnoriAdButtonClasses = `right-0 absolute pt-[2vw] pb-[2vw] text-[4vw] rounded-[2vw] translate-y-[-55%] top-[50%] z-[100] mr-[6vw] w-[19%]`

  const AvailableButton = () => {
    const isYutnoriAd =
      ENTERING_COMPONENT === CPM_ENTERING_COMPONENTS.YUTNORI_AD
    return (
      <button
        className={`${
          isYutnoriAd ? yutnoriAdButtonClasses : baseButtonClasses
        } text-[#fff] font-bold 
        ${buttonClicked ? 'bg-[#ABE066]' : 'bg-[#7ED321]'}`}
        onPointerDown={() => setButtonClicked(true)}
        onPointerCancel={() => setButtonClicked(false)}
        onPointerUp={() => {
          setButtonClicked(false)
          openModal()
        }}
      >
        {buttonText}
      </button>
    )
  }

  const TimerButton = () => {
    return (
      <div className={`${baseButtonClasses} text-[#fff] font-light`}>
        <WhiteTextTimer
          onTimeEnd={() => {
            setTimerState((prevState) => ({
              ...prevState,
              isTimerOn: false,
            }))
            setAdConditions((prevState) => ({
              ...prevState,
              isIntervalTimeReady: true,
            }))
          }}
          timeStamp={timerState.remainedIntervalTime}
          timerMinutes={0}
          color='#442b22'
        />
      </div>
    )
  }

  const DisabledButton = () => {
    return (
      <div className={`${baseButtonClasses} text-[#442b22] font-light`}>
        {buttonText}
      </div>
    )
  }

  const getButton = () => {
    if (isAdAvailable) {
      return <AvailableButton />
    }

    if (
      adConditions.hasRemainingViewCounts &&
      adConditions.hasAdStock &&
      timerState.isTimerOn
    ) {
      return <TimerButton />
    }

    return <DisabledButton />
  }

  return (
    <>
      {isAdDisplayable &&
        ENTERING_COMPONENT !== CPM_ENTERING_COMPONENTS.YUTNORI_AD && (
          <div>
            <div className='relative text-center'>
              <BottomSheetQuestComponent
                mainIconSrc={buttonMainIconImageUrl}
                title={buttonTitleText}
                subtitle={buttonSubtitleText}
                rewardIconType={buttonSubtitleIconType}
                buttonComponent={getButton()}
              />
            </div>
          </div>
        )}
      {isAdDisplayable &&
        ENTERING_COMPONENT === CPM_ENTERING_COMPONENTS.YUTNORI_AD && (
          <div className='relative text-center'>
            <BottomSheetArcadeComponent
              mainIconSrc={buttonMainIconImageUrl}
              title={buttonTitleText}
              subtitle={buttonSubtitleText}
              rewardIconType={buttonSubtitleIconType}
              buttonComponent={getButton()}
            />
          </div>
        )}
    </>
  )
}

export default CPMBottomSheetButton
