import VideoAdModalActionButton from 'comps/molecules/VideoAdModal/components/VideoAdModalActionButton'
import VideoAdModalTitle from 'comps/molecules/VideoAdModal/components/VideoAdModalTitle'
import VideoAdModalWrapper from 'comps/molecules/VideoAdModal/components/VideoAdModalWrapper'
import VideoAdModalDescription from './components/VideoAdModalDescription'
import VideoAdModalRewardIcon from './components/VideoAdModalRewardIcon'

const VideoAdModal = Object.assign(VideoAdModalWrapper, {
  Title: VideoAdModalTitle,
  ActionButton: VideoAdModalActionButton,
  Description: VideoAdModalDescription,
  RewardIcon: VideoAdModalRewardIcon,
})

export default VideoAdModal
