import React, { useState, useEffect } from 'react'
import { observer } from 'mobx-react-lite'
import backendApis from '../../utils/backendApis'
import ModalStore from '../../store/ModalStore'

import moment from 'moment'

const descriptionMap = {
  purchasedLuckydrawRewardItem: '비료바우처 구매',
  canceledPurchaseRewardLuckydraw: '비료바우처 취소',
  dailyLoginFert: '매일 출석하기',
  giveFertilizerSet: '고급비료 사용',
  giveFertilizer: '일반비료 사용',
  completeRecommendItem: '추천상품 구경',
  purchasedRewardItem: '일반상품 구매',
  clearedInviteQuest: '친구초대 완료',
  bonusRewardBox: '친구 농장 비료상자',
  canceledPurchaseReward: '구매 취소',
  luckydraw: '럭키드로우',
  luckydraw101: '럭키드로우',
  luckydraw102: '럭키드로우',
  luckydraw103: '럭키드로우',
  luckydraw104: '럭키드로우',
  luckydraw105: '럭키드로우',
  luckydraw106: '럭키드로우',
  luckydraw107: '럭키드로우',
  luckydraw108: '럭키드로우',
  setLevelUpRewardTwo: '레벨업2',
  setLevelUpRewardThree: '레벨업3',
  setLevelUpRewardFour: '레벨업4',
  setLevelUpRewardFive: '레벨업5',
  setLevelUpRewardSix: '레벨업6',
  puppyGift: '바둑이 선물',
  clearedTutorial: '바둑이 선물',
  newUserEvent: '새친구 환영',
  cx: '고객센터 지급',
  comebackEvent: '복귀 이벤트',
  InvitefriendQuest: '친구 초대',
  inviteGganbuQuest: '맞팜 퀘스트',
  alwayzFarm: '맞팜 체험',
  waterToFertilizer: '물 비료 교환',
  lotteryDrawReward: '캡슐 뽑기',
  QRBonus: 'QR 선물',
  purchaseProducedFertMachine: '비료 생산기 구매',
  receiveProducedFertMachine: '비료 생산기 받기',
  canceledPurchaseFertMachineOrders: '비료 생산기 구매 취소',
  rouletteEventReward2: '룰렛 이벤트',
  giveBackAlfarmSingleDealOverCanceledOrder: '올팜특가상품 취소건 보상',
  newbiePurchaseRewardFertilizerset: '팜린이 고급비료 구매',
  cancelNewbiePurchaseRewardFertilizerset: '팜린이 고급비료 구매 취소',
  announcementReward: '공지 보상',
  couponUsageNudge: '일석이조 찬스',
  newbieTreasureBoxReward: '보물상자 리워드',
  relayReward1: '릴레이 레벨1 구매',
  relayReward2: '릴레이 레벨2 구매',
  relayReward3: '릴레이 레벨3 구매',
  relayReward4: '릴레이 레벨4 구매',
  relayReward: '릴레이 구매 취소',
  dealPurchase: '특가 상품 구매',
  dealCancel: '특가 상품 취소',
  rouletteEventV2: '룰렛 이벤트',
  missionHunterPurchase: '미션 사냥꾼 구매',
  makeGganbuEvent: '맞팜친구 맺기 보상',
  flappyBirdEventFertilizerSet: '플라잉팜 구매',
  affiliationItem: '취향저격수 상점 구매',
  leftGganbuInvite: '돌아온 맞팜 친구',
  acceptLeftGganbuInvite: '돌아온 친구 초대',
  coffeeGift: '커피 선물',
  chocoGift: '초코 선물',
  vipCard: 'VIP 카드',
  fromCoffeeBridgePage: '스타터팩 지원',
  newyearGift: '설날 선물',
  canceledPurchaseOutlet: '아울렛 구매 취소',
  outlet: '아울렛 구매',
  purchaseRewardSeasonDeal: '특가 상품 구매 취소',
  phoneBookGganbu: '맞팜 초대',
  manbogiStorePurchase: '만보기 상점 교환',
  penaltyRestore: '주문 취소 철회 지급',
  gganbuGift: '맞팜 친구에게 선물',
  alfarmHarvestOtionEvent: '이벤트 옵션 구매',
  alfarmRoulette: '룰렛 뽑기',
  alfarmBugReward: '올팜 구매 보상',
  lastHarvestPurchase: '왕창수확 구매',
  quizPurchase: '퀴즈 특가 구매',
  cancelQuizPurchase: '퀴즈 특가 구매 취소',
  weeklyBingoPurchase: '주간 빙고 구매',
  weeklyBingoSuccess: '주간 빙고 완성 보상',
  canceledWeeklyBingoSuccess: '주간 빙고 완성 취소',
  canceledWeeklyBingoPurchase: '주간 빙고 구매 취소',
  alfarmSingleItemDeal: '특가 상품 구매',
  canceledAlfarmSingleItemDeal: '특가 상품 취소',
  browsingAdQuest: '상품 15초 보기',
  alfarmbrowsingAdQuest: '15초 보기 구매',
  cancelBrowsingAdQuestPurchase: '15초 보기 구매 취소',
  fertilizerRoulette: '럭키 비료 룰렛 구매',
  fertilizerTowerGamePurchase: '비료 쌓기 구매',
  fertilizerTowerGame: '비료 쌓기',
  sellerStorePurchase: '맞팜 스토어',
  cancelSellerStorePurchase: '맞팜 스토어 구매 취소',
  purchaseRoulette: '깜짝 룰렛',
  friendGardenReward: '새친구 텃밭 보상',
  personalizedShopPurchase: '선물상자 구매',
  cancelPersonalizedShop: '선물상자 구매 취소',
  cancelPurchaseRoulette: '깜짝룰렛 구매 취소',
  selectOnePurchase: '골라사기 구매',
  cancelSelectOnePurchase: '골라사기 구매 취소',
  allBrandDealPurchase: '브랜드 상품 구매',
  cancelPurchaseAllBrandDeal: '브랜드 상품 구매 취소',
  surpriseMission: '깜짝 미션 보상',
  treasureDealPurchase: '보물특가 구매',
  cancelTreasureDealPurchase: '보물특가 구매 취소',
  eggBreakersPurchase: '포춘쿠키 구매',
  cancelEggBreakersPurchase: '포춘쿠키 구매 취소',
  trendQuizPurchase: '우주 최저가 퀴즈 구매',
  cancelTrendQuizPurchase: '우주 최저가 퀴즈 구매 취소',
  gganbuSurprisePurchase: '맞팜 히든 미션 구매',
  waterBrowsingAdQuest: '특별상품 구경하기',
  waterBrowsingAdQuestPurchase: '특별상품 구매',
  tenMinDealScreen: '10분 핫딜 구매',
  cancelTenMinDeal: '10분 핫딜 구매 취소',
  dailyMissionAllClear: '매일도전 완료 보상',
  goldFilled: '골드필드 구매',
  jewelryRevenueDeal: '주얼리특가 구매',
  kidsRevenueDeal: '키즈특가 구매',
  winterRevenueDeal: '겨울특가 구매',
  snackRevenueDeal: '간식특가 구매',
  daisoRevenueDeal: '생활특가 구매',
  healthRevenueDeal: '건강특가 구매',
  gganbuFarmBrandDeal: '맞팜 브랜드딜 구매',
  lotteryDrawPurchase: '캡슐뽑기 특별구매',
  cancelLotteryDrawPurchase: '캡슐뽑기 특별구매 취소',
  bottomSheetPurchaseQuestFertilizer: '주간 구매 혜택',
  livingExpensedDeal: '생활비 특가 구매',
  lotteryDrawRewardSpecialPurchase: '특별 구매',
  feverModePurchase: '피버모드 구매',
  feverMode: '피버모드 플레이',
  yutNoriPurchase: '윷 던지기 구매',
  cancelYutNoriPurchase: '윷 던지기 구매 취소',
  dailyMissionV2: '매일 도전',
  luckyBag: '복주머니 교환',
  newYearPurchase: '복주머니 구매',
  alphabetExchange: '알파벳 교환',
  potatoPetGift: '감자햄의 선물',
  lastNewyearpurchase: '한정 이벤트 구매하기',
  churnedGganbuBounty: '맞팜현상금 보상',
  newUserValueIndicate: '신규 농부님 지원금',
  jangbogiCoin: '신선마켓 교환',
  groceryDeal: '가정의달 상품 구매',
  quitTeamAlfarm: '팀농장 회수',
  googleAdCallback: '광고보기 보상',
  familyDeal: '가정의달 구매',
  cancelNutrimentLeaves: '낙엽 상품 구매 취소',
  nutrimentPurchase: '낙엽 상품 구매',
  weeklyPurchaseQuestFertilizerCancel: '주간 구매 혜택 취소',
  winnerDealPurchase: '오늘의 특가 상품 구매',
  cancelWinnerDealPurchase: '오늘의 특가 상품 구매 취소',
  fashionWeek: '패션위크 상품 구매',
  blackFriday: '블프 상품 구매',
  ALFARM_MISSION_REWARD: '광고 보기',
  tripleSeven: '777 특가 구매',
  SanSinSecondReward: '산신령 이벤트 선물',
  SanSinThirdReward: '산신령 이벤트 선물',
  chuseokDeal24: '추석특가 구매',
  alfarmGive10000Feed: '올목장 100회 사료주기',
  sinsunMartket: '신선마켓 상품 구매',
  alranchReward: '올목장 경험치 이전',
  mysteryFlowerReward: '신비한 화분 보상',
  mysteryFlowerRewardAd: '신비한 화분 광고시청',
  recommendedProductAdPurchase: '추천 상품 구매',
  cancelRecommendedProductAdPurchase: '추천 상품 구매 취소',
  todayDealAdBrowsing: '오늘의 특가 광고 보기',
  todayDealAdPurchase: '오늘의 특가 광고 구매 보상',
  cancelTodayDealAdPurchase: '오늘의 특가 광고 구매 취소',
  yutnoriAdBrowsing: '윷놀이 광고 보기',
  yutnoriAdPurchase: '윷놀이 광고 구매 보상',
  cancelYutnoriAdPurchase: '윷놀이 광고 구매 취소',
  fortuneCookieAdBrowsing: '포춘쿠키 광고 보기',
  fortuneCookieAdPurchase: '포춘쿠키 광고 구매 보상',
  cancelFortuneCookieAdPurchase: '포춘쿠키 광고 구매 취소',
  leavesAdBrowsing: '낙엽 광고 보기',
  leavesAdPurchase: '낙엽 광고 구매 보상',
  cancelLeavesAdPurchase: '낙엽 광고 구매 취소',
  quizAdBrowsing: '퀴즈 광고 보기',
  quizAdPurchase: '퀴즈 광고 구매 보상',
  cancelQuizAdPurchase: '퀴즈 광고 구매 취소',
  offerwallReward: '미션하고 보상 받기',
  badahamV2_fertilizer: '바다햄 비료 보상',
  badahamV2_fertilizerSet: '바다햄 고급비료 보상',
}

const FertilizerHistoryModal = observer(({ token }) => {
  const [fertHistoryData, setFertHistoryData] = useState([])
  const [emptyModal, setEmptyModal] = useState(false)

  const getFertHistory = async () => {
    const result = await backendApis.getFertHistory(token)

    if (result?.data?.length === 0) {
      setEmptyModal(true)
    }
    let description
    const customData = result?.data?.map((item) => {
      const typeList = item.type.split('_')
      const needFullDescription = typeList?.[0] === 'badahamV2'
      description = needFullDescription
        ? descriptionMap[item.type]
        : descriptionMap[typeList?.[0]] || '기타'
      const invited = typeList?.includes('invited') ? ' (친구)' : ''
      description += invited

      const prefix = typeList?.includes('cancel') ? ' 취소' : ''
      description += prefix
      return { ...item, description }
    })

    setFertHistoryData(customData)
  }

  useEffect(() => {
    getFertHistory()
  }, [token])

  return (
    <>
      <div
        style={{
          flex: 1,
          position: 'fixed',
          left: 0,
          top: 0,
          zIndex: 1000,
          width: '100%',
          height: '100vh',
          background: 'rgba(0,0,0,0.8)',
        }}
      />
      <div
        style={{
          width: '80%',
          height: '110vw',
          position: 'absolute',
          zIndex: 1001,
          top: '55vw',
          left: '10%',
          background: 'white',
          borderRadius: 20,
        }}
      >
        <div
          style={{
            fontFamily: 'maplestory',
            color: '#402C24',
            fontSize: 22,
            textAlign: 'center',
            marginTop: '5vw',
            marginBottom: '1vw',
          }}
        >
          비료 기록
        </div>
        <div
          style={{
            backgroundColor: '#f2f2f2',
            width: '100%',
            height: '0.5vw',
            marginTop: 12,
          }}
        />
        <div
          style={{
            position: 'relative',
            overflowY: 'scroll',
            height: '93vw',
          }}
        >
          <div>
            {fertHistoryData?.length > 0 &&
              fertHistoryData?.map((item, index) => {
                return (
                  <div key={index}>
                    <div
                      style={{
                        display: 'flex',
                        flex: 1,
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        marginTop: 8,
                      }}
                    >
                      <div
                        style={{
                          marginLeft: 18,
                        }}
                      >
                        <div
                          style={{
                            color: '#5F2D0C',
                            zIndex: 1999,
                            fontFamily: 'maplestory',
                            fontSize: 16,
                            marginTop: 8,
                            marginRight: 16,
                          }}
                        >
                          {item?.description}
                        </div>
                        <div
                          style={{
                            color: '#855C36',
                            fontFamily: 'maplestory',
                            fontSize: 12,
                            marginTop: 10,
                          }}
                        >
                          {moment(item?.loggedAt).format(`MM월 DD일 HH:mm`)}
                        </div>
                      </div>
                      {item?.action === 'plus' ? (
                        <div
                          style={{
                            color: 'black',
                            alignItems: 'center',
                            justifyContent: 'flex-end',
                            marginRight: 32,
                            display: 'flex',
                            flex: 1,
                            fontSize: 24,
                            fontFamily: 'maplestory',
                          }}
                        >
                          +
                          {item?.fertilizer >= 1 && (
                            <div
                              style={{
                                position: 'relative',
                              }}
                            >
                              <img
                                style={{
                                  width: '13vw',
                                }}
                                alt=''
                                src={'/icon/fertilizer.png'}
                              />
                              <div
                                style={{
                                  backgroundColor: '#A2C96D',
                                  right: -10,
                                  textAlign: 'center',
                                  borderRadius: '15px',
                                  padding: '3px 12px',
                                  position: 'absolute',
                                  fontFamily: 'maplestory',
                                  borderRightColor: 'white',
                                  bottom: -5,
                                  fontSize: 14,
                                }}
                              >
                                x {item?.fertilizer}
                              </div>
                            </div>
                          )}
                          {item?.fertilizerSet >= 1 && (
                            <div
                              style={{
                                position: 'relative',
                                marginLeft: 12,
                              }}
                            >
                              <img
                                style={{
                                  width: '13vw',
                                }}
                                alt=''
                                src={'/icon/fertilizerSet.png'}
                              />
                              <div
                                style={{
                                  backgroundColor: '#A2C96D',
                                  right: -10,
                                  textAlign: 'center',
                                  borderRadius: '15px',
                                  padding: '3px 12px',
                                  position: 'absolute',
                                  fontFamily: 'maplestory',
                                  borderRightColor: 'white',
                                  bottom: -5,
                                  fontSize: 14,
                                }}
                              >
                                x {item?.fertilizerSet}
                              </div>
                            </div>
                          )}
                        </div>
                      ) : (
                        <div
                          style={{
                            color: 'black',
                            alignItems: 'center',
                            display: 'flex',
                            flex: 1,
                            fontSize: 24,
                            fontFamily: 'maplestory',
                            justifyContent: 'flex-end',
                            marginRight: 32,
                          }}
                        >
                          -
                          {item?.fertilizer >= 1 && (
                            <div
                              style={{
                                position: 'relative',
                              }}
                            >
                              <img
                                style={{
                                  width: '13vw',
                                }}
                                alt=''
                                src={'/icon/fertilizer.png'}
                              />
                              <div
                                style={{
                                  backgroundColor: '#A2C96D',
                                  right: -10,
                                  textAlign: 'center',
                                  borderRadius: '15px',
                                  padding: '3px 12px',
                                  position: 'absolute',
                                  fontFamily: 'maplestory',
                                  borderRightColor: 'white',
                                  bottom: -5,
                                  fontSize: 14,
                                }}
                              >
                                x {item?.fertilizer}
                              </div>
                            </div>
                          )}
                          {item?.fertilizerSet >= 1 && (
                            <div
                              style={{
                                position: 'relative',
                              }}
                            >
                              <img
                                style={{
                                  width: '13vw',
                                  marginLeft: 5,
                                }}
                                alt=''
                                src={'/icon/fertilizerSet.png'}
                              />
                              <div
                                style={{
                                  backgroundColor: '#A2C96D',
                                  right: -10,
                                  textAlign: 'center',
                                  borderRadius: '15px',
                                  padding: '3px 12px',
                                  position: 'absolute',
                                  fontFamily: 'maplestory',
                                  borderRightColor: 'white',
                                  bottom: -5,
                                  fontSize: 14,
                                }}
                              >
                                x {item?.fertilizerSet}
                              </div>
                            </div>
                          )}
                        </div>
                      )}
                    </div>
                    <div
                      style={{
                        width: '100%',
                        height: 1,
                        backgroundColor: '#EDEDED',
                        fontSize: 18,
                        marginTop: 16,
                      }}
                    />
                  </div>
                )
              })}
          </div>
        </div>
      </div>

      <button
        style={{
          width: '8%',
          top: '40vw',
          right: '10vw',
          zIndex: 1001,
          position: 'absolute',
        }}
        onClick={() => {
          ModalStore.setIsModalOpen('basic')
        }}
      >
        <img src='/icon/modalCloseButton.png' alt='' />
      </button>

      {emptyModal && (
        <>
          <div
            style={{
              color: 'black',
              fontFamily: 'maplestory',
              fontSize: 18,
              marginTop: '20vw',
              textAlign: 'center',
            }}
          >
            비료 기록이 없어요
            <br />
            비료를 받거나 사용해보세요!
          </div>
        </>
      )}
    </>
  )
})

export default FertilizerHistoryModal
