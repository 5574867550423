const BottomSheetArcadeComponent = ({
  mainIconSrc,
  title,
  subtitle,
  buttonComponent,
  rewardIconType,
  newPing = false,
}) => {
  const REWARD_ICONS = {
    water: '/icon/water.png',
    fertilizer: '/icon/fertilizer.png',
  }

  return (
    <div className='flex relative bg-white px-[4.2vw] pt-[2.9vw] pb-[3.8vw]'>
      {/* 1. 메인 아이콘 */}
      <div className='flex items-center'>
        <img
          src={mainIconSrc}
          alt='quest icon'
          className='w-[17vw] h-[17vw] mr-[2.2vw]'
        />
      </div>

      {/* 2. 텍스트 컨테이너 */}
      <div className='flex flex-col text-left mt-[0.4vw] justify-center'>
        {/* 타이틀 */}
        <h3 className='text-[4.2vw] text-black'>{title}</h3>
        {/* 서브타이틀 */}
        <div className='flex items-center '>
          {rewardIconType && (
            <img
              src={REWARD_ICONS[rewardIconType]}
              alt='subtitle icon'
              className='w-[4.8vw] h-[4.8vw] mr-[0.5vw]'
            />
          )}
          <span className='text-[3.6vw] text-[#6D7680]'>{subtitle}</span>
        </div>

        {/* New 핑 표시 */}
        {newPing && (
          <img
            src='/icon/newPing.png'
            alt='new ping'
            className='absolute bottom-[10vw] left-[10vw] w-[8%] z-10 wobble-ver-left'
          />
        )}
      </div>
      {/* 3. 버튼 컴포넌트 */}
      <div className='flex items-center justify-center'>{buttonComponent}</div>
    </div>
  )
}

export default BottomSheetArcadeComponent
