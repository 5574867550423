import {
  CPM_VIEWER_TYPES,
  CPM_ENTERING_COMPONENTS,
  CPM_PURCHASE_FREQUENCY_TYPES,
  CPM_GOODS_MODAL_TYPES,
  CPM_READY_MODAL_TYPES,
  CPM_VIDEO_MODAL_TYPES,
} from './cpmAdTypes'
import backendApis from 'utils/backendApis'

// 구매 빈도에 따른 configs
const PURCHASE_FREQUENCY_AD_CONFIGS = {
  [CPM_PURCHASE_FREQUENCY_TYPES.LOW]: {
    viewerType: CPM_VIEWER_TYPES.VIDEO, // goods, video, none
    maxViewCount: 2, // 최대 광고 볼 수 있는 횟수
    intervalHours: 6, // 광고 보기 간격
    textMaxRewardAmount: 15, // 보상 최대 크기 (버튼 텍스트)
    browsingSeconds: 15,
  },
  [CPM_PURCHASE_FREQUENCY_TYPES.MEDIUM]: {
    viewerType: CPM_VIEWER_TYPES.GOODS,
    maxViewCount: 2,
    intervalHours: 6,
    textMaxRewardAmount: 30,
    browsingSeconds: 15,
  },
  [CPM_PURCHASE_FREQUENCY_TYPES.HEAVY]: {
    viewerType: CPM_VIEWER_TYPES.GOODS,
    maxViewCount: 3,
    intervalHours: 4,
    textMaxRewardAmount: 30,
    browsingSeconds: 15,
  },
}

// viewerType에 따른 configs
const VIEWER_TYPE_AD_CONFIGS = {
  [CPM_VIEWER_TYPES.GOODS]: {
    buttonTitleText: '포춘쿠키 상품 광고 보기',
    navigationModal: CPM_GOODS_MODAL_TYPES.FORTUNE_COOKIE_AD,
    readyModal: CPM_READY_MODAL_TYPES.FORTUNE_COOKIE_AD,
  },
  [CPM_VIEWER_TYPES.VIDEO]: {
    buttonTitleText: '포춘쿠키 영상 광고 보기',
    navigationModal: CPM_VIDEO_MODAL_TYPES.FORTUNE_COOKIE_AD,
    readyModal: CPM_READY_MODAL_TYPES.FORTUNE_COOKIE_AD,
  },
  [CPM_VIEWER_TYPES.NONE]: {
    buttonTitleText: '',
    navigationModal: '',
    readyModal: '',
  },
}

// 버튼 PROPS를 동적으로 반환
const getButtonProps = (userPurchaseFrequencyType) => {
  const purchaseConfig =
    PURCHASE_FREQUENCY_AD_CONFIGS[userPurchaseFrequencyType]

  const viewerType = purchaseConfig.viewerType

  const viewerConfig = VIEWER_TYPE_AD_CONFIGS[viewerType]

  const BUTTON_PROPS = {
    codePushVersionLimit: '6.8.37', // 버전 제한
    bottomEventIconImageUrl: `/eggBreakers/fortuneCookieEntry.png`,
    viewerType,
    maxViewCount: purchaseConfig.maxViewCount,
    intervalHours: purchaseConfig.intervalHours,
    navigationModal: viewerConfig.navigationModal,
    readyModal: viewerConfig.readyModal,
  }

  return BUTTON_PROPS
}

const getReadyModalProps = (userPurchaseFrequencyType) => {
  const purchaseConfig =
    PURCHASE_FREQUENCY_AD_CONFIGS[userPurchaseFrequencyType]

  const viewerType = purchaseConfig.viewerType

  const READY_MODAL_PROPS = {
    userPurchaseFrequencyType,
    modalBackgroundImageUrl: 'eggBreakers/fortuneCookieModal.png',
    modalPath: VIEWER_TYPE_AD_CONFIGS[viewerType].navigationModal,
    modalImageUrl1: '/eggBreakers/fortuneCookie.png',
    modalImageUrl2: '/eggBreakers/hammer.png',
    modalText1: '포춘쿠키를 열고',
    modalText2: `물 최대 ${purchaseConfig.textMaxRewardAmount}g을 받아보세요!`,
    CTRButtonText: '포춘쿠키 깨러 가기',
  }

  return READY_MODAL_PROPS
}

// 상품 모달 PROPS를 동적으로 반환
const getGoodsModalProps = async (userPurchaseFrequencyType) => {
  const fortuneCookieAdStatus = await backendApis.getCPMAdStatusByType({
    type: CPM_ENTERING_COMPONENTS.FORTUNE_COOKIE_AD,
  })
  const { rewardAmount = 20 } = fortuneCookieAdStatus?.data ?? {}

  const purchaseConfig =
    PURCHASE_FREQUENCY_AD_CONFIGS[userPurchaseFrequencyType]

  const GOODS_MODAL_COMMON_PROPS = {
    BROWSING_TIME_SEC: purchaseConfig.browsingSeconds,
    FIRST_BUTTON_TEXT: '준비 중',
    FINAL_BUTTON_TEXT: `상품 구경하기`,
    MODAL_IMAGE_URL:
      'https://assets.ilevit.com/ed38b982-2d8d-44b0-b175-1ad613d2bbb7.png',
    MODAL_TEXT_1: `${purchaseConfig.browsingSeconds}초 간 상품을 구경하면`,
    MODAL_TEXT_2: `물 ${rewardAmount}g`,
    MODAL_TEXT_3: '을 받아요',
    CLOSE_BUTTON_POSITION: { top: '18%', right: '15%' }, // TODO: 수정필요 - 레디모달에 맞게
    ANIMATION_DURATION: 1500,
    ENTERING_COMPONENT: CPM_ENTERING_COMPONENTS.FORTUNE_COOKIE_AD,
  }

  const CPM_PURCHASE_SCREEN_COMMON_PROPS = {
    title: '포춘쿠키 상품 구경하기',
    image: 'https://assets.ilevit.com/2cfa0fa4-f146-429f-a44c-5a5375f48bf5.png', // TODO: 수정필요
    ratio: 99 / 360,
    enteringComponent: CPM_ENTERING_COMPONENTS.FORTUNE_COOKIE_AD,
    questTimer: purchaseConfig.browsingSeconds / 60,
    isTimerOn: true,
    userPurchaseFrequencyType: userPurchaseFrequencyType,
    questRewardText: `초간 구경하면 물을 ${rewardAmount}g 받아요!`,
    scrollQuestReward: `물 ${rewardAmount}g`,
    buyingReward: '고급 비료 5개, 물 1,000g',
  }

  const GOODS_MODAL_PROPS = {
    GOODS_MODAL_COMMON_PROPS: { ...GOODS_MODAL_COMMON_PROPS },
    CPM_PURCHASE_SCREEN_PROPS: {
      ...CPM_PURCHASE_SCREEN_COMMON_PROPS,
    },
  }

  return GOODS_MODAL_PROPS
}

const getVideoModalProps = (userPurchaseFrequencyType) => {
  const purchaseConfig =
    PURCHASE_FREQUENCY_AD_CONFIGS[userPurchaseFrequencyType]

  return {
    VIDEO_MODAL_COMMON_PROPS: {
      ...purchaseConfig,
      viewerType: CPM_VIEWER_TYPES.VIDEO,
    },
    CPM_PURCHASE_SCREEN_PROPS: {
      ...VIEWER_TYPE_AD_CONFIGS[CPM_VIEWER_TYPES.VIDEO],
      enteringComponent: CPM_ENTERING_COMPONENTS.FORTUNE_COOKIE_AD,
      userPurchaseFrequencyType,
    },
  }
}

export {
  getButtonProps,
  getGoodsModalProps,
  getReadyModalProps,
  getVideoModalProps,
}
