import React, { useState, useEffect } from 'react'
import { useSearchParam } from 'react-use'
import Layout from '../comps/atoms/layout'
import backendApis from '../utils/backendApis'
import GganbuWithEventIcons from '../comps/atoms/gganbuWithEventIcons'
import Farm from '../comps/molecules/farm'
import GaugeBarPercentage from '../comps/atoms/gaugeBarPercentage'
import GaugeBar from '../comps/atoms/gaugeBar'
import commaNumber from 'comma-number'
import Modal from 'react-modal'
import WaterEffect from '../comps/atoms/waterEffect'
import GetResourceModal from '../comps/atoms/getResourceModal'
import dayjs from 'dayjs'
import WhiteTextTimer from '../comps/WhiteTextTimer'
import LoadingIndicator from '../comps/loadingIndicator'
import { NavLink, useParams } from 'react-router-dom'
import MessageModal from '../comps/atoms/MessageModal'
import { BottomSheet } from 'react-spring-bottom-sheet'
import GganbuFarmQuickButton from '../comps/atoms/GganbuFarmQuickButton'
import ReviewModal from '../comps/atoms/reviewModal'
import PurchaseForGganbuModal from '../comps/atoms/purchaseForGganbuModal'
import useCheckCodePushVersion from '../utils/customHooks/useConvertCodePushVersion'
import BasicButtonGganbu from '../comps/atoms/basicButtonGganbu'
import BadgeModal from '../comps/atoms/badgeModal'
import PlantBasicInfo from '../comps/molecules/PlantBasicInfo'
import PokeFriendModal from '../comps/atoms/pokeFriendModal'
import ResurrectionModal from '../comps/atoms/Modals/ResurrectionModal'
import GganbuGiftModal from '../comps/atoms/Modals/GganbuGiftModal'
import indexStore from '../store/indexStore'
import { observer } from 'mobx-react-lite'
import ToastStore from '../store/ToastStore'
import GganbuSurprisePurchaseModal from 'comps/atoms/GganbuSurprisePurchaseModal'
import GganbuFarmBrandDealModal from 'comps/atoms/Modals/GganbuFarmBrandDealModal'
import AB from 'utils/ab'
import { API_URI } from 'utils/constant'
import BokForGganbuModal from 'comps/atoms/bokForGganbuModal'
import BokSentModal from 'comps/atoms/bokSentModal'
import DealAutomationStore from 'store/DealAutomationStore'
import UserStore from 'store/UserStore'
import ABStore from 'store/ABStore'
import ResurrectNudgeStampModal from 'comps/atoms/resurrectNudgeStampModal'
import moment from 'moment'

const fetcher = async (url, token, method = 'GET', params = {}) => {
  const resource = method === 'GET' ? url + new URLSearchParams(params) : url
  const init = ['POST', 'PUT', 'DELETE'].includes(method)
    ? {
        body: JSON.stringify(params),
        headers: {},
      }
    : { headers: {} }
  init['method'] = method
  init.headers['Content-Type'] = 'application/json'
  init.headers['x-access-token'] = token
  try {
    const res = await fetch(API_URI + resource, init)

    const data = await res.json()

    return data
  } catch (err) {
    return null
  }
}

const getGganbuList = async ({ token, setGganbuData }) => {
  const result = await backendApis.getGganbuList(token)

  if (result?.data) {
    setGganbuData(result?.data)
    window.localStorage.setItem('gganbuData', JSON.stringify(result?.data))
  }
}

const GganbuBoxInfo = ({ ModalStore }) => (
  <div className='absolute top-0 left-0 z-[900] w-screen h-screen bg-black bg-opacity-80 flex flex-col items-center justify-center'>
    <div className='relative w-[80vw] h-[130vw] bg-[#EEFFE6] border-[1vw] border-[#98E275] rounded-xl pt-10 pb-4 text-[#007D0D] flex flex-col items-center justify-between text-center'>
      <img src='/gganbuBox/modalTitle.png' className='absolute top-[-14vw]' />
      12월 18일부터
      <br />
      비료상자가 맞팜상자로 변경돼요
      <img src='/gganbuBox/change.png' className='w-[80%] py-4' />
      맞팜상자를 열면
      <br />
      아래 보상 중 하나를 받아요
      <div className='relative w-full h-[16vw] overflow-hidden flex-none my-[7vw]'>
        <div className='flex flex-row'>
          <div className='flex flex-row mr-[4vw] space-x-[4vw] animate-scroll'>
            {[
              'water',
              'fert1',
              'fert2',
              'fert3',
              'puppy',
              'fertSet3',
              'fertSet1',
              'fertSet2',
            ].map((elem) => (
              <img
                src={`/gganbuBox/${elem}.png`}
                className='w-[16vw] h-[16vw]'
              />
            ))}
          </div>
          <div className='flex flex-row space-x-[4vw] aria-hidden animate-scroll'>
            {[
              'water',
              'fert1',
              'fert2',
              'fert3',
              'puppy',
              'fertSet3',
              'fertSet1',
              'fertSet2',
            ].map((elem) => (
              <img
                src={`/gganbuBox/${elem}.png`}
                className='w-[16vw] h-[16vw]'
              />
            ))}
          </div>
        </div>
      </div>
      <img
        src='/gganbuBox/button.png'
        className='w-[90%]'
        onClick={() => {
          ModalStore.setIsModalOpen('basic')
        }}
      />
      <img
        src='/gganbuBox/largeTextBubble.png'
        className='absolute bottom-[-7vw] z-20 w-[40%] rotate-180'
      />
      <span className='absolute bottom-[-6vw] z-30 w-[60%] text-white text-sm'>
        {18 - new Date().getDate()}일 뒤 변경 예정
      </span>
    </div>
    <button
      style={{
        width: '50%',
        top: '12%',
        right: '-10%',
        position: 'absolute',
        zIndex: 3,
      }}
      onClick={() => {
        ModalStore.setIsModalOpen('basic')
      }}
    >
      <img
        style={{
          zIndex: 101,
          position: 'absolute',
          width: '15%',
          top: '73%',
          left: '50%',
        }}
        src='/icon/modalCloseButton.png'
        alt=''
      />
    </button>
  </div>
)

const GganbuFarm = observer(({ setGganbuKey }) => {
  const { ModalStore, TimeStore } = indexStore()
  const { BottomSheetStore } = indexStore()
  const { gganbuId } = useParams()
  const token = useSearchParam('token')
  const itemType = useSearchParam('itemType')
  const gganbuName = useSearchParam('gganbuName')
  const [farmData, setFarmData] = useState({})
  const [gganbuFarmResetInfo, setGganbuFarmResetInfo] = useState()
  const [gganbuFarmGiftInfo, setGganbuFarmGiftInfo] = useState()
  const stampInfo = farmData?.gganbuList?.find(
    (each) => each.userId === gganbuId && !each.deletedAt,
  )?.stampInfo
  const isStampTester =
    ABStore.gganbuStampTester &&
    stampInfo &&
    !stampInfo.closeRewardGivenAt &&
    moment().isBefore(moment('2024-11-19 00:00:00'))

  useEffect(() => {
    setGganbuKey(gganbuId)
  }, [setGganbuKey, gganbuId])

  const [gganbuData, setGganbuData] = useState(
    JSON.parse(window.localStorage.getItem('gganbuData')) || null,
  )
  const [gganbuFarmData, setGganbuFarmData] = useState(
    gganbuData?.gganbuList?.find(
      (each) => each?.userId?.toString() === gganbuId?.toString(),
    ),
  )

  const [rewardTypeForRewardToast, setRewardTypeForRewardToast] =
    useState('water')
  const [rewardAmountForRewardToast, setRewardAmountForRewardToast] =
    useState(0)
  const [level, setLevel] = useState(1)
  const [remainExp, setRemainExp] = useState(0)
  const [expToLevelUp, setExpToLevelUp] = useState(0)
  const [gganbuItemType, setGganbuItemType] = useState('')
  const [itemNameKorean, setItemNameKorean] = useState('default')
  const [waterModalVisible, setWaterModalVisible] = useState(false)
  const [canClickGiveWater, setCanClickGiveWater] = useState(false)
  const [canClickGetWater, setCanClickGetWater] = useState(false)
  const [getResourceModal, setGetResourceModal] = useState(false)
  const [animState, setAnimState] = useState('')
  const [lastGetWaterFromGganbu, setLastGetWaterFromGganbu] = useState(
    dayjs('1970-01-01T06:01:39.853+00:00'),
  )

  const [getWaterFromGganbuTimer, setGetWaterFromGganbuTimer] = useState(
    new Date(),
  )

  const [isLoading, setIsLoading] = useState(true)
  const [indexCount, setIndexCount] = useState(0)
  const [addWater, setAddWater] = useState(0)
  const [addFertilizer, setAddFertilizer] = useState(0)
  const [welcomeSpeechBubble, setWelcomeSpeechBubble] = useState(false)
  const [isButtonPressedGiveWater, setIsButtonPressedGiveWater] =
    useState(false)

  const [showSignPost, setShowSignPost] = useState(false)
  const [finishedFarmModal, setFinishedFarmModal] = useState(false)
  const [showMinimumWaterModal, setShowMinimumWaterModal] = useState(false)
  const [showRewardModal, setShowRewardModal] = useState(true)
  const [isButtonPressedOpenReward, setIsButtonPressedOpenReward] =
    useState(false)

  const [showMessageModal, setShowMessageModal] = useState(false)
  const [showMessageSuccessModal, setShowMessageSuccessModal] = useState(false)

  const [userCodePushVersion, setUserCodePushVersion] = useState(
    window.localStorage.getItem('userCodePushVersionNew'),
  )

  const [giveWaterLimitModal, setGiveWaterLimitModal] = useState(false)
  const [showPokeSuccessModal, setShowPokeSuccessModal] = useState(false)
  const [showPokeSixTimesSuccessModal, setShowPokeSixTimesSuccessModal] =
    useState(false)
  const [pokeSuccessRewardAmount, setPokeSuccessRewardAmount] = useState(0)

  const [isLoaded, setIsLoaded] = useState(false)
  const [showSuccessPopup, setShowSuccessPopup] = useState(false)

  const [pokeCount, setPokeCount] = useState(5)
  const [userIdTurn, setUserIdTurn] = useState(false)
  const [notMyTurnToast, setNotMyTurnToast] = useState(false)
  const [sentResurrectAlarm, setSentResurrectAlarm] = useState(false)
  const [alreadyReceivedAlarm, setAlreadyReceivedAlarm] = useState(false)
  const [canPoke, setCanPoke] = useState(false)
  const [nThHarvest, setNThHarvest] = useState(1)
  const [isEntryVisible, setIsEntryVisible] = useState(false)

  const gganbuFarmDealTest = AB(farmData?.userId, 'gganbuFarmDeal1220', [3])

  const getPokeData = async () => {
    const result = await backendApis.getPokeData(token, 'GET', {
      gganbuId: gganbuId,
    })

    if (result?.status === 200) {
      setPokeCount(result?.data?.pokeCount)
      setUserIdTurn(result?.data?.userIdTurn)
      if (result?.data?.userIdTurn) {
        setCanPoke(true)
      }
    }
  }

  useEffect(() => {
    if (farmData?.userId) {
      getPokeData()
    }
    if (!gganbuFarmData?.exp) {
      setFinishedFarmModal(true)
    }
  }, [gganbuId, farmData?.userId])

  const getGganbuFarmData = async () => {
    const result = await backendApis.getGganbuFarmInfo(token, 'GET', {
      gganbuId: gganbuId,
    })

    if (result?.farmLevel?.level6 <= result?.exp) {
      setFinishedFarmModal(true)
    }
    setGganbuFarmData(result)
  }
  const codePushVersionAvailable = useCheckCodePushVersion()

  function showRewardToast(rewardAmount, rewardtype = 'fertilizer') {
    setRewardTypeForRewardToast(rewardtype)
    setRewardAmountForRewardToast(rewardAmount)
    setGetResourceModal(true)
    setTimeout(() => {
      setGetResourceModal(false)
      setRewardAmountForRewardToast(0)
      setRewardTypeForRewardToast('fertilizer')
    }, 1500)
  }

  const getAlfarmData = async (token, farmData) => {
    if (token && !farmData?.userId) {
      fetcher(`/games/start-alfarm`, token, 'POST', {
        itemType: itemType,
      }).then((data) => {
        if (data?.userId) {
          setFarmData(data)
          setCanClickGiveWater(true)
          if (!gganbuFarmData?.isLeftUser || farmData?.resurrectBonus) {
            setCanClickGetWater(true)
          }
        } else {
          alert('정보를 불러오지 못했어요. 잠시 후 다시 시도해 주세요.')
        }
      })
    }
  }

  useEffect(() => {
    const startTime = new Date(
      DealAutomationStore?.firstGganbuFarmBalloonAdData?.timeRangeStart ??
        '2023-01-01',
    )
    const endTime = new Date(
      DealAutomationStore?.firstGganbuFarmBalloonAdData?.timeRangeEnd ??
        '2023-01-01',
    )

    const checkVisibility = () => {
      const currentTime = new Date()

      if (currentTime >= startTime && currentTime <= endTime) {
        setIsEntryVisible(true)
      } else {
        setIsEntryVisible(false)
      }
    }

    checkVisibility()

    // Set interval to check every minute
    const intervalId = setInterval(checkVisibility, 60 * 1000)

    return () => clearInterval(intervalId)
  }, [DealAutomationStore?.firstGganbuFarmBalloonAdData])

  function onDismiss() {
    setTimeout(() => BottomSheetStore.isBottomSheetOpen === 'basic', 50)
  }

  function convertCodePushVer(x) {
    if (!x || !x?.includes('.')) {
      return 0
    }
    return (
      Number(x.split('.')[0] * 1000) +
      Number(x.split('.')[1] * 100) +
      Number(x.split('.')[2])
    )
  }

  const shareHelp = () => {
    let arr = [
      [`${gganbuName}함께 작물 키워봐요!`, '저랑 같이 물 주면서 수확해봐요 💪'],
    ]
    let text = arr[Math.floor(Math.random() * arr.length)]
    const inviteData = {
      shareType: 'AlfarmLeftGganbuV2',
      title: text[0],
      description: text[1],
      bannerImage:
        'https://assets.ilevit.com/34ed5826-d1bd-4822-940f-02274f0a9042.png',
    }
    window.location.href = `#openAlfarmRemovedDependencyInviteModal.${JSON.stringify(
      inviteData,
    )}`
  }

  const sendResurrectAlarm = async () => {
    const result = await backendApis.sendResurrectAlarm(token, 'PUT', {
      gganbuId: gganbuId,
      gganbuName: gganbuName,
    })

    if (result?.status === 200) {
      setSentResurrectAlarm(true)
      setTimeout(() => {
        setSentResurrectAlarm(false)
      }, 1500)
    }
    if (result?.status === 201) {
      setAlreadyReceivedAlarm(true)
      setTimeout(() => {
        setAlreadyReceivedAlarm(false)
      }, 1500)
    }
  }

  const pokeFriend = async () => {
    const result = await backendApis.pokeFriend(token, 'PUT', {
      gganbuId: gganbuId,
      gganbuName: gganbuName,
    })

    if (result?.status === 200) {
    }
    if (result?.data?.status === 202) {
      setShowPokeSuccessModal(true)
      setTimeout(() => {
        setShowPokeSuccessModal(false)
      }, 1500)
    }
    if (result?.data?.status === 203) {
      setNotMyTurnToast(true)
      setTimeout(() => {
        setNotMyTurnToast(false)
      }, 1500)
    }
    if (result?.data?.status === 201) {
      setPokeSuccessRewardAmount(result?.data?.water)
      setShowPokeSixTimesSuccessModal(true)
      setTimeout(() => {
        setShowPokeSixTimesSuccessModal(false)
      }, 1500)
      let tmpFarmData = farmData
      if (UserStore.waterExceeded) {
        tmpFarmData.water += Math.round(result?.data?.water * 0.5)
      } else {
        tmpFarmData.water += result?.data?.water
      }
      setFarmData(tmpFarmData)
    }
    setPokeCount(pokeCount + 1)
    setUserIdTurn(false)
    setCanPoke(false)
  }

  const addStamp = async ({ gganbuFarmResetInfo }) => {
    const selectedGganbuData = gganbuFarmData
    const stampInfo = selectedGganbuData?.stampInfo
    const alreadyStampedToday =
      stampInfo &&
      moment(stampInfo?.lastStampedAt).format('YYYY-MM-DD') ===
        moment().format('YYYY-MM-DD')

    // 이미 도장 찍었거나, 오늘 물을 아직 안 준 경우

    if (alreadyStampedToday) {
      ToastStore.toastOn({
        type: 'emoji',
        message: '오늘 이미 도장을 찍었어요',
        emoji: '🌟',
        duration: 1500,
      })
      isPressed = false
      return false
    }

    if (!gganbuFarmResetInfo?.didGiveWaterToGganbuToday) {
      ModalStore.setIsModalOpen('basic')
      ToastStore.toastOn({
        type: 'emoji',
        message: '물을 주면 도장을 찍을 수 있어요',
        emoji: '🚿',
      })
      return
    }

    if (!stampInfo || stampInfo?.count >= 0) {
      const result = await backendApis.addGganbuStamp(token, 'PUT', {
        gganbuId: selectedGganbuData?.userId,
      })

      if (result?.status === 200) {
        let tmpFarmData = farmData

        if (result.data.reward) {
          // 물 받기
          tmpFarmData.water += result?.data?.reward
          tmpFarmData.gganbuList = tmpFarmData.gganbuList.map((each) => {
            if (each.userId === selectedGganbuData?.userId && !each.deletedAt) {
              return {
                ...each,
                stampInfo: {
                  count: 0,
                  lastStampedAt: new Date(),
                },
              }
            }
            return each
          })
          setAddWater(result?.data?.reward)

          setGetResourceModal(true)
          setTimeout(() => {
            setGetResourceModal(false)
          }, 1000)
        } else {
          // tmpFarmData.gganbuList에서 해당 gganbuId를 찾아서 stampInfo.count를 업데이트
          tmpFarmData.gganbuList = tmpFarmData.gganbuList.map((each) => {
            if (each.userId === selectedGganbuData?.userId && !each.deletedAt) {
              return {
                ...each,
                stampInfo: {
                  count: (each?.stampInfo?.count || 0) + 1,
                  lastStampedAt: new Date(),
                },
              }
            }
            return each
          })
        }

        setFarmData(tmpFarmData)

        return true
      }
    }

    return false
  }

  const finishStamp = async () => {
    const selectedGganbuData = gganbuFarmData

    const result = await backendApis.closeGganbuStamp(token, 'PUT', {
      gganbuId: selectedGganbuData?.userId,
    })

    if (result?.status === 200) {
      let tmpFarmData = farmData

      if (result.data.reward) {
        // 물 받기
        tmpFarmData.water += result?.data?.reward
        tmpFarmData.gganbuList = tmpFarmData.gganbuList.map((each) => {
          if (each.userId === selectedGganbuData?.userId && !each.deletedAt) {
            return {
              ...each,
              stampInfo: {
                closeRewardGivenAt: new Date(),
              },
            }
          }
          return each
        })
        setAddWater(result?.data?.reward)

        setGetResourceModal(true)
        setTimeout(() => {
          setGetResourceModal(false)
        }, 1000)
      } else {
        // tmpFarmData.gganbuList에서 해당 gganbuId를 찾아서 stampInfo.count를 업데이트
        tmpFarmData.gganbuList = tmpFarmData.gganbuList.map((each) => {
          if (each.userId === selectedGganbuData?.userId && !each.deletedAt) {
            return {
              ...each,
              stampInfo: {
                closeRewardGivenAt: new Date(),
              },
            }
          }
          return each
        })
      }

      setFarmData(tmpFarmData)

      return true
    }

    return false
  }

  useEffect(() => {
    const fetchData = async () => {
      if (!gganbuData?.gganbuList?.length) {
        await getGganbuList({ token, setGganbuData })
      }
      // gganbuFarmData 가 없 || 경험치가 높 || 이탈 유저면 다시 로드
      if (
        !gganbuData &&
        (!gganbuFarmData ||
          (level === 6 && remainExp < 500) ||
          gganbuFarmData?.isLeftUser)
      ) {
        await getGganbuFarmData()
      }
      await getAlfarmData(token, farmData)
      setFarmData(farmData)
      setGganbuItemType(gganbuFarmData?.itemType)
      setItemNameKorean(
        PlantBasicInfo.PlantNameAndAmount(gganbuFarmData?.itemType)?.koreanName,
      )
      setWelcomeSpeechBubble(true)
      getLastGetWaterFromGganbu()
      setTimeout(() => {
        setIsLoading(false)
      }, 300)

      setTimeout(() => {
        setWelcomeSpeechBubble(false)
      }, 3500)
      setShowSignPost(true)

      const result = await backendApis.clearedItemGganbu(token, 'GET', {
        gganbuId: gganbuId,
      })

      let count = 1
      if (result?.data?.count) {
        for (const [k, v] of Object.entries(result?.data?.count)) {
          count += v
        }
      }

      setNThHarvest(count)

      if (UserStore?.farmData?.resurrectBonus) {
        setGganbuFarmData((prev) => ({ ...prev, isLeftUser: false }))
      }
    }

    if (token && !farmData?.userId) {
      fetchData()
    }
  }, [token, farmData?.userId, gganbuId])

  const getLevel = async (exp, token) => {
    const level1 = gganbuFarmData?.farmLevel?.level1
    const level2 = gganbuFarmData?.farmLevel?.level2
    const level3 = gganbuFarmData?.farmLevel?.level3
    const level4 = gganbuFarmData?.farmLevel?.level4
    const level5 = gganbuFarmData?.farmLevel?.level5
    const level6 = gganbuFarmData?.farmLevel?.level6

    const levelData = [level1, level2, level3, level4, level5, level6]

    let level = 1
    let remainExp = 0
    let expToLevelUp = 0
    let prev = 0
    for (let i = 0; i < levelData.length; i++) {
      const each = levelData[i]
      if (exp > each) level++
      else {
        expToLevelUp = each - exp
        remainExp = exp - prev
        break
      }

      prev = each
    }

    setLevel(level)
    setRemainExp(remainExp)
    setExpToLevelUp(expToLevelUp)

    return { level, remainExp, expToLevelUp }
  }

  const showWaterModal = () => {
    setWaterModalVisible(true)
    setTimeout(() => {
      setWaterModalVisible(false)
    }, 1200)
  }
  const handleAnim = (action, duration, callback) => {
    setAnimState(action)
    getLevel(gganbuFarmData?.exp, token)
    setTimeout(() => {
      callback()
    }, duration)
  }

  const giveWaterBasket = async () => {
    if (farmData?.water < 10) {
      if (showMinimumWaterModal) return
      setShowMinimumWaterModal(true)
      setTimeout(() => {
        setShowMinimumWaterModal(false)
      }, 2000)
      return
    }
    setIndexCount(indexCount + 1)
    if (canClickGiveWater) {
      setCanClickGiveWater(false)
      const result = await backendApis.giveWaterBasketToGganbu(token, 'POST', {
        gganbuId: gganbuId,
      })

      if (!result?.data?.success) {
        setGiveWaterLimitModal(true)
        setTimeout(() => {
          setGiveWaterLimitModal(false)
          setCanClickGiveWater(true)
        }, 2000)
        return
      }
      let tmpFarmData = farmData
      tmpFarmData.water -= result?.data?.point
      // tmpFarmData.waterCount = result?.data?.waterCount;
      setFarmData(tmpFarmData)
      let tempGganbuFarmData = gganbuFarmData
      tempGganbuFarmData.exp += result?.data?.exp
      setGganbuFarmData(tempGganbuFarmData)
      showWaterModal()
      setCanClickGiveWater(true)

      handleAnim('giveWaterBasket', 1500, () => {
        setAnimState('')
      })
    }
  }

  const giveWaterBasketFive = async () => {
    if (farmData?.water < 50) {
      return
    }
    setIndexCount(indexCount + 1)
    if (canClickGiveWater) {
      setCanClickGiveWater(false)
      const result = await backendApis.giveWaterBasketFiveToGganbu(
        token,
        'POST',
        {
          gganbuId: gganbuId,
        },
      )
      if (!result?.data?.success) {
        setGiveWaterLimitModal(true)
        setTimeout(() => {
          setGiveWaterLimitModal(false)
          setCanClickGiveWater(true)
        }, 2000)
        return
      }
      let tmpFarmData = farmData
      tmpFarmData.water -= result?.data?.point
      // tmpFarmData.waterCount = result?.data?.waterCount;
      setFarmData(tmpFarmData)
      let tempGganbuFarmData = gganbuFarmData
      tempGganbuFarmData.exp += result?.data?.exp
      setGganbuFarmData(tempGganbuFarmData)
      showWaterModal()
      setCanClickGiveWater(true)
      handleAnim('giveWaterBasket', 1500, () => {
        setAnimState('')
      })
    }
  }

  const getBonusRewardInfo = async () => {
    const result = await backendApis.getBonusRewardInfo(token, 'GET', {
      gganbuId: gganbuId,
    })

    setGganbuFarmResetInfo(result?.gganbuFarmResetInfo)
    setGganbuFarmGiftInfo(result?.giftResetInfo)
  }

  useEffect(() => {
    getLevel(gganbuFarmData?.exp)
  }, [token, gganbuFarmData?.exp])

  const bonusRewardBox = async () => {
    if (!gganbuFarmResetInfo?.bonusBoxAvailable) {
      return false
    }

    const result = await backendApis.bonusRewardBox(token, 'POST', {
      gganbuId: gganbuId,
    })
    if (result?.data?.success === false) {
      if (result?.data?.msg === 'exceed limit') {
        // 토스트 띄워주기

        ToastStore?.toastOn({
          type: 'error',
          message: '[일일 제한 초과] 내일 다시 시도해주세요',
          duration: 3000,
        })
      }
      return
    }

    if (result?.data?.success === true) {
      setAddFertilizer(result?.data?.bonusFertilizer)
      setShowRewardModal(true)
      setGganbuFarmResetInfo(false)
    }
    // }
  }

  const bonusRewardButton = () => {
    if (
      (gganbuFarmData?.isLeftUser ||
        TimeStore.currentDayJS.isSameOrAfter(
          dayjs(gganbuFarmData?.lastGiveWaterAt).add(5, 'd'),
        )) &&
      !farmData?.resurrectBonus
    ) {
      return (
        <button
          style={{
            width: '100%',
            fontFamily: 'maplestory',
            color: 'white',
            fontSize: '4.5vw',
            borderRadius: '30vw',
            padding: 16,
            background: isButtonPressedOpenReward
              ? ' linear-gradient(180deg, #F2BA73 0%, #ED7732 100%)'
              : ' linear-gradient(180deg, #F2BA73 0%, #ED7732 100%)',
            boxShadow: isButtonPressedOpenReward
              ? 'inset 3px 3px 4px rgba(79, 19, 0, 0.15)'
              : '',
          }}
          onClick={() => {
            ModalStore.setIsModalOpen('basic')
            shareHelp()
          }}
          onPointerDown={() => {
            setIsButtonPressedOpenReward(true)
          }}
          onPointerCancel={() => {
            setIsButtonPressedOpenReward(false)
          }}
          onPointerUp={() => {
            setIsButtonPressedOpenReward(false)
          }}
        >
          {gganbuName?.length > 4 ? (
            <>{gganbuName.slice(0, 4)}..</>
          ) : (
            <>{gganbuName.slice(0, 4)}</>
          )}
          님 링크로 초대하기
        </button>
      )
    } else if (!gganbuFarmResetInfo?.didGiveWaterToGganbuToday) {
      return (
        <div>
          <button
            style={{
              width: '100%',
              fontFamily: 'maplestory',
              color: 'black',
              fontSize: '4.5vw',
              borderRadius: '30px',
              padding: 16,
              zIndex: 999,
              background: isButtonPressedGiveWater
                ? 'linear-gradient(180deg, #FFE99A 0%, #F7D046 31.77%, #F6CB45 64.58%, #F09236 100%)'
                : 'linear-gradient(180deg, #FFE99A 0%, #F7D046 31.77%, #F6CB45 64.58%, #F09236 100%)',
              boxShadow: isButtonPressedGiveWater
                ? 'inset 3px 3px 4px rgba(79, 19, 0, 0.15)'
                : '',
            }}
            onClick={() => {
              ModalStore.setIsModalOpen('basic')
              giveWaterBasket()
              setTimeout(() => {
                ModalStore.setIsModalOpen('bonusRewardBox')
              }, 1500)
            }}
            onPointerDown={() => {
              setIsButtonPressedGiveWater(true)
            }}
            onPointerCancel={() => {
              setIsButtonPressedGiveWater(false)
            }}
            onPointerUp={() => {
              setIsButtonPressedGiveWater(false)
            }}
          >
            물주기
          </button>
        </div>
      )
    } else if (
      gganbuFarmResetInfo?.didGiveWaterToGganbuToday &&
      gganbuFarmResetInfo?.bonusBoxAvailable &&
      !gganbuFarmData?.isLeftUser
    ) {
      return (
        <button
          style={{
            width: '100%',
            fontFamily: 'maplestory',
            color: 'white',
            fontSize: '4.5vw',
            borderRadius: '30vw',
            padding: 16,
            background: isButtonPressedOpenReward
              ? ' linear-gradient(180deg, #F2BA73 0%, #ED7732 100%)'
              : ' linear-gradient(180deg, #F2BA73 0%, #ED7732 100%)',
            boxShadow: isButtonPressedOpenReward
              ? 'inset 3px 3px 4px rgba(79, 19, 0, 0.15)'
              : '',
          }}
          onClick={() => {
            ModalStore.setIsModalOpen('basic')
            bonusRewardBox()
          }}
          onPointerDown={() => {
            setIsButtonPressedOpenReward(true)
          }}
          onPointerCancel={() => {
            setIsButtonPressedOpenReward(false)
          }}
          onPointerUp={() => {
            setIsButtonPressedOpenReward(false)
          }}
        >
          비료상자 열기
        </button>
      )
    } else {
      return (
        <button
          style={{
            width: '100%',
            backgroundColor: '#ADADAD',
            fontFamily: 'maplestory',
            color: 'black',
            fontSize: '4.5vw',
            borderRadius: '30px',
            padding: 16,
          }}
        >
          내일 다시 받을 수 있어요
        </button>
      )
    }
  }

  const waterFromGganbu = async () => {
    if (TimeStore.currentDayJS - lastGetWaterFromGganbu < 14400000) {
      return false
    }
    if (canClickGetWater) {
      setCanClickGetWater(false)

      const result = await backendApis.waterFromGganbu(token, 'POST', {
        gganbuId: gganbuId,
      })

      if (result?.data?.success === false) {
        if (result?.data?.msg === 'exceed limit') {
          // 토스트 띄워주기
          ToastStore?.toastOn({
            type: 'error',
            message: '[일일 제한 초과] 내일 다시 시도해주세요',
            duration: 3000,
          })
        }
        let tmpFarmData = farmData
        tmpFarmData.water += 0
        setFarmData(tmpFarmData)
        setAddWater(result?.data?.water)
        return
      }
      let tmpFarmData = farmData
      if (UserStore.waterExceeded) {
        tmpFarmData.water += Math.round(result?.data?.water * 0.5)
        setAddWater(Math.round(result?.data?.water * 0.5))
      } else {
        tmpFarmData.water += result?.data?.water
        setAddWater(result?.data?.water)
      }
      setFarmData(tmpFarmData)
      setGganbuData({
        ...gganbuData,
        gganbuList: gganbuData?.gganbuList?.map((each) => {
          const match = each?.userId?.toString() === gganbuId?.toString()
          if (match) {
            return {
              ...each,
              lastGetWaterAt: new Date(),
            }
          }
          return each
        }),
      })
      window.localStorage.setItem(
        'gganbuData',
        JSON.stringify({
          ...gganbuData,
          gganbuList: gganbuData?.gganbuList?.map((each) => {
            const match = each?.userId?.toString() === gganbuId?.toString()
            if (match) {
              return {
                ...each,
                lastGetWaterAt: new Date(),
              }
            }
            return each
          }),
        }),
      )
      setGetResourceModal(true)
      setTimeout(() => {
        setGetResourceModal(false)
      }, 1000)

      setCanClickGetWater(false)
    }
  }

  const getLastGetWaterFromGganbu = () => {
    const result = gganbuData?.gganbuList?.find(
      (each) => each?.userId?.toString() === gganbuId?.toString(),
    )?.lastGetWaterAt

    let lastGetWaterFromGganbuAt
    if (!result) {
      lastGetWaterFromGganbuAt = dayjs('1970-01-01T06:01:39.853+00:00')
    } else if (result) {
      lastGetWaterFromGganbuAt = dayjs(result)
    } else {
      lastGetWaterFromGganbuAt = dayjs('1970-01-01T06:01:39.853+00:00')
    }
    setLastGetWaterFromGganbu(lastGetWaterFromGganbuAt)

    let gganbuGetWaterAt
    if (!result) {
      gganbuGetWaterAt = new Date('1970-01-01T06:01:39.853+00:00')
    } else if (result) {
      gganbuGetWaterAt = new Date(result)
    } else {
      gganbuGetWaterAt = new Date('1970-01-01T06:01:39.853+00:00')
    }
    setGetWaterFromGganbuTimer(gganbuGetWaterAt)

    if (
      TimeStore.currentDayJS - lastGetWaterFromGganbuAt >= 14400000 &&
      (gganbuFarmData?.isLeftUser === false || farmData?.resurrectBonus)
    ) {
      setCanClickGetWater(true)
    } else {
      setCanClickGetWater(false)
    }
  }

  useEffect(() => {
    if (token && farmData?.water) {
      const fetchData = async () => {
        if (
          !gganbuFarmData ||
          (level === 6 && remainExp < 500) ||
          gganbuFarmData?.isLeftUser
        ) {
          await getGganbuFarmData()
        }
      }
      fetchData()
      getBonusRewardInfo()
    }
  }, [token, farmData?.water, addWater, gganbuFarmData?.isLeftUser])

  useEffect(() => {
    getLastGetWaterFromGganbu()
  }, [
    token,
    farmData?.water,
    addWater,
    gganbuData?.gganbuList?.find(
      (each) => each?.userId?.toString() === gganbuId?.toString(),
    )?.lastGetWaterAt,
  ])

  if (isLoading) {
    return (
      <Layout isLoading={isLoading}>
        <LoadingIndicator isLoading={isLoading} />
      </Layout>
    )
  }

  return (
    <div className='w-full h-full fadeInGganbu'>
      <Layout isLoading={isLoading}>
        <header>
          <div
            style={{
              paddingTop: '10vw',
              position: 'absolute',
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              alignItems: 'center',
              zIndex: 1,
            }}
            className='inset-top'
          >
            <NavLink
              to={`/farmPage?token=${token}&itemType=${itemType}`}
              //   style={{ width: "80vw" }}
            >
              <button
                style={{
                  width: '7vw',
                  marginLeft: '2vw',
                  paddingTop: '3vw',
                }}
                action='goBack'
                // onClick={() => {
                //   window.location.href = "#closeGame";
                // }}
              >
                <img src='/icon/Back.png' alt='' />
              </button>
            </NavLink>
          </div>
        </header>

        {/* <GganbuFarmTopBanner token={token} /> */}
        {/* 우측 버튼 */}
        <div
          style={{
            height: '40vh',
            position: 'absolute',
            top: '20vw',
            right: '2vw',
            overflowY: 'scroll',
            overflowX: 'hidden',
            display: 'flex',
            flexDirection: 'column',
            zIndex: 102,
            color: '#bbb',
          }}
          className={'masked-overflow'}
        >
          <GganbuFarmQuickButton
            action='message'
            onClick={() => {
              BottomSheetStore.setIsbottomSheetOpen('sendMessage')
            }}
          />
          <GganbuFarmQuickButton
            action='pokeFriend'
            onClick={() => {
              ModalStore.setIsModalOpen('pokeFriend')
            }}
          />
          <GganbuFarmQuickButton
            action='gift'
            onClick={() => {
              ModalStore.setIsModalOpen('gganbuGift')
            }}
          />
        </div>

        {/* 좌측 버튼 */}
        <div
          style={{
            height: '40vh',
            position: 'absolute',
            top: '20vw',
            left: '2vw',
            overflowY: 'scroll',
            overflowX: 'hidden',
            display: 'flex',
            flexDirection: 'column',
            zIndex: 100,
            color: '#bbb',
          }}
          className={'masked-overflow'}
        >
          <GganbuFarmQuickButton
            action='badgeIcon'
            onClick={() => {
              ModalStore.setIsModalOpen('badge')
            }}
          />
        </div>

        {/* 맞팜 브랜드딜 진입점 */}
        {showSignPost && (
          <div
            style={{
              position: 'absolute',
              zIndex: 3,
              fontFamily: 'maplestory',
              bottom: '101.5vw',
              left: '27.5%',
              width: '50%',
              flex: 1,
            }}
          >
            <div
              className='fadeOutEffect'
              style={{
                position: 'absolute',
                zIndex: 301,
                alignItems: 'center',
                justifyContent: 'center',
                color: 'black',
                fontWeight: 'bold',
                transform: 'rotate(-15deg)',
                backgroundColor: 'white',
                padding: 8,
                border: '2px solid red',
                borderRadius: 8,
                marginTop: '-8vw',
                marginLeft: '-7vw',
                opacity: 0,
              }}
            >
              {`${nThHarvest}번째 수확 중`}
            </div>
            <img
              style={{
                width: '90%',
                height: '90%',
              }}
              src='/icon/alfarmGganbuBackGround.png'
              alt=''
            />
            <div
              style={{
                position: 'absolute',
                zIndex: 101,
                fontFamily: 'maplestory',
                alignItems: 'center',
                width: '90%',
                color: 'black',
                textAlign: 'center',
                bottom: '4.5vw',
                fontSize: '3.5vw',
                top: '2.2vw',
              }}
            >
              {gganbuName?.length > 5 ? (
                <>{gganbuName.slice(0, 5)}..</>
              ) : (
                <>{gganbuName.slice(0, 5)}</>
              )}
              님의
              <div
                style={{
                  color: 'black',
                }}
              >
                {itemNameKorean} 농장
              </div>
            </div>
          </div>
        )}
        {isEntryVisible &&
          DealAutomationStore?.firstGganbuFarmBalloonAdData?.dealId &&
          ABStore.alfarmBallon1108 &&
          codePushVersionAvailable(
            UserStore?.codePushVersionFromAlwayzApp,
            '6.8.29',
          ) && (
            <button
              style={{
                width: '38vw',
                position: 'absolute',
                zIndex: 100,
                bottom: '120vw',
                left: '30%',
              }}
              className='flex flex-col items-center slide-top-bottom'
              onClick={() => {
                ModalStore.setIsModalOpen('gganbuFarmBrandDeal')
              }}
            >
              <img
                alt=''
                src={
                  DealAutomationStore?.firstGganbuFarmBalloonAdData?.entryIcon
                }
              />
            </button>
          )}
        <div
          style={{
            position: 'absolute',
            width: '100%',
            justifyContent: 'center',
            alignItems: 'center',
            alignContent: 'center',
            left: 0,
            right: 0,
            bottom: '50vw',
            display: 'flex',
            flexDirection: 'column',
            alignSelf: 'center',
          }}
        >
          {welcomeSpeechBubble && (
            <img
              style={{
                position: 'absolute',
                zIndex: 101,
                bottom: '35vw',
                width: '80%',
              }}
              src='/icon/welcomeSpeechBubble.png'
              alt=''
            />
          )}

          <Farm
            id={PlantBasicInfo.IdFromPlantName(gganbuItemType)}
            level={level}
            token={token}
            farmData={gganbuFarmData}
            setFarmData={setGganbuFarmData}
            gganbuMode
            welcomeSpeechBubble
            isLoading={isLoading}
            myFarmData={farmData}
          />

          <div
            style={{
              position: 'absolute',
              bottom: '-1vw',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              alignContent: 'center',
              flexDirection: 'column',
              width: '100%',
            }}
          >
            <GaugeBar
              level={level}
              remainExp={remainExp}
              expToLevelUp={expToLevelUp}
              isGiveWater={animState === 'giveWaterBasket'}
              token={token}
              gganbuMode
            />
            <GaugeBarPercentage
              level={level}
              remainExp={remainExp}
              expToLevelUp={expToLevelUp}
              gganbuMode
            />
          </div>
        </div>
        {gganbuFarmResetInfo?.didGiveWaterToGganbuToday &&
          gganbuFarmResetInfo?.bonusBoxAvailable &&
          !gganbuFarmData?.isLeftUser && (
            <button
              style={{
                width: '18vw',
                position: 'absolute',
                left: '5vw',
                bottom: '65vw',
                zIndex: 10,
              }}
              className='slide-top-bottom'
              onClick={() => {
                ModalStore.setIsModalOpen('bonusRewardBox')
              }}
            >
              <img alt='' src={`/icon/fertBoxIconNew.png`} />
            </button>
          )}
        {canClickGetWater && (
          <button
            style={{
              width: '18vw',
              position: 'absolute',
              right: '5vw',
              bottom: '65vw',
              zIndex: 10,
            }}
            className='slide-top-bottom'
            onClick={waterFromGganbu}
          >
            <img alt='' src={`/icon/getWaterFromGganbuNew.png`} />
          </button>
        )}

        {codePushVersionAvailable(userCodePushVersion, '2.1.46') &&
          gganbuFarmData?.isLeftUser && (
            <button
              style={{
                width: '18vw',
                position: 'absolute',
                right: '5vw',
                bottom: '65vw',
                zIndex: 111,
              }}
              className='slide-top-bottom'
              onClick={() => {
                ModalStore.setIsModalOpen('openResurrection')
                // 스탬프 화면으로 이동
                backendApis.logABclick(token, 'PUT', {
                  location: 'gganbuStampModal',
                  action: 'openModalFromOldUI',
                  data: {
                    description: 'open stamp modal from old UI',
                    buttonLocation: 'right',
                  },
                })
              }}
            >
              <img
                alt=''
                src={
                  isStampTester
                    ? `/icon/icn_gganbuFarm_stamp.png`
                    : `/icon/isLeftGganbuIconNew.png`
                }
              />
            </button>
          )}
        <div
          style={{
            position: 'absolute',
            bottom: '22vw',
          }}
        >
          <div
            style={{
              position: 'absolute',
              bottom: '21vw',
              left: '1vw',
              zIndex: 7,
            }}
          >
            <NavLink
              to={`/farmPage?token=${token}&itemType=${itemType}`}
              //   style={{ width: "80vw" }}
            >
              <GganbuFarmQuickButton action='backToFarm' />
            </NavLink>
          </div>
          {canPoke && (
            <div className='w-[16vw] absolute bottom-[55vw] left-[56vw] z-2000'>
              <img
                onClick={() => {
                  pokeFriend()
                }}
                id='pokepoke'
                className='slide-tl '
                style={{
                  width: '16vw',
                  height: '16vw',
                  zIndex: 34000,
                }}
                alt=''
                src={'/icon/pokeWithFinger.png'}
              />
            </div>
          )}

          {canClickGetWater && gganbuFarmData?.isLeftUser === false && (
            <div
              style={{
                position: 'absolute',
                bottom: '1vw',
                left: '1vw',
                zIndex: 7,
              }}
            >
              <GganbuFarmQuickButton
                action='getWaterFromGganbu'
                onClick={waterFromGganbu}
              />
            </div>
          )}
          {!canClickGetWater && gganbuFarmData?.isLeftUser === false && (
            <div
              style={{
                position: 'absolute',
                bottom: '1vw',
                left: '1vw',
                zIndex: 7,
              }}
            >
              <GganbuFarmQuickButton action='alreadyGetWaterFromGganbu' />

              <div
                style={{
                  position: 'absolute',
                  //   bottom: "50vw",
                  zIndex: 8,
                  fontFamily: 'maplestory',
                  fontSize: 14,
                  textShadow:
                    '-1px 0 black, 0 1px black, 1px 0 black, 0 -1px black',
                }}
                className='absolute center gganbu timer'
              >
                <WhiteTextTimer
                  onTimeEnd={() => {
                    setCanClickGetWater()
                  }}
                  timeStamp={getWaterFromGganbuTimer}
                  timerMinutes={240}
                  color='white'
                />
              </div>
            </div>
          )}
          {gganbuFarmData?.isLeftUser === true && (
            <div
              style={{
                position: 'absolute',
                bottom: '1vw',
                left: '1vw',
                zIndex: 7,
              }}
            >
              <GganbuFarmQuickButton
                action='getWaterFromGganbu'
                onClick={() => {
                  ModalStore.setIsModalOpen('isLeftUser')
                  // 스탬프 화면으로 이동
                  backendApis.logABclick(token, 'PUT', {
                    location: 'gganbuStampModal',
                    action: 'openModalFromOldUI',
                    data: {
                      description: 'open stamp modal from old UI',
                      buttonLocation: 'left',
                    },
                  })
                }}
              />
            </div>
          )}
          <div
            style={{
              position: 'absolute',
              bottom: '1vw',
              left: '18.7vw',
              zIndex: 7,
            }}
          >
            <img
              src='/gganbuBox/smallTextBubble.png'
              className='w-[60%] absolute top-[-3vw] left-[3.7vw]'
            />
            <span className='absolute text-[2.7vw] top-[-2vw] left-[5.2vw]'>
              {18 - new Date().getDate()}일 남음
            </span>
            <GganbuFarmQuickButton
              action='fertBoxIcon'
              onClick={() => {
                ModalStore.setIsModalOpen('bonusRewardBox')
              }}
            />
          </div>
          <div
            style={{
              position: 'absolute',
              bottom: '1vw',
              left: '36.4vw',
              zIndex: 7,
            }}
          >
            <div class='blinking-slow absolute bg-[#EA3323] rounded-[5vw] p-[1.2vw] right-[3.5vw] top-[1.5vw]' />
            <GganbuFarmQuickButton
              action='gganbuBoxIcon'
              onClick={() => {
                ModalStore.setIsModalOpen('gganbuBox')
              }}
            />
          </div>

          {convertCodePushVer(userCodePushVersion) >=
            convertCodePushVer('1.6.10') && (
            <div
              style={{
                position: 'absolute',
                bottom: '1vw',
                left: '54.4vw',
                zIndex: 7,
              }}
            >
              <GganbuFarmQuickButton
                action='purchaseForGganbu'
                onClick={() => {
                  ModalStore.setIsModalOpen('purchaseForGganbu')
                }}
              />
            </div>
          )}
          {/* <div
            style={{
              position: "absolute",
              bottom: "1vw",
              left: "54.1vw",
              zIndex: 7,
            }}
          >
            <GganbuFarmQuickButton
              action="bokForGganbuModal"
              onClick={() => {
                ModalStore.setIsModalOpen("bokForGganbuModal");
              }}
            />
          </div> */}
          <div
            style={{
              position: 'absolute',
              bottom: '28vw',
              left: '99vw',
              zIndex: 7,
            }}
          >
            {farmData?.water >= 50 && (
              <BasicButtonGganbu
                type='waterFive'
                action='giveWaterFive'
                disabled={farmData?.water < 50 || !canClickGiveWater}
                onClick={giveWaterBasketFive}
              />
            )}
          </div>
          <div
            style={{
              position: 'absolute',
              left: '100vw',
              zIndex: 7,
              bottom: '0vw',
              right: '2.5vw',
            }}
          >
            {!waterModalVisible ? (
              <BasicButtonGganbu
                type='water'
                action='giveWater'
                label={
                  farmData?.water === undefined
                    ? `로딩중..`
                    : `${commaNumber(Math.floor(farmData?.water))}g`
                }
                // disabled={farmData?.water < 10 || !canClickGiveWater}
                onClick={giveWaterBasket}
              />
            ) : (
              <BasicButtonGganbu
                type='water'
                action='givingWater'
                label={
                  farmData?.water === undefined
                    ? `로딩중..`
                    : `${commaNumber(Math.floor(farmData?.water))}g`
                }
                disabled={farmData?.water < 10 || !canClickGiveWater}
                onClick={giveWaterBasket}
              />
            )}
          </div>
        </div>
        <div>
          <GganbuWithEventIcons
            token={token}
            itemType={itemType}
            gganbuData={gganbuData}
            gganbuId={gganbuId}
            setGganbuData={setGganbuData}
          />
        </div>
      </Layout>
      <Modal
        isOpen={waterModalVisible}
        onRequestClose={() => {}}
        style={{
          overlay: {
            background: 'rgba(0,0,0,0)',
            zIndex: 100,
          },
          content: {
            position: 'relative',
            width: '100%',
            height: '110%',
          },
        }}
        className='modal-basic'
      >
        <WaterEffect />
      </Modal>
      {getResourceModal && (
        <GetResourceModal
          amount={addWater}
          setGetResourceModal={setGetResourceModal}
        />
      )}
      {ModalStore?.isModalOpen === 'bonusRewardBox' && (
        <div
          style={{
            flex: 1,
            position: 'fixed',
            left: 0,
            top: 0,
            zIndex: 900,
            width: '100%',
            height: '100vh',
            backgroundColor: 'rgba(0,0,0,0.8)',
          }}
        >
          <div
            style={{
              position: 'relative',
              top: '20%',
            }}
          >
            <img
              style={{
                width: '90%',
                left: '5%',
                position: 'absolute',
              }}
              alt=''
              src={`${
                TimeStore.currentDayJS.isSameOrAfter(
                  dayjs(gganbuFarmData?.lastGiveWaterAt).add(5, 'd'),
                ) && !farmData?.resurrectBonus
                  ? '/icon/fertBoxForbidden_v3.png'
                  : '/icon/fertBox.png'
              }`}
            />
            <div
              style={{
                position: 'absolute',
                width: '70%',
                left: '15%',
                bottom: '-100vw',
              }}
            >
              {bonusRewardButton()}
            </div>
            {(TimeStore.currentDayJS.isSameOrAfter(
              dayjs(gganbuFarmData?.lastGiveWaterAt).add(5, 'd'),
            ) ||
              gganbuFarmData?.isLeftUser) &&
              !farmData?.resurrectBonus && (
                <div
                  style={{
                    position: 'absolute',
                    width: '70%',
                    left: '15%',
                    bottom: '-116vw',
                  }}
                >
                  <button
                    style={{
                      width: '100%',
                      fontSize: '4vw',
                      borderRadius: '30vw',
                      padding: 16,
                      background: isButtonPressedOpenReward
                        ? ' linear-gradient(180deg, #FFE99A 0%, #F7D046 33%, #F6CB45 66%, #F09236 100%)'
                        : ' linear-gradient(180deg, #FFE99A 0%, #F7D046 33%, #F6CB45 66%, #F09236 100%)',
                      boxShadow: isButtonPressedOpenReward
                        ? 'inset 3px 3px 4px rgba(79, 19, 0, 0.15)'
                        : '',
                    }}
                    onClick={() => {
                      ModalStore.setIsModalOpen('basic')
                      sendResurrectAlarm()
                    }}
                  >
                    <div style={{ fontSize: '4vw', color: '#402C24' }}>
                      {gganbuName?.length > 4 ? (
                        <>{gganbuName.slice(0, 4)}..</>
                      ) : (
                        <>{gganbuName.slice(0, 4)}</>
                      )}
                      님에게 푸시알림 보내기
                    </div>
                  </button>
                </div>
              )}
          </div>

          <button
            style={{
              width: '50%',
              top: '12%',
              right: '-10%',
              position: 'absolute',
              zIndex: 3,
            }}
            onClick={() => {
              ModalStore.setIsModalOpen('basic')
            }}
          >
            <img
              style={{
                zIndex: 101,
                position: 'absolute',
                width: '15%',
                top: '73%',
                left: '50%',
              }}
              src='/icon/modalCloseButton.png'
              alt=''
            />
          </button>
          <div
            className='absolute w-[100%] text-center'
            style={{
              bottom:
                (TimeStore.currentDayJS.isSameOrAfter(
                  dayjs(gganbuFarmData?.lastGiveWaterAt).add(5, 'd'),
                ) ||
                  gganbuFarmData?.isLeftUser) &&
                !farmData?.resurrectBonus
                  ? '30vw'
                  : '40vw',
            }}
          >
            <div className='text-lg'>
              비료 상자가 {18 - new Date().getDate()}일 후{' '}
              <span className='text-[#7BDC4D]'>맞팜상자</span>로 변경돼요
            </div>
            <div
              className='underline text-md'
              onClick={() => {
                ModalStore.setIsModalOpen('gganbuBox')
              }}
            >
              자세히 보러 가기
            </div>
          </div>
        </div>
      )}
      {ModalStore?.isModalOpen === 'gganbuBox' && (
        <GganbuBoxInfo ModalStore={ModalStore} />
      )}
      {finishedFarmModal && (
        <div
          style={{
            flex: 1,
            position: 'fixed',
            left: 0,
            top: 0,
            zIndex: 900,
            width: '100%',
            height: '100vh',
            backgroundColor: 'rgba(0,0,0,0.9)',
          }}
        >
          <div
            style={{
              background: 'white',
              width: '80vw',
              position: 'absolute',
              height: '50vw',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              borderRadius: '4vw',
            }}
          >
            <div
              style={{
                color: 'black',
                textAlign: 'center',
                lineHeight: '150%',
                fontSize: '5vw',
                marginTop: '8vw',
              }}
            >
              이미 수확이 끝난 농장이에요 <br />
              다른 친구의 농장에 방문해보세요!
            </div>
            <a
              style={{
                width: '70%',
                background:
                  'linear-gradient(180deg, #FFE99A 0%, #F7D046 31.77%, #F6CB45 64.58%, #F09236 100%)',
                fontFamily: 'maplestory',
                color: 'black',
                fontSize: '4.5vw',
                borderRadius: '30vw',
                padding: '4vw',
                position: 'absolute',
                left: '15%',
                bottom: '8vw',
                textAlign: 'center',
              }}
              href={`/farmPage?token=${token}&itemType=${itemType}`}
            >
              내 농장으로 돌아가기
            </a>
          </div>
        </div>
      )}
      {showMinimumWaterModal && (
        <div
          style={{
            flex: 1,
            position: 'absolute',
            left: '50%',
            top: '50%',
            zIndex: 111,
          }}
        >
          <div
            style={{
              background: '#FFFFFF',
              boxShadow: 'inset 0px 0px 12px -3px rgba(0, 0, 0, 0.35)',
              borderRadius: '16px',
              position: 'absolute',
              overlay: {
                background: 'rgba(0,0,0,0)',
              },
              width: '85vw',
              height: '22vw',
              transform: 'translate(-50%, -50%)',
              fontFamily: 'maplestory',
              textAlign: 'center',
              justifyContent: 'center',
              alignItems: 'center',
              alignContent: 'center',
              fontSize: '18px',
              padding: 22,
            }}
          >
            <div
              style={{
                marginTop: 6,
                color: 'rgba(64, 44, 36, 1)',
              }}
            >
              물은 10g이상 부터 줄 수 있어요
            </div>
            <div style={{ height: '18px' }} />
          </div>
        </div>
      )}
      {giveWaterLimitModal && (
        <div
          style={{
            flex: 1,
            position: 'absolute',
            left: '50%',
            top: '50%',
            zIndex: 111,
          }}
        >
          <div
            style={{
              background: '#FFFFFF',
              boxShadow: 'inset 0px 0px 12px -3px rgba(0, 0, 0, 0.35)',
              borderRadius: '16px',
              position: 'absolute',
              overlay: {
                background: 'rgba(0,0,0,0)',
              },
              width: '85vw',
              height: '30vw',
              transform: 'translate(-50%, -50%)',
              fontFamily: 'maplestory',
              textAlign: 'center',
              justifyContent: 'center',
              alignItems: 'center',
              alignContent: 'center',
              fontSize: '18px',
              padding: '4vw',
            }}
          >
            <div
              style={{
                marginTop: '2vw',
                color: 'rgba(64, 44, 36, 1)',
              }}
            >
              한 친구에게 하루 최대 <br></br>20번까지 물을 줄 수 있어요
              <div style={{ height: '8px' }} />
              내일 다시 물을 줄 수 있어요
            </div>
            <div style={{ height: '18px' }} />
          </div>
        </div>
      )}
      {BottomSheetStore.isBottomSheetOpen === 'sendMessage' && (
        <BottomSheet
          className='bottom-sheet'
          open={BottomSheetStore.isBottomSheetOpen === 'sendMessage'}
          expandOnContentDrag
          blocking={true}
          onDismiss={onDismiss}
          defaultSnap={({ lastSnap, snapPoints }) => {
            lastSnap ?? Math.max(...snapPoints)
          }}
        >
          <MessageModal
            gganbuName={gganbuName}
            showMessageModal={showMessageModal}
            setShowMessageModal={setShowMessageModal}
            token={token}
            gganbuId={gganbuId}
            showMessageSuccessModal={showMessageSuccessModal}
            setShowMessageSuccessModal={setShowMessageSuccessModal}
          />
        </BottomSheet>
      )}
      {showMessageSuccessModal && (
        <div
          style={{
            flex: 1,
            position: 'fixed',
            left: 0,
            top: 0,
            zIndex: 900,
            width: '100%',
            height: '100vh',
            backgroundColor: 'rgba(0,0,0,0.8)',
          }}
        >
          <div
            style={{
              position: 'relative',
              top: '40%',
            }}
          >
            <img
              style={{
                width: '60%',
                left: '20%',
                position: 'absolute',
              }}
              alt=''
              src={'/icon/sendMessageSuccess1.png'}
            />
          </div>
        </div>
      )}

      {ModalStore?.isModalOpen === 'openResurrection' && !isStampTester && (
        <ResurrectionModal
          token={token}
          gganbuName={gganbuName}
          gganbuId={gganbuId}
        />
      )}
      {ModalStore?.isModalOpen === 'openResurrection' &&
        isStampTester &&
        farmData.gganbuList?.find((each) => each.userId === gganbuId) && (
          <div className='w-[70vw]'>
            <ResurrectNudgeStampModal
              gganbuData={farmData.gganbuList?.find(
                (each) => each.userId === gganbuId,
              )}
              addStamp={addStamp}
              finishStamp={finishStamp}
              gganbuFarmResetInfo={gganbuFarmResetInfo}
            />
          </div>
        )}
      {ModalStore?.isModalOpen === 'pokeFriend' && (
        <PokeFriendModal
          setShowPokeSuccessModal={setShowPokeSuccessModal}
          setShowPokeSixTimesSuccessModal={setShowPokeSixTimesSuccessModal}
          setPokeSuccessRewardAmount={setPokeSuccessRewardAmount}
          token={token}
          gganbuName={gganbuName}
          gganbuId={gganbuId}
          gganbuFarmData={gganbuFarmData}
          myFarmData={farmData}
          setMyFarmData={setFarmData}
          isLoaded={isLoaded}
          setIsLoaded={setIsLoaded}
          pokeCount={pokeCount}
          setPokeCount={setPokeCount}
          userIdTurn={userIdTurn}
          setUserIdTurn={setUserIdTurn}
          pokeFriend={pokeFriend}
          canPoke={canPoke}
        />
      )}

      {ModalStore?.isModalOpen === 'gganbuGift' && (
        <GganbuGiftModal
          gganbuId={gganbuId}
          gganbuFarmGiftInfo={gganbuFarmGiftInfo}
          getBonusRewardInfo={getBonusRewardInfo}
          farmData={farmData}
          setFarmData={setFarmData}
          setShowSuccessPopup={setShowSuccessPopup}
        />
      )}

      {showSuccessPopup && (
        <div
          style={{
            flex: 1,
            position: 'fixed',
            left: 0,
            top: 0,
            zIndex: 900,
            width: '100%',
            height: '100vh',
            backgroundColor: 'rgba(0,0,0,0.8)',
          }}
        >
          <div
            style={{
              position: 'relative',
              top: '40%',
            }}
          >
            <img
              style={{
                width: '60%',
                left: '20%',
                position: 'absolute',
              }}
              alt=''
              src={'/gganbuGift/successModal.png'}
            />
          </div>
        </div>
      )}
      {showPokeSuccessModal && (
        <div
          style={{
            flex: 1,
            position: 'fixed',
            left: 0,
            top: 0,
            zIndex: 900,
            width: '100%',
            height: '100vh',
            backgroundColor: 'rgba(0,0,0,0.8)',
          }}
          onClick={() => {
            setShowPokeSuccessModal(false)
          }}
        >
          <div
            style={{
              position: 'relative',
              top: '40%',
            }}
          >
            <img
              style={{
                width: '60%',
                left: '20%',
                position: 'absolute',
              }}
              alt=''
              src={'/icon/youPokedGganbu.png'}
            />
          </div>
        </div>
      )}

      {sentResurrectAlarm && (
        <div
          style={{
            flex: 1,
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%,-50%)',
            zIndex: 2000,
            width: '80%',
            height: '30vw',
            background: 'rgba(0,0,0,0.8)',
            borderRadius: '4vw',
            fontFamily: 'maplestory',
            fontSize: '4.5vw',
            textAlign: 'center',
            lineHeight: '180%',
            justifyContent: 'center',
            alignContent: 'center',
            alignItems: 'center',
            flexDirection: 'column',
            display: 'flex',
          }}
        >
          친구에게 알림을 보냈어요! <br />* 알림은 1일 1회만 보낼 수 있어요
        </div>
      )}

      {alreadyReceivedAlarm && (
        <div
          style={{
            flex: 1,
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%,-50%)',
            zIndex: 2000,
            width: '80%',
            height: '30vw',
            background: 'rgba(0,0,0,0.8)',
            borderRadius: '4vw',
            fontFamily: 'maplestory',
            fontSize: '4.5vw',
            textAlign: 'center',
            lineHeight: '180%',
            justifyContent: 'center',
            alignContent: 'center',
            alignItems: 'center',
            flexDirection: 'column',
            display: 'flex',
          }}
        >
          친구가 이미 알림을 받았어요!
        </div>
      )}

      {notMyTurnToast && (
        <div
          style={{
            flex: 1,
            position: 'absolute',
            top: '60%',
            left: '50%',
            transform: 'translate(-50%,-50%)',
            zIndex: 2000,
            width: '80%',
            height: '30vw',
            background: 'rgba(0,0,0,0.8)',
            borderRadius: '4vw',
            fontFamily: 'maplestory',
            fontSize: '4.5vw',
            textAlign: 'center',
            lineHeight: '180%',
            justifyContent: 'center',
            alignContent: 'center',
            alignItems: 'center',
            flexDirection: 'column',
            display: 'flex',
          }}
        >
          앗! 내가 찌를 차례가 아니에요 <br />
          친구가 찌를 차례예요!
        </div>
      )}

      {showPokeSixTimesSuccessModal && pokeSuccessRewardAmount > 0 && (
        <div
          style={{
            flex: 1,
            position: 'fixed',
            left: 0,
            top: 0,
            zIndex: 900,
            width: '100%',
            height: '100vh',
            backgroundColor: 'rgba(0,0,0,0.8)',
          }}
        >
          <div
            style={{
              position: 'relative',
              top: '40%',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <img
              style={{
                width: '60%',
                left: '20%',
                position: 'absolute',
              }}
              alt=''
              src={'/icon/pokeSuccess.png'}
            />
            <div
              style={{
                color: 'black',
                fontSize: '5.4vw',
                position: 'relative',
                transform: 'translate(0%, 80%)',
                fontWeight: 'bold',
                textAlign: 'center',
                fontFamily: 'maplestory',
              }}
            >
              {pokeSuccessRewardAmount}g 획득!
            </div>
          </div>
        </div>
      )}
      {ModalStore?.isModalOpen === 'review' && (
        <ReviewModal
          farmData={farmData}
          token={token}
          id={PlantBasicInfo.IdFromPlantName(gganbuItemType)}
        />
      )}
      {ModalStore?.isModalOpen === 'purchaseForGganbu' && (
        <PurchaseForGganbuModal
          token={token}
          farmData={farmData}
          gganbuId={gganbuId}
        />
      )}
      {ModalStore?.isModalOpen === 'bokForGganbuModal' && (
        <BokForGganbuModal
          token={token}
          userId={farmData?.userId}
          gganbuId={gganbuId}
          gganbuName={gganbuName}
        />
      )}
      {ModalStore?.isModalOpen === 'bokSentModal' && (
        <BokSentModal
          token={token}
          userId={farmData?.userId}
          gganbuId={gganbuId}
          gganbuName={gganbuName}
        />
      )}
      {ModalStore?.isModalOpen === 'gganbuFarmBrandDeal' && (
        <GganbuFarmBrandDealModal token={token} farmData={farmData} />
      )}
      {ModalStore?.isModalOpen === 'gganbuSurprise' && (
        <GganbuSurprisePurchaseModal
          token={token}
          farmData={farmData}
          gganbuId={gganbuId}
        />
      )}
      {ModalStore?.isModalOpen === 'isLeftUser' && !isStampTester && (
        <div
          style={{
            flex: 1,
            position: 'fixed',
            left: 0,
            top: 0,
            zIndex: 2000,
            width: '100%',
            height: '100vh',
            backgroundColor: 'rgba(0,0,0,0.8)',
          }}
        >
          <div
            style={{
              left: '50%',
              top: '50%',
              background: '#FFFFFF',
              borderRadius: '16px',
              position: 'absolute',
              overlay: {
                background: 'rgba(0,0,0,0)',
              },
              width: '75vw',
              height: '45vw',
              transform: 'translate(-50%, -50%)',
              fontFamily: 'maplestory',
              textAlign: 'center',
              justifyContent: 'center',
              alignItems: 'center',
              alignContent: 'center',
              fontSize: '18px',
              zIndex: 1000,
            }}
          >
            <div
              style={{
                marginTop: '8vw',
                color: 'rgba(64, 44, 36, 1)',
                padding: 4,
              }}
            >
              {gganbuName?.length > 4 ? (
                <>{gganbuName.slice(0, 4)}..</>
              ) : (
                <>{gganbuName.slice(0, 4)}</>
              )}
              님이 작물을 돌보지 않아 <br></br>
              물을 받을 수 없어요 <br></br>
              <br></br>
              친구가 다시 농장에 돌아오면 <br></br> 물을 받을 수 있어요
            </div>
          </div>

          <button
            style={{
              width: '50%',
              right: '-5%',
              position: 'absolute',
              zIndex: 3,
              top: '30%',
            }}
            onClick={() => {
              ModalStore.setIsModalOpen('basic')
            }}
          >
            <img
              style={{
                zIndex: 101,
                position: 'absolute',
                width: '15%',
                left: '50%',
              }}
              src='/icon/modalCloseButton.png'
              alt=''
            />
          </button>
        </div>
      )}
      {ModalStore?.isModalOpen === 'isLeftUser' &&
        isStampTester &&
        farmData.gganbuList?.find((each) => each.userId === gganbuId) && (
          <div className='w-[70vw]'>
            <ResurrectNudgeStampModal
              gganbuData={farmData.gganbuList?.find(
                (each) => each.userId === gganbuId,
              )}
              addStamp={addStamp}
              finishStamp={finishStamp}
              gganbuFarmResetInfo={gganbuFarmResetInfo}
            />
          </div>
        )}

      {ModalStore?.isModalOpen === 'badge' && (
        <BadgeModal
          gganbuName={gganbuName}
          gganbuId={gganbuId}
          token={token}
          gganbuFarmData={gganbuFarmData}
        />
      )}
      {showRewardModal && addFertilizer === 1 && (
        <div
          style={{
            flex: 1,
            position: 'fixed',
            left: 0,
            top: 0,
            zIndex: 900,
            width: '100%',
            height: '100vh',
            backgroundColor: 'rgba(0,0,0,0.8)',
          }}
        >
          <div
            style={{
              position: 'relative',
              top: '40%',
            }}
          >
            <img
              style={{
                width: '60%',
                left: '20%',
                position: 'absolute',
              }}
              alt=''
              src={'/icon/reward1.png'}
            />
            <button
              style={{
                width: '35%',
                background:
                  'linear-gradient(180deg, #FFE99A 0%, #F7D046 31.77%, #F6CB45 64.58%, #F09236 100%)',
                fontFamily: 'maplestory',
                color: 'black',
                fontSize: '16px',
                borderRadius: '30px',
                padding: 16,
                position: 'absolute',

                left: '32%',
                top: '33vw',
              }}
              onClick={() => {
                setShowRewardModal(false)
              }}
            >
              확인
            </button>
          </div>
        </div>
      )}
      {showRewardModal && addFertilizer === 2 && (
        <div
          style={{
            flex: 1,
            position: 'fixed',
            left: 0,
            top: 0,
            zIndex: 900,
            width: '100%',
            height: '100vh',
            backgroundColor: 'rgba(0,0,0,0.8)',
          }}
        >
          <div
            style={{
              position: 'relative',
              top: '40%',
            }}
          >
            <img
              style={{
                width: '60%',
                left: '20%',
                position: 'absolute',
              }}
              alt=''
              src={'/icon/reward2.png'}
            />
            <button
              style={{
                width: '35%',
                background:
                  'linear-gradient(180deg, #FFE99A 0%, #F7D046 31.77%, #F6CB45 64.58%, #F09236 100%)',
                fontFamily: 'maplestory',
                color: 'black',
                fontSize: '16px',
                borderRadius: '30px',
                padding: 16,
                position: 'absolute',

                left: '32%',
                top: '33vw',
              }}
              onClick={() => {
                setShowRewardModal(false)
              }}
            >
              확인
            </button>
          </div>
        </div>
      )}
      {showRewardModal && addFertilizer === 3 && (
        <div
          style={{
            flex: 1,
            position: 'fixed',
            left: 0,
            top: 0,
            zIndex: 900,
            width: '100%',
            height: '100vh',
            backgroundColor: 'rgba(0,0,0,0.8)',
          }}
        >
          <div
            style={{
              position: 'relative',
              top: '40%',
            }}
          >
            <img
              style={{
                width: '60%',
                left: '20%',
                position: 'absolute',
              }}
              alt=''
              src={'/icon/reward3.png'}
            />
            <button
              style={{
                width: '35%',
                background:
                  'linear-gradient(180deg, #FFE99A 0%, #F7D046 31.77%, #F6CB45 64.58%, #F09236 100%)',
                fontFamily: 'maplestory',
                color: 'black',
                fontSize: '16px',
                borderRadius: '30px',
                padding: 16,
                position: 'absolute',

                left: '32%',
                top: '33vw',
              }}
              onClick={() => {
                setShowRewardModal(false)
              }}
            >
              확인
            </button>
          </div>
        </div>
      )}
    </div>
  )
})

export default GganbuFarm
