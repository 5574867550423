import backendApis from 'utils/backendApis'
import { useState } from 'react'
import BadahamRewardModal from './BadahamRewardModal'
import ModalStore from 'store/ModalStore'
import { observer } from 'mobx-react-lite'

const pearlStoreInfo = [
  {
    rewardText: '10~30g',
    icon: '/icon/water.png',
    needAmount: 1,
    questType: 'clear1',
  },
  {
    rewardText: '50~100g',
    icon: '/icon/water.png',
    needAmount: 3,
    questType: 'clear3',
  },
  {
    rewardText: '1~3개',
    icon: '/icon/fertilizer.png',
    needAmount: 6,
    questType: 'clear6',
  },
  {
    rewardText: '1~5개',
    icon: '/icon/fertilizerSet.png',
    needAmount: 9,
    questType: 'clear9',
  },
]

const floatingAnimation = `
  @keyframes floating {
    0% { transform: translate(-50%, 0); }
    50% { transform: translate(-50%, -2px); }
    100% { transform: translate(-50%, 0); }
  }
`;

const AdditionalRewardSection =observer (({clearedMissionCount = 0, dailyBadahamData, refetchDailyBadahamData}) => {
  const [rewardInfo, setRewardInfo] = useState(null)
  const [isLoading, setIsLoading] = useState(false)

  const isButtonEnabled = (additionalRewardInfo) => {
    const questInfo = dailyBadahamData?.questInfo?.[additionalRewardInfo.questType]
    const hasEnoughMissions = clearedMissionCount >= additionalRewardInfo.needAmount
    const hasNotReceivedReward = !questInfo || questInfo.length === 0
    
    return hasEnoughMissions && hasNotReceivedReward
  }

  const onClickRewardBtn = async (additionalRewardInfo) => {
    if(!isButtonEnabled(additionalRewardInfo) || isLoading) {
      return;
    }
    
    try {
      setIsLoading(true)
      const res = await backendApis.clearBadahamMission({
        questType: additionalRewardInfo.questType
      })
      const rewardInfo = res?.data?.rewardInfo
      setRewardInfo(rewardInfo)
      ModalStore.setIsModalOpen('badahamRewardModal')
      refetchDailyBadahamData()
    } catch (error) {
      console.error('보상 수령 실패:', error)
    } finally {
      setIsLoading(false)
    }
  }

  const getButtonText = (additionalRewardInfo) => {
    const questInfo = dailyBadahamData?.questInfo?.[additionalRewardInfo.questType]
    const hasNotReceivedReward = !questInfo || questInfo.length === 0
    return hasNotReceivedReward ? `미션 ${additionalRewardInfo.needAmount}번` : '완료'
  }

  return (
    <>
      <style>{floatingAnimation}</style>
      <div className='w-full flex flex-col items-start'>
        <div className='w-full flex flex-col items-center justify-center'>
          <div className='flex gap-[6px] pb-[10px] pt-[20px] flex-nowrap'>
            {pearlStoreInfo.map((item) => (
              <div
                key={item.questType}
                className='flex flex-col items-center justify-center gap-[8px] bg-white rounded-[12px] p-[6px] relative'
              >
                {isButtonEnabled(item) && (
                  <div className='absolute w-[80px] -top-[15px] left-1/2 flex flex-col items-center'
                    style={{
                      animation: 'floating 1.5s ease-in-out infinite',
                      transform: 'translateX(-50%)'
                    }}>
                    <img 
                      src="https://assets.ilevit.com/399dd5e6-024b-41aa-bb74-a63b3edbf4af.png"
                      alt="reward bubble"
                      className='w-full h-[32px]'
                    />
                    <span className='text-[12px] absolute top-[3px] text-[#4D4D4D] font-bold'>
                      보상 받기
                    </span>
                  </div>
                )}
                
                {!isButtonEnabled(item) && dailyBadahamData?.questInfo?.[item.questType]?.length > 0 && (
                  <div className='absolute inset-0 bg-white opacity-50 rounded-[12px] z-20' />
                )}
                
                <div className='flex flex-col items-center gap-[2px] relative z-10'>
                  <img src={item.icon} alt='reward' className='w-[36px]' />
                  <span className='text-black text-[3vw] font-bold'>
                    {item.rewardText}
                  </span>
                </div>
                <div
                  className='relative w-[84px] h-[31px] cursor-pointer'
                  onClick={() => onClickRewardBtn(item)}
                >
                  <img 
                    src={isButtonEnabled(item) 
                      ? "https://assets.ilevit.com/9fe9dc4f-e488-42e0-9f96-b762b6b888be.png"
                      : "https://assets.ilevit.com/7b98c0c4-0769-46f4-8303-8093b8fb8b26.png"
                    }
                    alt="button background"
                    className='w-full h-full object-contain'
                  />
                  <div className='absolute inset-0 flex items-center justify-center'>
                    <span className={`${isButtonEnabled(item) ? 'text-[#0090A8]' : 'text-[#FFF]'} text-[3.3vw] font-bold`}>
                      {getButtonText(item)}
                    </span>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
        {ModalStore?.isModalOpen === 'badahamRewardModal' && (
          <BadahamRewardModal rewardInfo={rewardInfo} />
        )}
      </div>
    </>
  )
})

export default AdditionalRewardSection
