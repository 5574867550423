import backendApis from 'utils/backendApis'
import { useState, useEffect } from 'react'
import { getCPMReadyModalPropsByType } from 'utils/alfarmCPMConstant/cpmAdConfig'
import { CPM_ENTERING_COMPONENTS } from 'utils/alfarmCPMConstant/cpmAdTypes'
import ModalStore from 'store/ModalStore'

const QuizAdReadyModal = () => {
  const enteringComponent = CPM_ENTERING_COMPONENTS.QUIZ_AD
  const [adConfig, setAdConfig] = useState({})
  const [quizData, setQuizData] = useState({
    quizQuestion: '',
    options: [],
    correctAnswer: '',
  })
  const [userSelectOption, setUserSelectOption] = useState(null)

  useEffect(() => {
    setAdConfigByType()
    getRandomQuizData()
  }, [])

  const setAdConfigByType = () => {
    const adConfig = getCPMReadyModalPropsByType(enteringComponent)
    setAdConfig(adConfig)
  }

  const getRandomQuizData = async () => {
    const result = await backendApis.getCPMRandomQuizData()
    if (result && result.status === 200) {
      setQuizData({
        quizQuestion: result.data.quizQuestion,
        options: result.data.options,
        correctAnswer: result.data.correctAnswer,
      })
    }
  }

  const postAnswer = async () => {
    if (userSelectOption === null) return

    const determineReward = async () => {
      const isCorrect = userSelectOption === quizData.correctAnswer

      await backendApis.presetCPMRewardByType({
        type: CPM_ENTERING_COMPONENTS.QUIZ_AD,
        props: {
          userPurchaseFrequencyType: adConfig.userPurchaseFrequencyType,
          isCorrect,
        },
      })
    }

    const openModal = () => {
      ModalStore.setShowCPMAdAnimation(true)
      ModalStore.setIsModalOpen(adConfig.modalPath)
    }

    await determineReward()
    openModal()
  }

  const CloseButton = () => {
    return (
      <button
        className='w-[10%] absolute right-[2%] top-[3%] z-[1113] transform-translate-x-[-50%] transform-translate-y-[-50%]'
        onClick={() => {
          setUserSelectOption(null)
          ModalStore.setIsModalOpen('basic')
        }}
      >
        <img
          className='absolute z-[101]'
          src='/icon/modalCloseButton.png'
          alt=''
        />
      </button>
    )
  }

  const OptionButton = ({ optionNum = 0 }) => {
    return (
      <button
        className='flex items-center w-full text-[4.5vw] leading-[150%] bg-white text-black p-[2.5vw] font-maplestory rounded-[3vw] mb-[2.5vw] border-[0.7vw]'
        style={{
          border:
            userSelectOption === optionNum
              ? '0.7vw solid #402C24'
              : '0.7vw solid #DEDEE3',
        }}
        onClick={() => setUserSelectOption(optionNum)}
      >
        {quizData.options[optionNum]}
      </button>
    )
  }

  const AnswerButton = () => {
    const baseButtonClass =
      'w-[55vw] h-[14vw] rounded-[99px] font-maplestory justify-center items-center text-[4.5vw] absolute bottom-[10vw] left-1/2 -translate-x-1/2'

    const buttonConfig =
      userSelectOption === null
        ? {
            className: 'bg-[#D3D7DD] text-[#707070]',
            text: adConfig.answerButtonTextBeforeSelection,
            onClick: undefined,
          }
        : {
            className: 'bg-[#F7C82A] text-black',
            text: adConfig.answerButtonTextAfterSelection,
            onClick: postAnswer,
          }

    return (
      <button
        className={`${baseButtonClass} ${buttonConfig.className}`}
        onClick={buttonConfig.onClick}
      >
        {buttonConfig.text}
      </button>
    )
  }

  const ModalBackgroundImage = () => {
    return (
      <img
        src={adConfig.modalBackgroundImageUrl}
        alt='abc'
        className='w-[90vw]'
      />
    )
  }

  const QuizSection = () => {
    return (
      <div className='absolute left-[50%] top-[37%] w-[80%] -translate-x-1/2 -translate-y-1'>
        {/* 퀴즈 섹션 */}
        <div className='max-h-[26vw] text-black text-[4.5vw] overflow-y-scroll leading-[140%] rounded-[2vw]'>
          {quizData.quizQuestion}
        </div>
        {/* 선택지 섹션 */}
        <div className='flex flex-col mt-[5vw]'>
          <OptionButton optionNum={0} />
          <OptionButton optionNum={1} />
          <OptionButton optionNum={2} />
        </div>
      </div>
    )
  }

  return (
    <div className='fixed inset-0 z-[5004] flex items-center justify-center bg-black/80'>
      <div className='w-[90vw] relative'>
        <CloseButton />
        <ModalBackgroundImage />
        <QuizSection />
        <AnswerButton />
      </div>
    </div>
  )
}

export default QuizAdReadyModal
