import clsx from 'clsx'
import { observer } from 'mobx-react-lite'
import { useRef, useState } from 'react'
import BottomSheetStore from 'store/BottomSheetStore'
import ModalStore from 'store/ModalStore'
import UserStore from 'store/UserStore'
import AlfarmEventLogger from 'utils/alfarmEventLogger'

const VideoAdModalActionButton = observer(
  ({ adType, onClick, children, className, position = 'absolute' }) => {
    const throttleRef = useRef({})

    const [isButtonPressed, setIsButtonPressed] = useState(false)

    return (
      <button
        type='button'
        disabled={!UserStore.isRewardAdReady}
        className={clsx(
          'w-[60vw] h-[14vw] disabled:bg-[#B2B2B2] bg-[#F7C82A] rounded-[99px] text-black font-maplestory text-[4.5vw] text-center flex justify-center items-center',
          isButtonPressed && 'shadow-[inset_3px_3px_4px_rgba(79,19,0,0.15)]',
          position,
          className,
        )}
        onPointerDown={() => {
          setIsButtonPressed(true)
        }}
        onPointerCancel={() => {
          setIsButtonPressed(false)
        }}
        onPointerUp={() => {
          setIsButtonPressed(false)

          AlfarmEventLogger({
            throttleRef,
            locationType: ModalStore?.isModalOpen,
            locationName: 'rewardAdModalButton',
            eventType: 'click',
            collection: 'UserAlfarmClickLog',
          })

          BottomSheetStore.setIsbottomSheetOpen('basic')
          ModalStore.setIsModalOpen('basic')

          onClick?.()

          window.ReactNativeWebView?.postMessage(
            JSON.stringify({
              type: 'showRewardedAd',
              data: adType,
            }),
          )
        }}
      >
        {UserStore.isRewardAdReady ? children : '광고 준비 중'}
      </button>
    )
  },
)

export default VideoAdModalActionButton
