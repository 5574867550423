import { VIDEO_AD_TYPE } from 'utils/alfarmCPMConstant/videoAd'
import VideoAdModal from 'comps/molecules/VideoAdModal'
import { observer } from 'mobx-react-lite'
import { getCPMVideoModalPropsByType } from 'utils/alfarmCPMConstant/cpmAdConfig'
import { CPM_ENTERING_COMPONENTS } from 'utils/alfarmCPMConstant/cpmAdTypes'
import CPMAnimationWrapper from 'comps/atoms/CPMAnimationWrapper'
import useGetCPMAdStatusByType from 'queries/cpm/useGetCPMAdStatusByType'

const QuizVideoAdModal = observer(() => {
  const { VIDEO_MODAL_COMMON_PROPS, CORRECT_TYPE_AD_CONFIGS } =
    getCPMVideoModalPropsByType(CPM_ENTERING_COMPONENTS.QUIZ_AD)

  const { data: quizAdStatus } = useGetCPMAdStatusByType({
    type: CPM_ENTERING_COMPONENTS.QUIZ_AD,
  })

  if (!quizAdStatus) {
    return null
  }

  const { rewardAmount, rewardStatus = false } = quizAdStatus
  const correctConfig = CORRECT_TYPE_AD_CONFIGS[rewardStatus]

  const questReward = rewardAmount
    ? `${rewardAmount}g`
    : `${VIDEO_MODAL_COMMON_PROPS.textMaxRewardAmount}g`

  return (
    <CPMAnimationWrapper
      enteringComponent={CPM_ENTERING_COMPONENTS.QUIZ_AD}
      animationDuration={1500}
    >
      <VideoAdModal modalImageUrl={correctConfig?.modalImageUrl}>
        <VideoAdModal.Description
          description={`${correctConfig?.modalTitleText}\n영상을 보면 물 ${questReward}을 받아요`}
          className='mt-2 mb-8'
        />
        <VideoAdModal.RewardIcon url='/quiz/rewardImage.png' className='mb-8' />
        <VideoAdModal.ActionButton
          adType={VIDEO_AD_TYPE[CPM_ENTERING_COMPONENTS.QUIZ_AD]}
          position='relative'
        >
          {`영상 보고 물 ${questReward} 받기`}
        </VideoAdModal.ActionButton>
      </VideoAdModal>
    </CPMAnimationWrapper>
  )
})

export default QuizVideoAdModal
