import MissionList from './MissionList'
import backendApis from 'utils/backendApis'
import { useEffect, useState } from 'react'
import RewardRecordButton from './RewardRecordButton'
import ModalStore from 'store/ModalStore'
import AlfarmEventLogger from 'utils/alfarmEventLogger'
import { observer } from 'mobx-react-lite'
import BadahamInfoModal from './BadahamInfoModal'
import RewardAdModal from 'comps/atoms/RewardAdModal'
import PearlReceivedModal from './PearlReceivedModal'
import UserStore from 'store/UserStore'
import CoinMarketSection from './CoinMarketSection'
import BadahamCoinHistoryModal from './BadahamCoinHistoryModal'
import { AdBannerSection } from '@wetripod/alwayz-frontend-shared'
import AB from 'utils/ab_v3'
import ABStore from 'store/ABStore'

const Title = () => {
  return (
    <div
      className='flex flex-col justify-center items-center'
      onClick={() => {
        ModalStore.setIsModalOpen('badahamInfoModal')
      }}
    >
      <img
        src='/ads/badahamMission/badahamTitle.png'
        alt='badaham'
        className='w-[60vw]'
      />
      <span className='text-[#4EB8CA] mt-[1vh]'>
        미션을 완료한 만큼 보상을 받을 수 있어요!
      </span>
    </div>
  )
}

const NoteSection = () => {
  return (
    <div className='px-[16px] w-[80vw] '>
      <div className='flex flex-col gap-[8px] text-[14px] leading-[20px]'>
        <span className='font-bold text-[16px] text-[#74BAC6]'>유의사항</span>
        <div className='flex flex-col gap-[4px] text-[#74BAC6]'>
          · 바다햄 미션은 매일 자정 초기화되며,
          <br />
          &nbsp;&nbsp;&nbsp;(진주 개수는 유지)
          <br />
          · 출석하기 미션은 일 1회씩만 가능해요.
          <br />
          · 진주로 교환한 물과 비료는 환불할 수 없어요
          <br />
        </div>
      </div>
    </div>
  )
}

const BadahamScreenV2 = observer(() => {
  const [userCodePushVersion] = useState(
    window.localStorage.getItem('userCodePushVersionNew'),
  )
  const [dailyBadahamData, setDailyBadahamData] = useState(null)
  const [badahamConfig, setBadahamConfig] = useState(null)
  const getBadahamConfig = async () => {
    const res = await backendApis.getDailyEventQuestConfig({
      event: 'badahamV2',
    })
    if (res?.status === 200) {
      setBadahamConfig(res?.data)
    }
  }

  useEffect(() => {
    getBadahamConfig()
    window.ReactNativeWebView?.postMessage(
      JSON.stringify({
        type: 'prepareRewardedAd',
        data: {
          adType: 'rewarded',
          placement: 'ALFARM_BADAHAM_V2_MISSION_REWARD',
        },
      }),
    )
  }, [])

  const [badahamV2CoinCount, setBadahamV2CoinCount] = useState(0)
  const refetchUserBadahamV2Coin = async () => {
    const result = await backendApis.getWaterRepoData({
      project: {
        badahamV2Coin: 1,
      },
    })
    setBadahamV2CoinCount(result?.data?.badahamV2Coin || 0)
  }

  useEffect(() => {
    refetchDailyBadahamData()
    refetchUserBadahamV2Coin()
  }, [])

  const refetchDailyBadahamData = async () => {
    const res = await backendApis.getDailyEventQuestData({ event: 'badahamV2' })
    setDailyBadahamData(res?.data)
  }
  useEffect(() => {
    const handleVisibilityChange = () => {
      if (document.visibilityState === 'visible') {
        refetchDailyBadahamData()
        refetchUserBadahamV2Coin()
      }
    }

    document.addEventListener('visibilitychange', handleVisibilityChange)

    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange)
    }
  }, [UserStore.isWatchingAd])

  // 페이지 뷰 로그
  useEffect(() => {
    AlfarmEventLogger({
      locationType: 'page',
      locationName: 'BadahamScreenV2',
      eventType: 'pageview',
      data: {},
      collection: 'UserAlfarmPageviewLog',
    })
  }, [])

  return (
    <div>
      <div className='bg-[#DEFAFF] w-full h-screen overflow-y-auto'>
        <RewardRecordButton modalType={'badahamRecordModal'} />
        <Title />
        <div className='mb-[8px]' />
        <div className='bg-[#C2F0F8]'>
          <div className='mb-[4vw] py-[20px] flex flex-col items-center justify-start gap-[12px]  overflow-hidden'>
            <div className='flex flex-col items-center w-full'>
              <CoinMarketSection
                coinExchangeData={badahamConfig?.coinExchange}
                coinCount={badahamV2CoinCount}
                refetchCoinCount={refetchUserBadahamV2Coin}
              />
              <div className='h-[10px]' />
            </div>
            <div className='w-full px-[16px] flex flex-col gap-3'>
              {ABStore.badaham1107 && (
                <AdBannerSection
                  product='ALFARM'
                  placement='BADAHAM_BANNER'
                  platform={
                    UserStore.offerwallInfo.platform === 'A' ? 'android' : 'ios'
                  }
                  targetpickTargetId='alfarm-badaham-targetpick-ad-container'
                  namAdUnitId={`Alwayz_Alfarm_Sea_WebNative_${
                    UserStore.offerwallInfo.platform === 'A' ? 'Android' : 'iOS'
                  }-N256497692`}
                  namAdSlotElementId='alfarm-badaham-nam-ad-container'
                  appVersion={userCodePushVersion}
                  ifa={UserStore.offerwallInfo.ifa}
                  ifv={UserStore.offerwallInfo.ifv}
                  enableInternal
                  enableBuzzvil={false}
                  enableNaver
                  enableTargetpick
                  adCount={1}
                />
              )}
              <MissionList
                dailyBadahamData={dailyBadahamData}
                refetchDailyBadahamData={refetchDailyBadahamData}
                refetchCoinCount={refetchUserBadahamV2Coin}
                badahamConfig={badahamConfig}
              />
            </div>
          </div>
        </div>
        <div className='h-[33px]' />
        <NoteSection />
        <div className='h-[50px]' />
        {ModalStore?.isModalOpen === 'badahamInfoModal' && <BadahamInfoModal />}
        {ModalStore?.isModalOpen === 'rewardAdModalWater' && <RewardAdModal />}
        {ModalStore?.isModalOpen === 'pearlReceivedModal' && (
          <PearlReceivedModal />
        )}
        {ModalStore?.isModalOpen === 'badahamCoinHistoryModal' && (
          <BadahamCoinHistoryModal />
        )}
      </div>
    </div>
  )
})

export default BadahamScreenV2
