import { VIDEO_AD_TYPE } from 'utils/alfarmCPMConstant/videoAd'
import VideoAdModal from 'comps/molecules/VideoAdModal'
import { observer } from 'mobx-react-lite'
import { CPM_ENTERING_COMPONENTS } from 'utils/alfarmCPMConstant/cpmAdTypes'
import CPMAnimationWrapper from 'comps/atoms/CPMAnimationWrapper'
import useGetCPMAdStatusByType from 'queries/cpm/useGetCPMAdStatusByType'

const YutnoriVideoAdModal = observer(() => {
  const yutImages = {
    도: '/minigame/yutnori/yutResultDo.png',
    개: '/minigame/yutnori/yutResultGae.png',
    걸: '/minigame/yutnori/yutResultGeol.png',
    윷: '/minigame/yutnori/yutResultYut.png',
    모: '/minigame/yutnori/yutResultMo.png',
  }

  const { data: yutnoriAdStatus } = useGetCPMAdStatusByType({
    type: CPM_ENTERING_COMPONENTS.YUTNORI_AD,
  })

  if (!yutnoriAdStatus) {
    return null
  }

  return (
    <CPMAnimationWrapper
      enteringComponent={CPM_ENTERING_COMPONENTS.YUTNORI_AD}
      animationDuration={1500}
    >
      <VideoAdModal
        modalImageUrl='/minigame/yutnori/yutnoriFieldBackground.png'
        style={{
          width: '80vw',
        }}
      >
        <VideoAdModal.Title titleUrl={'/minigame/yutnori/yutnoriTitle.png'} />
        <div
          className={`absolute w-[90vw] top-[14vw] bg-white/90 px-8 py-4 rounded-2xl shadow-[inset_0_-4px_0_0_rgba(151,176,195,0.34)] ${
            !yutnoriAdStatus.rewardStatus ? 'invisible' : 'visible'
          }`}
        >
          <VideoAdModal.Description
            description={`'${yutnoriAdStatus?.rewardStatus}' 나왔어요!\n영상을 보면 물 ${yutnoriAdStatus?.rewardAmount}g을 드려요!`}
            style={{
              color: '#7C5C35',
              fontSize: '5vw',
            }}
          />
        </div>
        {yutnoriAdStatus?.rewardStatus && (
          <img
            className='absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 w-[30vw]'
            src={yutImages[yutnoriAdStatus.rewardStatus]}
            alt={yutnoriAdStatus.rewardStatus}
          />
        )}
        <VideoAdModal.ActionButton
          adType={VIDEO_AD_TYPE[CPM_ENTERING_COMPONENTS.YUTNORI_AD]}
          className='bottom-[9vw]'
        >
          영상 보고 물 받기
        </VideoAdModal.ActionButton>
      </VideoAdModal>
    </CPMAnimationWrapper>
  )
})

export default YutnoriVideoAdModal
