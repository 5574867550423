import backendApis from 'utils/backendApis'
import { BADAHAM_V2_COIN_IMAGE_URL } from '../_constatns/badahamAssets'
import BadahamRewardModal from '../BadahamRewardModal'
import { useState } from 'react'

const RewardCard = ({
  price,
  rewardInfo,
  coinCount,
  refetchCoinCount,
  isLoading,
  setIsLoading,
}) => {
  const [exchangedReward, setExchangedReward] = useState(null)
  const [isModalOpen, setIsModalOpen] = useState(false)

  const getRewardInfoForDisplay = (rewardInfo) => {
    if (rewardInfo.water)
      return { name: '물', amount: `15~40g`, image: '/icon/water.png' }
    if (rewardInfo.fertilizer)
      return {
        name: '일반비료',
        amount: `${rewardInfo.fertilizer}개`,
        image: '/icon/fertilizer.png',
      }
    if (rewardInfo.fertilizerSet)
      return {
        name: '고급비료',
        amount: `${rewardInfo.fertilizerSet}개`,
        image: '/icon/fertilizerSet.png',
      }
  }

  const isButtonEnabled = coinCount >= price

  const handleClickExchangeButton = async () => {
    if (!isButtonEnabled || isLoading) return

    try {
      setIsLoading(true)
      const res = await backendApis.exchangeCoinForDailyEventQuest({
        event: 'badahamV2',
        exchangeAmount: price,
      })
      if (res.status === 200) {
        const rewardInfo = res?.data?.rewardInfo
        setExchangedReward(rewardInfo)
        setIsModalOpen(true)
        await refetchCoinCount()
      }
    } catch (error) {
      console.error('교환 중 오류 발생:', error)
    } finally {
      setIsLoading(false)
    }
  }

  const reward = getRewardInfoForDisplay(rewardInfo)
  return (
    <div className='rounded-[8px] border-[1px] border-[#26C0DB] overflow-hidden'>
      <div className='bg-[#26C0DB] py-[6px] px-[8px] text-white font-bold flex items-center'>
        <div className='flex items-center justify-center w-full gap-[4px]'>
          <span className='text-[17px] font-bold'>
            {reward.name} {reward.amount}
          </span>
        </div>
      </div>
      <div className='flex flex-col items-center bg-[#fff]'>
        <div className='h-[16px]' />
        <img
          src={reward.image}
          alt={reward.name}
          className='w-[48px] h-[48px]'
        />
        <div className='h-[16px]' />
        <button
          className='relative w-[148px] h-[41px] cursor-pointer disabled:cursor-not-allowed'
          onClick={handleClickExchangeButton}
          disabled={!isButtonEnabled || isLoading}
        >
          <img
            src={
              isButtonEnabled && !isLoading
                ? 'https://assets.ilevit.com/397f8583-3219-44dc-8a77-13e394805c12.png'
                : 'https://assets.ilevit.com/796dd8fd-08e3-4d66-8729-7fabf9ac927e.png'
            }
            alt='button background'
            className='w-full h-full'
          />
          <div className='absolute inset-0 flex items-center justify-center'>
            <div className='flex items-center justify-center w-full gap-[4px]'>
              <img
                src={BADAHAM_V2_COIN_IMAGE_URL}
                alt='진주'
                className='w-[20px] h-[20px]'
              />
              <span
                className={`text-[17px] font-bold ${
                  isButtonEnabled && !isLoading ? 'text-[#0098B4]' : ''
                }`}
              >
                {isLoading ? '교환중...' : `${price}개`}
              </span>
            </div>
          </div>
        </button>
        <div className='h-[12px]' />
      </div>
      {isModalOpen && (
        <BadahamRewardModal
          rewardInfo={exchangedReward}
          onClose={() => setIsModalOpen(false)}
        />
      )}
    </div>
  )
}

export default RewardCard
