import React from 'react'

const sprite = {
  goBack: {
    images: ['/icon/goBack.png'],
  },
  timerBase: {
    images: ['/icon/timerBase.png'],
  },
  questApp: {
    images: ['/icon/quest.png'],
  },
  questWeb: {
    images: ['/icon/EngageReward.png'],
  },
  attendanceWater: {
    images: ['/icon/attendanceWater.png'],
  },
  canGetAttendanceWaterIcon: {
    images: ['/icon/canGetAttendanceWaterIcon.png'],
  },
  getWaterButton: {
    images: ['/icon/getWaterButton.png'],
  },
  getFertButton: {
    images: ['/icon/getFertButton.png'],
  },
  canGetFertButton: {
    images: ['/icon/canGetFertButton.png'],
  },
  canGetWaterButton: {
    images: ['/icon/canGetWaterButton.png'],
  },
  reviewButton: {
    images: ['/icon/reviewButton.png'],
  },
  inviteQuest: {
    images: ['/icon/inviteQuestIcon.png'],
  },
  backToFarm: {
    images: ['/icon/backToFarmNew.png'],
  },
  getWaterFromGganbu: {
    images: ['/icon/getWaterFromGganbuNew.png'],
  },
  alreadyGetWaterFromGganbu: {
    images: ['/icon/alreadyGetWaterFromGganbuNew.png'],
  },
  bonusReward: {
    images: ['/icon/bonusReward.png'],
  },
  message: {
    images: ['/icon/gganbuMessageNew.png'],
  },
  purchaseForGganbu: {
    images: ['/icon/purchaseForGganbuIconNew.png'],
  },
  bokForGganbuModal: {
    images: ['/icon/bokForGganbuIcon.png'],
  },
  badgeIcon: {
    images: ['/icon/badgeIcon.png'],
  },
  fertBoxIcon: {
    images: ['/icon/fertBoxIconNew.png'],
  },
  gganbuBoxIcon: {
    images: ['/icon/gganbuBoxIcon.png'],
  },
  pokeFriend: {
    images: ['/icon/pokeFriendNew.png'],
  },
  gift: {
    images: ['/gganbuGift/giftIconNew.png'],
  },
  alreadyGetWaterFromSellerFarm: {
    images: ['/icon/alreadyGetWaterFromSellerFarm.png'],
  },
  gganbuFarmBrandDeal: {
    images: ['/icon/gganbuFarmBrandDealEntry.png'],
  },
  gganbuFarmStamp: {
    images: ['/icon/icn_gganbuFarm_stamp.png'],
  },
}

const GganbuFarmQuickButton = ({
  action = 'goBack',
  onClick,
  className = '',
  style,
}) => {
  if (!(action in sprite)) return null
  return (
    <button
      onClick={onClick}
      className={`bt-gganbu-quick ${className}`}
      style={style}
    >
      <img src={sprite[action].images} alt='' />
    </button>
  )
}

export default GganbuFarmQuickButton
