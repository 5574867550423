import backendApis from 'utils/backendApis'
import {
  checkIsTodayWithISODate,
  createDefaultCPMAdDocument,
} from 'utils/utils'
import { CPM_VIEWER_TYPES } from 'utils/alfarmCPMConstant/cpmAdTypes'
import { getCPMButtonPropsByType } from 'utils/alfarmCPMConstant/cpmAdConfig'
import UserStore from 'store/UserStore'

export const checkIntervalTime = ({ userAdStatus, intervalHours }) => {
  if (!userAdStatus)
    return {
      isIntervalTimeReady: false,
      remainedIntervalTime: intervalHours * 60 * 60 * 1000,
    }

  const lastClearedTime = new Date(userAdStatus.clearedAt)
  const timeDifference = new Date().getTime() - lastClearedTime.getTime()
  const isIntervalTimeReady = timeDifference >= intervalHours * 60 * 60 * 1000
  const remainedIntervalTime =
    lastClearedTime.getTime() + intervalHours * 60 * 60 * 1000 - Date.now()

  return { isIntervalTimeReady, remainedIntervalTime }
}

export const determineAdAvailability = ({
  hasRemainingViewCounts,
  remainedViewCount,
  isIntervalTimeReady,
  remainedIntervalTime,
  modalPath,
  hasAdStock,
}) => {
  const BUTTON_STATES = {
    PREPARING: '준비 중',
    COMPLETED: '완료',
    SOLD_OUT: '광고 마감',
    WAITING: '준비 중',
    AVAILABLE: '구경하기',
  }

  const remainingHours = Math.floor(remainedIntervalTime / (60 * 60 * 1000))
  const remainingMinutes = Math.floor(
    (remainedIntervalTime % (60 * 60 * 1000)) / (60 * 1000),
  )

  if (
    hasRemainingViewCounts == null ||
    hasAdStock == null ||
    isIntervalTimeReady == null ||
    modalPath == null
  ) {
    return {
      buttonText: BUTTON_STATES.PREPARING,
      isTimerOn: false,
      isAdAvailable: false,
    }
  }

  if (hasRemainingViewCounts === false) {
    return {
      buttonText: BUTTON_STATES.COMPLETED,
      isTimerOn: false,
      isAdAvailable: false,
    }
  }

  if (hasAdStock === false) {
    return {
      buttonText: BUTTON_STATES.SOLD_OUT,
      isTimerOn: false,
      isAdAvailable: false,
    }
  }

  if (isIntervalTimeReady === false) {
    return {
      buttonText:
        `${
          remainingHours > 0
            ? `${remainingHours}시간 남음`
            : `${remainingMinutes}분 남음`
        }` || BUTTON_STATES.WAITING,
      isTimerOn: true,
      isAdAvailable: false,
    }
  }

  if (
    isIntervalTimeReady === true &&
    hasAdStock === true &&
    hasRemainingViewCounts === true
  ) {
    return {
      buttonText: remainedViewCount
        ? `${remainedViewCount}번 남음`
        : BUTTON_STATES.AVAILABLE,
      isTimerOn: false,
      isAdAvailable: true,
    }
  }
}

const getCPMBottomEventIconButtonProps = async ({ enteringComponent }) => {
  const BUTTON_PROPS = getCPMButtonPropsByType(enteringComponent)

  if (!BUTTON_PROPS) return

  const {
    codePushVersionLimit,
    viewerType,
    maxViewCount,
    intervalHours,
    readyModal,
    navigationModal,
  } = BUTTON_PROPS

  const checkCanShowAd = () => {
    if (
      viewerType === CPM_VIEWER_TYPES.NONE ||
      !enteringComponent ||
      !BUTTON_PROPS ||
      !navigationModal ||
      UserStore.codePushVersionFromAlwayzApp < codePushVersionLimit // 버전 제한 + 경험치/뉴유저/물양 제한
    ) {
      return { isAdDisplayable: false }
    }
    return { isAdDisplayable: true }
  }

  const setupAdConditions = async () => {
    const userAdStatus = await getUserAdStatus()

    const { hasRemainingViewCounts, remainedViewCount } =
      checkViewCounts(userAdStatus)
    const { isIntervalTimeReady, remainedIntervalTime } = checkIntervalTime({
      userAdStatus,
      intervalHours,
    })
    const { modalPath } = checkModalPath(userAdStatus)
    const { hasAdStock } = await checkAdStock()

    return {
      hasRemainingViewCounts,
      remainedViewCount,
      isIntervalTimeReady,
      remainedIntervalTime,
      modalPath,
      hasAdStock,
    }
  }

  const getUserAdStatus = async () => {
    try {
      const result = await backendApis.getCPMAdStatusByType({
        type: enteringComponent,
      })
      if (result?.status === 200) {
        return result?.data
      } else if (result?.status === 404) {
        const defaultAdStatus = await createDefaultCPMAdDocument({
          type: enteringComponent,
        })
        return defaultAdStatus
      }
      return null
    } catch (error) {
      console.error('Failed to get ad status:', error)
      return null
    }
  }

  const checkViewCounts = (userAdStatus) => {
    if (!userAdStatus)
      return { hasRemainingViewCounts: false, remainedViewCount: 0 }

    const todayClearedCount = checkIsTodayWithISODate(userAdStatus.clearedAt)
      ? userAdStatus.clearedCount
      : 0
    const hasRemainingViewCounts = todayClearedCount < maxViewCount
    const remainedViewCount = maxViewCount - todayClearedCount || 0
    return { hasRemainingViewCounts, remainedViewCount }
  }

  const checkModalPath = (userAdStatus) => {
    if (!userAdStatus) return { modalPath: readyModal || navigationModal }

    const shouldShowReadyModal =
      readyModal &&
      (!userAdStatus.rewardDeterminedAt ||
        !checkIsTodayWithISODate(userAdStatus.rewardDeterminedAt) ||
        userAdStatus.rewardDeterminedAt <= userAdStatus.clearedAt)

    return {
      modalPath: shouldShowReadyModal ? readyModal : navigationModal,
    }
  }

  const checkAdStock = async () => {
    try {
      if (viewerType === CPM_VIEWER_TYPES.GOODS) {
        const result = await backendApis.checkCPMAdSetExist()
        if (result?.status === 200) {
          return { hasAdStock: result?.data }
        }
      }
      if (viewerType === CPM_VIEWER_TYPES.VIDEO) {
        // video viewer는 cpm 재고 검사 하지 않음
        return { hasAdStock: true }
      }
      return { hasAdStock: false }
    } catch (error) {
      console.error('Failed to check CPM ad stock:', error)
      return { hasAdStock: false }
    }
  }

  const { isAdDisplayable } = checkCanShowAd()
  const {
    hasRemainingViewCounts,
    remainedViewCount,
    isIntervalTimeReady,
    remainedIntervalTime,
    modalPath,
    hasAdStock,
  } = await setupAdConditions()
  const { buttonText, isTimerOn, isAdAvailable } = determineAdAvailability({
    hasRemainingViewCounts,
    remainedViewCount,
    isIntervalTimeReady,
    remainedIntervalTime,
    modalPath,
    hasAdStock,
  })

  return {
    ...BUTTON_PROPS,
    isAdDisplayable, // 광고 보여지는 지 boolean
    hasRemainingViewCounts, // 오늘 더 볼 수 있는지 boolean
    remainedViewCount, // 오늘 더 볼 수 있는 횟수
    isIntervalTimeReady, // 광고 쿨타임 지났는지 boolean
    remainedIntervalTime, // 광고 쿨타임 남은 시간
    modalPath, // 모달 경로
    hasAdStock, // 광고 재고 남았는지 boolean
    buttonText, // 버튼 텍스트
    isTimerOn, // 타이머 켜져있는지 boolean
    isAdAvailable, // 광고 볼 수 있는지 boolean
  }
}

export default getCPMBottomEventIconButtonProps
