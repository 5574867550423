import { NavLink } from 'react-router-dom'
import AuthStore from 'store/AuthStore'
import ModalStore from 'store/ModalStore'
import UserStore from 'store/UserStore'

const RewardRecordButton = ({
  modalType = '', // 보상기록 버튼 클릭시 오픈할 모달 타입
}) => {
  return (
    <header
      style={{
        width: '100%',
        marginTop: '13vw',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        zIndex: 999,
        paddingLeft: '4vw',
        paddingRight: '4vw',
      }}
    >
      <NavLink
        to={`/farmPage?token=${AuthStore?.token}&itemType=${
          UserStore?.itemType
        }&randomNumber=${Math.random().toString().slice(0, 8)}`}
      >
        <button
          style={{
            width: '8vw',
            zIndex: 999,
          }}
          action='goBack'
        >
          <img src='/backButton/black.png' alt='back button' />
        </button>
      </NavLink>
      <div
        className='flex flex-col items-center cursor-pointer'
        onClick={() => {
          ModalStore.setIsModalOpen('badahamCoinHistoryModal')
        }}
      >
        <svg
          width='24'
          height='24'
          viewBox='0 0 24 24'
          fill='none'
          xmlns='http://www.w3.org/2000/svg'
        >
          <circle cx='12' cy='12' r='11' fill='white' />
          <circle cx='12' cy='12' r='11' stroke='#74BAC6' strokeWidth='2' />
          <rect x='7' y='8' width='10' height='2' rx='1' fill='#74BAC6' />
          <rect x='7' y='11' width='10' height='2' rx='1' fill='#74BAC6' />
          <rect x='7' y='14' width='10' height='2' rx='1' fill='#74BAC6' />
        </svg>
        <span className='text-[14px] text-[#4EB8CA] text-center font-bold leading-[163.347%]'>
          기록
        </span>
      </div>
    </header>
  )
}

export default RewardRecordButton
