import CPMBottomSheetButton from './CPMBottomSheetButton'
import { getCPMButtonPropsByType } from 'utils/alfarmCPMConstant/cpmAdConfig'
import { CPM_ENTERING_COMPONENTS } from 'utils/alfarmCPMConstant/cpmAdTypes'

const RecommendedProductAdButton = ({ closeBottomSheet }) => {
  const ENTERING_COMPONENT = CPM_ENTERING_COMPONENTS.RECOMMENDED_PRODUCT_AD
  const BUTTON_PROPS = getCPMButtonPropsByType(ENTERING_COMPONENT)

  return (
    <CPMBottomSheetButton
      ENTERING_COMPONENT={ENTERING_COMPONENT}
      BUTTON_PROPS={BUTTON_PROPS}
      closeBottomSheet={closeBottomSheet}
    />
  )
}

export default RecommendedProductAdButton
