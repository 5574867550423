import { VIDEO_AD_TYPE } from 'utils/alfarmCPMConstant/videoAd'
import VideoAdModal from 'comps/molecules/VideoAdModal'
import { observer } from 'mobx-react-lite'
import { CPM_ENTERING_COMPONENTS } from 'utils/alfarmCPMConstant/cpmAdTypes'
import useGetCPMAdStatusByType from 'queries/cpm/useGetCPMAdStatusByType'
import CPMAnimationWrapper from 'comps/atoms/CPMAnimationWrapper'

const FortuneCookieVideoAdModal = observer(() => {
  const { data: fortuneCookieAdStatus } = useGetCPMAdStatusByType({
    type: CPM_ENTERING_COMPONENTS.FORTUNE_COOKIE_AD,
  })

  const rewardAmount = `${fortuneCookieAdStatus?.rewardAmount || 20}g`

  return (
    <CPMAnimationWrapper
      enteringComponent={CPM_ENTERING_COMPONENTS.FORTUNE_COOKIE_AD}
      animationDuration={1500}
    >
      <VideoAdModal
        modalImageUrl='https://assets.ilevit.com/ed38b982-2d8d-44b0-b175-1ad613d2bbb7.png'
        style={{
          width: '80vw',
        }}
      >
        <VideoAdModal.Description
          description={`영상을 보면\n 물 ${rewardAmount}을 받아요`}
          className='mt-[6.4vw] ml-3'
        />
        <VideoAdModal.ActionButton
          adType={VIDEO_AD_TYPE[CPM_ENTERING_COMPONENTS.FORTUNE_COOKIE_AD]}
          className='bottom-[9vw]'
        >
          {`영상 보고 물 ${rewardAmount} 받기`}
        </VideoAdModal.ActionButton>
      </VideoAdModal>
    </CPMAnimationWrapper>
  )
})

export default FortuneCookieVideoAdModal
