import { CPM_ENTERING_COMPONENTS } from './cpmAdTypes'
import * as recommendedProductAdConfig from './recommendedProductAdConfig'
import * as todayDealAdConfig from './todayDealAdConfig'
import * as yutnoriAdConfig from './yutnoriAdConfig'
import * as leavesAdConfig from './leavesAdConfig'
import * as fortuneCookieAdConfig from './fortuneCookieAdConfig'
import * as quizAdConfig from './quizAdConfig'

import UserStore from '../../store/UserStore'

// 버튼 프롭스
const BUTTON_PROPS = {
  [CPM_ENTERING_COMPONENTS.RECOMMENDED_PRODUCT_AD]:
    recommendedProductAdConfig.getButtonProps,
  [CPM_ENTERING_COMPONENTS.TODAY_DEAL_AD]: todayDealAdConfig.getButtonProps,
  [CPM_ENTERING_COMPONENTS.YUTNORI_AD]: yutnoriAdConfig.getButtonProps,
  [CPM_ENTERING_COMPONENTS.LEAVES_AD]: leavesAdConfig.getButtonProps,
  [CPM_ENTERING_COMPONENTS.FORTUNE_COOKIE_AD]:
    fortuneCookieAdConfig.getButtonProps,
  [CPM_ENTERING_COMPONENTS.QUIZ_AD]: quizAdConfig.getButtonProps,
}

// 레디 모달(보상 결정 모달) =>  3개 밖에 없음
const READY_MODAL_PROPS = {
  [CPM_ENTERING_COMPONENTS.YUTNORI_AD]: yutnoriAdConfig.getReadyModalProps,
  [CPM_ENTERING_COMPONENTS.FORTUNE_COOKIE_AD]:
    fortuneCookieAdConfig.getReadyModalProps,
  [CPM_ENTERING_COMPONENTS.QUIZ_AD]: quizAdConfig.getReadyModalProps,
}

// 상품 광고 모달
const GOODS_MODAL_PROPS = {
  [CPM_ENTERING_COMPONENTS.RECOMMENDED_PRODUCT_AD]:
    recommendedProductAdConfig.getGoodsModalProps,
  [CPM_ENTERING_COMPONENTS.TODAY_DEAL_AD]: todayDealAdConfig.getGoodsModalProps,
  [CPM_ENTERING_COMPONENTS.YUTNORI_AD]: yutnoriAdConfig.getGoodsModalProps,
  [CPM_ENTERING_COMPONENTS.LEAVES_AD]: leavesAdConfig.getGoodsModalProps,
  [CPM_ENTERING_COMPONENTS.FORTUNE_COOKIE_AD]:
    fortuneCookieAdConfig.getGoodsModalProps,
  [CPM_ENTERING_COMPONENTS.QUIZ_AD]: quizAdConfig.getGoodsModalProps,
}

// 영상 광고 모달
const VIDEO_MODAL_PROPS = {
  [CPM_ENTERING_COMPONENTS.RECOMMENDED_PRODUCT_AD]:
    recommendedProductAdConfig.getVideoModalProps,
  [CPM_ENTERING_COMPONENTS.TODAY_DEAL_AD]: todayDealAdConfig.getVideoModalProps,
  [CPM_ENTERING_COMPONENTS.YUTNORI_AD]: yutnoriAdConfig.getVideoModalProps,
  [CPM_ENTERING_COMPONENTS.LEAVES_AD]: leavesAdConfig.getVideoModalProps,
  [CPM_ENTERING_COMPONENTS.FORTUNE_COOKIE_AD]:
    fortuneCookieAdConfig.getVideoModalProps,
  [CPM_ENTERING_COMPONENTS.QUIZ_AD]: quizAdConfig.getVideoModalProps,
}

const getCPMButtonPropsByType = (type) => {
  if (!type) return
  return BUTTON_PROPS[type](UserStore.purchaseFrequency)
}

const getCPMReadyModalPropsByType = (type) => {
  if (!type) return
  return READY_MODAL_PROPS[type](UserStore.purchaseFrequency)
}

const getCPMGoodsModalPropsByType = (type) => {
  if (!type) return
  return GOODS_MODAL_PROPS[type](UserStore.purchaseFrequency)
}

const getCPMVideoModalPropsByType = (type) => {
  if (!type) return
  return VIDEO_MODAL_PROPS[type](UserStore.purchaseFrequency)
}

export {
  getCPMButtonPropsByType,
  getCPMReadyModalPropsByType,
  getCPMGoodsModalPropsByType,
  getCPMVideoModalPropsByType,
}
