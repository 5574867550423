import {
  VIDEO_AD_MODAL_TYPE,
  VIDEO_AD_TYPE,
} from 'utils/alfarmCPMConstant/videoAd'
import VideoAdModal from 'comps/molecules/VideoAdModal'
import { observer } from 'mobx-react-lite'
import { getCPMVideoModalPropsByType } from 'utils/alfarmCPMConstant/cpmAdConfig'
import { CPM_ENTERING_COMPONENTS } from 'utils/alfarmCPMConstant/cpmAdTypes'
import CPMAnimationWrapper from 'comps/atoms/CPMAnimationWrapper'

const CPMVideoAdModal = observer(({ modalType }) => {
  const ENTERING_COMPONENT = {
    [VIDEO_AD_MODAL_TYPE.todayDealAd_video]:
      CPM_ENTERING_COMPONENTS.TODAY_DEAL_AD,
    [VIDEO_AD_MODAL_TYPE.recommendedProductAd_video]:
      CPM_ENTERING_COMPONENTS.RECOMMENDED_PRODUCT_AD,
    [VIDEO_AD_MODAL_TYPE.fortuneCookieAd_video]:
      CPM_ENTERING_COMPONENTS.FORTUNE_COOKIE_AD,
  }

  const { CPM_PURCHASE_SCREEN_PROPS, VIDEO_MODAL_COMMON_PROPS } =
    getCPMVideoModalPropsByType(ENTERING_COMPONENT[modalType])

  const questReward = `${VIDEO_MODAL_COMMON_PROPS.textMaxRewardAmount}g`

  return (
    <CPMAnimationWrapper
      enteringComponent={ENTERING_COMPONENT[modalType]}
      animationDuration={1500}
    >
      <VideoAdModal>
        <VideoAdModal.Title
          title={CPM_PURCHASE_SCREEN_PROPS.buttonTitleText}
          questReward={questReward}
        />
        <VideoAdModal.ActionButton
          adType={VIDEO_AD_TYPE[ENTERING_COMPONENT[modalType]]}
          className='bottom-[9vw]'
        >
          영상 보고 물 받기
        </VideoAdModal.ActionButton>
      </VideoAdModal>
    </CPMAnimationWrapper>
  )
})

export default CPMVideoAdModal
