import React, { useState, useEffect, useCallback } from 'react'
import { observer } from 'mobx-react-lite'
import ModalStore from '../../../../store/ModalStore'
import { getCPMRecommendedItemsWithDefaults } from '../../../../utils/utils'
import sendToCPMPurchaseScreen from '../../../../utils/sendToCPMPurchaseScreen'
import CPMGoodsModalBodySection from '../../CPMGoodsModalBodySection'
import { getCPMGoodsModalPropsByType } from 'utils/alfarmCPMConstant/cpmAdConfig'
import { CPM_ENTERING_COMPONENTS } from 'utils/alfarmCPMConstant/cpmAdTypes'
import CPMAnimationWrapper from 'comps/atoms/CPMAnimationWrapper'

import './index.css'

const CPMGoodsModal = observer(({ enteringComponent }) => {
  const [isButtonPressed, setIsButtonPressed] = useState(false)
  const [cpmItems, setCpmItems] = useState([])
  const [itemIsLoading, setItemIsLoading] = useState(false)
  const [buttonText, setButtonText] = useState('')

  const [goodsModalCommonProps, setGoodsModalCommonProps] = useState(null)
  const [cpmPurchaseScreenProps, setCpmPurchaseScreenProps] = useState(null)

  const setModalProps = useCallback(async () => {
    const modalProps = await getCPMGoodsModalPropsByType(enteringComponent)

    setGoodsModalCommonProps(modalProps.GOODS_MODAL_COMMON_PROPS)
    setCpmPurchaseScreenProps(modalProps.CPM_PURCHASE_SCREEN_PROPS)
  }, [enteringComponent])

  const fetchCPMItems = useCallback(async () => {
    if (itemIsLoading) return
    setItemIsLoading(true)

    try {
      await setModalProps()
      const items = await getCPMRecommendedItemsWithDefaults()
      setCpmItems(items)

      if (items.length > 0) {
        const modalProps = await getCPMGoodsModalPropsByType(enteringComponent)
        setButtonText(
          modalProps.GOODS_MODAL_COMMON_PROPS?.FINAL_BUTTON_TEXT || '구경하기',
        )
      } else {
        setButtonText('오늘 상품이 마감됐어요')
      }
    } catch (error) {
      console.error('Failed to fetch CPM items:', error)
      setButtonText('오류가 발생했습니다')
    } finally {
      setItemIsLoading(false)
    }
  }, [enteringComponent, itemIsLoading])

  useEffect(() => {
    fetchCPMItems()
  }, [])

  useEffect(() => {
    if (itemIsLoading && goodsModalCommonProps?.FIRST_BUTTON_TEXT) {
      setButtonText(goodsModalCommonProps.FIRST_BUTTON_TEXT)
    }
  }, [itemIsLoading, goodsModalCommonProps])

  const BrowsingButton = () => {
    const isYutnoriAd = enteringComponent === CPM_ENTERING_COMPONENTS.YUTNORI_AD
    const buttonClasses = `w-[60vw] h-[14vw] rounded-[99px] text-black font-maplestory text-[4.5vw] flex justify-center items-center absolute ${
      isYutnoriAd ? 'bottom-[-22vw]' : 'bottom-[8vw]'
    } left-[10vw]
    ${itemIsLoading ? 'bg-[#B2B2B2] loading-dots' : 'bg-[#F7C82A]'}
    ${isButtonPressed ? 'shadow-[inset_3px_3px_4px_rgba(79,19,0,0.15)]' : ''}
    `

    return (
      <div
        className={buttonClasses}
        onPointerDown={() => {
          if (itemIsLoading) return
          setIsButtonPressed(true)
        }}
        onPointerCancel={() => {
          if (itemIsLoading) return
          setIsButtonPressed(false)
        }}
        onPointerUp={() => {
          if (itemIsLoading) return
          setIsButtonPressed(false)
          sendToCPMPurchaseScreen(cpmItems, cpmPurchaseScreenProps)
        }}
      >
        {buttonText}
      </div>
    )
  }

  const CloseButton = () => {
    const defaultPosition = { top: '5%', right: '5%' }
    const buttonPosition =
      goodsModalCommonProps?.CLOSE_BUTTON_POSITION || defaultPosition

    return (
      <button
        className='w-[10%] absolute z-[1113]'
        style={{
          top: buttonPosition.top,
          right: buttonPosition.right,
        }}
        onClick={() => {
          ModalStore.setIsModalOpen('basic')
        }}
      >
        <img
          className='absolute z-[101]'
          src='/icon/modalCloseButton.png'
          alt='close modal'
        />
      </button>
    )
  }

  const ModalBodySection = () => {
    const defaultModalProps = {
      MODAL_IMAGE_URL: '',
    }

    return (
      <CPMGoodsModalBodySection
        enteringComponent={enteringComponent}
        goodsModalCommonProps={goodsModalCommonProps || defaultModalProps}
      />
    )
  }

  return (
    <CPMAnimationWrapper
      enteringComponent={enteringComponent}
      animationDuration={goodsModalCommonProps?.ANIMATION_DURATION}
    >
      <div className='flex-1 fixed left-0 top-0 z-[5004] w-full h-screen bg-black/80'>
        {/* 닫기 버튼 */}
        <CloseButton />
        <div className='w-[80vw] top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 flex absolute justify-around items-center flex-col'>
          {/* 모달 이미지 */}
          <ModalBodySection />
          {/* 구경하기 버튼 */}
          <BrowsingButton />
        </div>
      </div>
    </CPMAnimationWrapper>
  )
})

export default CPMGoodsModal
