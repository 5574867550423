import { VIDEO_AD_TYPE } from 'utils/alfarmCPMConstant/videoAd'
import VideoAdModal from 'comps/molecules/VideoAdModal'
import { observer } from 'mobx-react-lite'
import { getCPMVideoModalPropsByType } from 'utils/alfarmCPMConstant/cpmAdConfig'
import { CPM_ENTERING_COMPONENTS } from 'utils/alfarmCPMConstant/cpmAdTypes'

const LeavesVideoAdModal = observer(() => {
  const { CPM_PURCHASE_SCREEN_PROPS, VIDEO_MODAL_COMMON_PROPS } =
    getCPMVideoModalPropsByType(CPM_ENTERING_COMPONENTS.LEAVES_AD)

  return (
    <VideoAdModal modalImageUrl='https://assets.ilevit.com/072d05d2-1c9c-4b72-a5e6-4e71cfce553f.png'>
      <VideoAdModal.Description
        description={`떨어진 낙엽을 주웠어요\n영상을 보면 양분을 최대 ${VIDEO_MODAL_COMMON_PROPS.textMaxRewardAmount} 받아요`}
        className='mt-2 mb-4'
      />
      <div className='absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 mt-[15vw] flex justify-center items-center'>
        <span className='text-[9.6vw] font-bold text-white text-center'>
          {VIDEO_MODAL_COMMON_PROPS.textMaxRewardAmount}
        </span>
      </div>
      <VideoAdModal.ActionButton
        adType={VIDEO_AD_TYPE[CPM_PURCHASE_SCREEN_PROPS.enteringComponent]}
        className='bottom-[12vw]'
      >
        영상 보고 양분 받기
      </VideoAdModal.ActionButton>
    </VideoAdModal>
  )
})

export default LeavesVideoAdModal
