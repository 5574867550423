import useInterval from 'hooks/useInterval'
import React, { useEffect, useState } from 'react'

const BrowsingTimerSection = ({
  isFinished,
  isScrolling,
  isScrollTimer,
  time,
  isIntervalTimer,
  rewardText,
  onTimeEnd,
}) => {
  const [questTimer, setQuestTimer] = useState(time)

  useInterval(() => {
    setQuestTimer((prev) => {
      if (prev === 0) return prev
      return prev - 1
    })
  }, 1000)

  useEffect(() => {
    if (isScrollTimer && isFinished) {
      onTimeEnd?.()
    }
  }, [isFinished, isScrollTimer])

  useEffect(() => {
    if (isIntervalTimer && questTimer === 0) {
      onTimeEnd?.()
    }
  }, [isIntervalTimer, questTimer])

  if (isScrollTimer && !isScrolling && !isFinished) {
    return (
      <div className='flex h-[43px] mx-3 bg-[#F6F6FA] rounded-[20px] items-center justify-center'>
        <span className='text-[#30333D] text-base font-medium'>
          스크롤을 시작하면 시간이 줄어요
        </span>
      </div>
    )
  }

  if (isFinished || questTimer === 0) {
    return (
      <div className='flex h-[43px] mx-3 bg-[#F6F6FA] rounded-[20px] items-center justify-center'>
        <span className='text-[#30333D] text-base font-medium'>
          축하합니다! 올팜에서 보상을 확인하세요!
        </span>
      </div>
    )
  }

  return (
    <div className='flex h-[43px] mx-3 bg-[#F6F6FA] rounded-[20px] items-center justify-center'>
      <span className='text-[#30333D] text-base font-medium'>퀘스트를 </span>
      <div className='w-[5px] h-[5px] ' />
      <span className='text-[#F8323E] text-2xl font-bold'>
        {isIntervalTimer ? questTimer : time}
      </span>
      <span className='text-[#30333D] text-base font-medium'>
        초간 구경하면 {rewardText}을 받아요
      </span>
    </div>
  )
}

export default BrowsingTimerSection
