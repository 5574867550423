import React, { useEffect, useState } from 'react'
import { observer } from 'mobx-react-lite'
import ModalStore from '../../../store/ModalStore'
import backendApis from 'utils/backendApis'
import { Oval as Loader } from 'react-loader-spinner'
import { sleep } from 'utils/utils'
import ABStore from 'store/ABStore'
import UserStore from 'store/UserStore'
import AlfarmAdStore from 'store/AlfarmAdStore'
import AlfarmEventLogger from 'utils/alfarmEventLogger'
import LoadingIndicator from '../../loadingIndicator'
import useCheckCodePushVersion from 'utils/customHooks/useConvertCodePushVersion'

const NutrimentLeaveModalV2 = observer(({ token, farmData, setFarmData }) => {
  const [isButtonPressed, setIsButtonPressed] = useState(false)
  const [mainAdData, setMainAdData] = useState(null)
  const [subAdData, setSubAdData] = useState(null)
  const [clearedAd, setClearedAd] = useState(true)
  const [isLoading, setIsLoading] = useState(true)
  const [isLoadingRewardedAd, setIsLoadingRewardedAd] = useState(false)
  const [isNoLeaves, setIsNoLeaves] = useState(false)
  const [modalImage, setModalImage] = useState(null)
  const codePushVersionAvailable = useCheckCodePushVersion()
  const codePushVersionForAdmob = codePushVersionAvailable(
    UserStore?.codePushVersionFromAlwayzApp,
    '6.1.5',
  )
  const nutrimentLeavesRewardedAdCheck =
    UserStore.cherryPickerForAd &&
    (!codePushVersionForAdmob ||
      (codePushVersionForAdmob && !ABStore?.admobAlfarmTester))

  useEffect(() => {
    if (nutrimentLeavesRewardedAdCheck) {
      window.ReactNativeWebView?.postMessage(
        JSON.stringify({
          type: 'prepareRewardedAd',
          data: {
            adType: 'rewarded',
            placement: 'ALFARM_NUTRIMENT_LEAVES_MISSION_REWARD',
          },
        }),
      )
    }
  }, [nutrimentLeavesRewardedAdCheck])

  let buttonText = '상품 구경하고 양분 받기'
  if (clearedAd) {
    buttonText = '상품 구매하기'
  } else if (
    nutrimentLeavesRewardedAdCheck &&
    !UserStore.isRewardAdReady &&
    isLoadingRewardedAd
  ) {
    buttonText = '광고 준비중..'
  } else if (nutrimentLeavesRewardedAdCheck && UserStore.isRewardAdReady) {
    buttonText = '광고 보고 양분 받기'
  }

  const mainAdItemIds = mainAdData?.itemIds || []
  const subAdItem1Ids = subAdData?.[0]?.itemIds || []
  const subAdItem2Ids = subAdData?.[1]?.itemIds || []

  const combinedItemIds = [...mainAdItemIds, ...subAdItem1Ids, ...subAdItem2Ids]
  const mainAdId = mainAdData?._id || null
  const subAdId1 = subAdData?.[0]?._id || null
  const subAdId2 = subAdData?.[1]?._id || null
  const subAdIds = [subAdId1, subAdId2].filter((e) => e)

  const sendToPurchaseScreen = () => {
    if (!clearedAd) {
      window.location.href = `#generalNavigator.${JSON.stringify({
        screen: 'AlfarmAdPurchaseScreen',
        params: {
          title: '올팜',
          image:
            'https://assets.ilevit.com/2cfa0fa4-f146-429f-a44c-5a5375f48bf5.png', // s3 이미지 가져오기 - 바꿔줘야함
          ratio: 99 / 360,
          enteringComponent: 'Alfarm_nutrimentLeaves',
          adId: mainAdId,
          subAdId: subAdIds,
          itemIds: combinedItemIds,
          questReward: 'nutriment',
          isTimerOn: !clearedAd,
          questRewardType: 'nutriment',
          questRewardText: `초간 구경하면 양분 최대 10을 받아요!`,
          questTimer: 0.5,
        },
      })}`
    } else {
      window.location.href = `#generalNavigator.${JSON.stringify({
        screen: 'AlfarmAdPurchaseScreen',
        params: {
          title: '올팜',
          image:
            'https://assets.ilevit.com/2cfa0fa4-f146-429f-a44c-5a5375f48bf5.png', // s3 이미지 가져오기 - 바꿔줘야함
          ratio: 99 / 360,
          enteringComponent: 'Alfarm_nutrimentLeaves',
          adId: mainAdId,
          subAdId: subAdIds,
          itemIds: combinedItemIds,
          isTimerOn: !clearedAd,
        },
      })}`
    }
  }

  const showRewardedAd = () => {
    if (!clearedAd) {
      if (UserStore.isRewardAdReady) {
        window.ReactNativeWebView?.postMessage(
          JSON.stringify({
            type: 'showRewardedAd',
            data: {
              adType: 'rewarded',
              placement: 'ALFARM_NUTRIMENT_LEAVES_MISSION_REWARD',
            },
          }),
        )
      } else {
        sendToPurchaseScreen()
        AlfarmEventLogger({
          locationType: 'page',
          locationName: 'not_ready_rewardAd_Nutriment_leaves',
          eventType: 'pageview',
          data: {},
          collection: 'UserAlfarmPageviewLog',
        })
      }
    } else {
      window.location.href = `#generalNavigator.${JSON.stringify({
        screen: 'AlfarmAdPurchaseScreen',
        params: {
          title: '올팜',
          image:
            'https://assets.ilevit.com/2cfa0fa4-f146-429f-a44c-5a5375f48bf5.png', // s3 이미지 가져오기 - 바꿔줘야함
          ratio: 99 / 360,
          enteringComponent: 'Alfarm_nutrimentLeaves',
          adId: mainAdId,
          subAdId: subAdIds,
          itemIds: combinedItemIds,
          isTimerOn: !clearedAd,
        },
      })}`
    }
  }

  const getAdItemInfo = async () => {
    // 앱러빈 광고 보고 온 경우, 콜백을 위해 잠시 대기
    if (UserStore.isWatchingAd === true) await sleep(1000)

    // 하루에 최대 볼 수 있는 광고 횟수 - AB 테스트
    const dailyAdMaxExposureNumber = AlfarmAdStore.leavesAdDailyMaxExposureCount
    const timeIntervalBetweenExposure =
      AlfarmAdStore.leavesAdTimeIntervalBetweenExposure
    const result = await backendApis.getNutrimentLeavesAdsInfo(token, {
      dailyAdMaxExposureNumber,
      timeIntervalBetweenExposure,
    })

    if (result?.data?.mainAdData) {
      setMainAdData(result?.data?.mainAdData)
      setSubAdData(result?.data?.subAdData)
      if (result?.data?.cleared) {
        setModalImage('/nutrimentLeaves/purchaseModal.png')
      } else if (nutrimentLeavesRewardedAdCheck) {
        setModalImage('/nutrimentLeaves/rewardedAdModal.png')
      } else {
        setModalImage('/nutrimentLeaves/modal.png')
      }
    }
    if (!result?.data?.mainAdData && result?.status === 200) {
      setIsNoLeaves(true)
      setModalImage('/nutrimentLeaves/noLeavesModal.png')
    }
    setClearedAd(result?.data?.cleared)
    setIsLoading(false)
  }

  useEffect(() => {
    const handleRewardedAdReady = async () => {
      if (nutrimentLeavesRewardedAdCheck && !UserStore.isRewardAdReady) {
        setIsLoadingRewardedAd(true)
        // 광고 대상자인데 로드 안된경우 0.3초마다 확인해 최대 3초까지 대기
        for (let i = 0; i < 10; i += 1) {
          if (UserStore.isRewardAdReady) break
          // 로드되면 반복 종료
          await sleep(300)
        }
        setIsLoadingRewardedAd(false)
      }

      if (nutrimentLeavesRewardedAdCheck && UserStore.isRewardAdReady) {
        setModalImage('/nutrimentLeaves/rewardedAdModal.png')
      } else if (
        nutrimentLeavesRewardedAdCheck &&
        !UserStore.isRewardAdReady &&
        !isNoLeaves
      ) {
        setModalImage('/nutrimentLeaves/modal.png')
      }
    }

    handleRewardedAdReady()
  }, [UserStore.isRewardAdReady])

  useEffect(() => {
    getAdItemInfo()
  }, [UserStore.isWatchingAd])

  if (isLoading) {
    return (
      <div
        style={{
          flex: 1,
          position: 'fixed',
          left: 0,
          top: 0,
          zIndex: 5004,
          width: '100%',
          height: '100vh',
          backgroundColor: 'rgba(0,0,0,0.8)',
        }}
      >
        <div
          style={{
            width: '100%',
            transform: 'translate(-50%, -50%)',
            left: '50%',
            top: '50%',
            position: 'absolute',
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          <div
            style={{
              position: 'absolute',
              transform: 'translate(-50%, -50%)',
              left: '50%',
              top: '50%',
            }}
          >
            <Loader type='Oval' color='#EA3F49' height='40' width='40' />
          </div>

          <button
            style={{
              width: '8%',
              top: '0%',
              right: '10%',
              position: 'absolute',
              zIndex: 1113,
            }}
            onClick={() => {
              ModalStore.setIsModalOpen('basic')
            }}
          >
            <img
              style={{
                position: 'absolute',
                zIndex: 101,
              }}
              src='/icon/modalCloseButton.png'
              alt=''
            />
          </button>
          <img
            src={'/nutrimentLeaves/loadingModal.png'}
            alt='abc'
            style={{ width: '80vw' }}
          />
        </div>
      </div>
    )
  }

  return (
    <div
      style={{
        flex: 1,
        position: 'fixed',
        left: 0,
        top: 0,
        zIndex: 5004,
        width: '100%',
        height: '100vh',
        backgroundColor: 'rgba(0,0,0,0.8)',
      }}
    >
      {isLoadingRewardedAd && (
        <LoadingIndicator isLoading={isLoadingRewardedAd} />
      )}
      <div
        style={{
          width: '100%',
          transform: 'translate(-50%, -50%)',
          left: '50%',
          top: '50%',
          position: 'absolute',
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        <button
          style={{
            width: '8%',
            top: '0%',
            right: '10%',
            position: 'absolute',
            zIndex: 1113,
          }}
          onClick={() => {
            ModalStore.setIsModalOpen('basic')
          }}
        >
          <img
            style={{
              position: 'absolute',
              zIndex: 101,
            }}
            src='/icon/modalCloseButton.png'
            alt=''
          />
        </button>
        <img src={modalImage} alt='abc' style={{ width: '80vw' }} />
        {!isNoLeaves && (
          <div
            style={{
              background: isButtonPressed ? '#F7C82A' : '#F7C82A',
              boxShadow: isButtonPressed
                ? 'inset 3px 3px 4px rgba(79, 19, 0, 0.15)'
                : '',
              width: '60vw',
              height: '14vw',
              borderRadius: 99,
              color: 'black',
              fontFamily: 'maplestory',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              fontSize: '4.5vw',
              position: 'absolute',
              bottom: '8vw',
            }}
            onClick={() => {
              if (nutrimentLeavesRewardedAdCheck) {
                showRewardedAd()
              } else {
                sendToPurchaseScreen()
              }
              ModalStore.setIsModalOpen('basic')
            }}
            onPointerDown={() => {
              setIsButtonPressed(true)
            }}
            onPointerCancel={() => {
              setIsButtonPressed(false)
            }}
            onPointerUp={() => {
              setIsButtonPressed(false)
            }}
          >
            {buttonText}
          </div>
        )}
      </div>
    </div>
  )
})

export default NutrimentLeaveModalV2
