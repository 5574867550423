import backendApis from 'utils/backendApis'
import { useState, useEffect } from 'react'
import { getCPMReadyModalPropsByType } from 'utils/alfarmCPMConstant/cpmAdConfig'
import { CPM_ENTERING_COMPONENTS } from 'utils/alfarmCPMConstant/cpmAdTypes'
import ModalStore from 'store/ModalStore'

const FortuneCookieAdReadyModal = () => {
  const enteringComponent = CPM_ENTERING_COMPONENTS.FORTUNE_COOKIE_AD
  const [adConfig, setAdConfig] = useState({})

  useEffect(() => {
    setAdConfigByType()
  }, [])

  const setAdConfigByType = () => {
    const adConfig = getCPMReadyModalPropsByType(enteringComponent)
    setAdConfig(adConfig)
  }

  const clickAction = async () => {
    const determineReward = async () => {
      const result = await backendApis.presetCPMRewardByType({
        type: enteringComponent,
        props: {
          userPurchaseFrequencyType: adConfig.userPurchaseFrequencyType,
        },
      })
    }

    const openModal = () => {
      ModalStore.setShowCPMAdAnimation(true)
      ModalStore.setIsModalOpen(adConfig.modalPath)
    }

    await determineReward()
    openModal()
  }

  const CloseButton = () => {
    return (
      <button
        className='w-[10%] absolute right-[2%] top-[3%] z-[1113] transform-translate-x-[-50%] transform-translate-y-[-50%]'
        onClick={() => {
          ModalStore.setIsModalOpen('basic')
        }}
      >
        <img
          className='absolute z-[101]'
          src='/icon/modalCloseButton.png'
          alt=''
        />
      </button>
    )
  }

  const ModalBackgroundImage = () => {
    return (
      <img
        src={adConfig.modalBackgroundImageUrl}
        alt='abc'
        className='w-[100vw] mb-[10vw]'
      />
    )
  }

  const HeaderSection = () => {
    return (
      <div className='absolute font-maplestory text-[4.7vw] w-[70%] text-center top-[10vw] h-[30vw] flex flex-col justify-center items-center line-height-[140%] text-[#7C5C35] rounded-[20px] bg-[#FFF] box-shadow-[0px_-6px_0px_0px_rgba(151,176,195,0.34)_inset] pt-[1vw]'>
        {adConfig.modalText1} <br />
        {adConfig.modalText2}
      </div>
    )
  }

  const BodySection = () => {
    return (
      <div className='absolute left-[60vw] top-[60vw] w-[80%] -translate-x-1/2 -translate-y-1'>
        <div
          className='w-[50vw] h-[50vw] '
          onClick={() => {
            clickAction()
          }}
        >
          <img src={adConfig.modalImageUrl1} alt='' />
          <div className='hammer w-[45%] mt-[-70%] absolute right-[0%]'>
            <img src={adConfig.modalImageUrl2} alt='' />
          </div>
        </div>
      </div>
    )
  }

  const CTRButton = () => {
    return (
      <button
        className='z-[1114] w-[55vw] h-[14vw] rounded-[99px] font-maplestory justify-center items-center text-[4.5vw] absolute bottom-[5vw] left-1/2 -translate-x-1/2 translate-y-1/2 bg-[#F7C82A] text-black'
        onClick={clickAction}
      >
        {adConfig.CTRButtonText}
      </button>
    )
  }

  return (
    <div className='fixed inset-0 z-[5004] flex items-center justify-center bg-black/80'>
      <div className='w-[90vw] relative'>
        <CloseButton />
        <ModalBackgroundImage />
        <div className='flex flex-col items-center justify-center'>
          <HeaderSection />
          <BodySection />
          <CTRButton />
        </div>
      </div>
    </div>
  )
}

export default FortuneCookieAdReadyModal
