import { observer } from 'mobx-react-lite'
import ModalCloseButton from 'comps/atoms/buttons/ModalCloseButton'
import ModalStore from 'store/ModalStore'

const VideoAdModalWrapper = observer(({ modalImageUrl, children, style }) => {
  const handleClose = () => {
    ModalStore.setIsModalOpen('basic')
  }

  return (
    <div className='flex-1 fixed left-0 top-0 z-[1100] w-full h-full bg-[rgba(0,0,0,0.8)]'>
      <div
        className='w-[90vw] h-[134vw] top-[50%] left-[50%] -translate-x-1/2 -translate-y-1/2 flex absolute items-center flex-col px-[16px] pt-[40vw] pb-[16px]'
        style={{
          backgroundImage: modalImageUrl
            ? `url(${modalImageUrl})`
            : 'url(../modal/backgroundVideoModal.png)',
          ...style,
        }}
      >
        <ModalCloseButton onClick={handleClose} style={{ top: '-3vw' }} />
        {children}
      </div>
    </div>
  )
})

export default VideoAdModalWrapper
