const VideoAdModalTitle = ({ title, titleUrl, questReward }) => {
  return (
    <div className='relative w-full flex justify-center items-center'>
      {titleUrl && (
        <div className='absolute top-[-46vw] w-[50%]'>
          <img src={titleUrl} alt='영상 광고 모달 제목' />
        </div>
      )}
      {title && (
        <div className='absolute top-[-21vw] flex text-[#442B22] font-bold text-[6.4vw]'>
          {title}
        </div>
      )}
      {questReward && (
        <div className='absolute top-0 flex justify-center items-center flex-col font-bold text-[5.4vw] break-words'>
          <div className='text-[#442B22]'>영상광고를 보면 물을 </div>
          <div className='text-[#442B22]'>
            최대 <strong className='text-[#FF6565]'>{questReward}</strong>을
            받을 수 있어요!
          </div>
        </div>
      )}
    </div>
  )
}

export default VideoAdModalTitle
