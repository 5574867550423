import { useState } from 'react'
import { BADAHAM_V2_COIN_IMAGE_URL } from '../_constatns/badahamAssets'
import RewardCard from './RewardCard'

const CoinMarketSection = ({ coinExchangeData = {}, coinCount = 0, refetchCoinCount }) => {
  const [isLoading, setIsLoading] = useState(false)

  if (!coinExchangeData) return null

  return (
    <div className='px-[16px] w-full mx-auto'>
      <div className='flex flex-col gap-[8px]'>
        <div className='flex items-center justify-between'>
          <span className='text-[22px] text-[#009FBB] font-bold'>진주 상점</span>
          <div className='flex items-center gap-[6px] bg-white rounded-[999px] py-[8px] px-[19px]'>
            <img src={BADAHAM_V2_COIN_IMAGE_URL} alt="진주" className='w-[20px] h-[20px]' />
            <span className='text-[17px] font-light text-[#646464]'>보유 진주</span>
            <span className='text-[17px] text-[#FF0000] font-bold'>{coinCount}개</span>
          </div>
        </div>
        <div className='grid grid-cols-2 gap-[10px]'>
          {Object.entries(coinExchangeData).map(([price, data]) => (
            <RewardCard
              key={price}
              coinCount={coinCount}
              price={price}
              rewardInfo={data.rewardInfo}
              refetchCoinCount={refetchCoinCount}
              isLoading={isLoading}
              setIsLoading={setIsLoading}
            />
          ))}
        </div>
      </div>
    </div>
  )
}

export default CoinMarketSection