import MissionList from './MissionList'
import backendApis from 'utils/backendApis'
import { useEffect, useState } from 'react'
import BadahamBanner from './BadahamBanner'
import RewardRecordButton from './RewardRecordButton'
import ModalStore from 'store/ModalStore'
import AlfarmEventLogger from 'utils/alfarmEventLogger'
import LoadingIndicator from 'comps/loadingIndicator'
import { observer } from 'mobx-react-lite'
import BadahamInfoModal from './BadahamInfoModal'
import RewardAdModal from 'comps/atoms/RewardAdModal'
import PearlReceivedModal from './PearlReceivedModal'
import AdditionalRewardSection from './AdditionalRewardSection'
import UserStore from 'store/UserStore'
import InitializeTimer from './InitializeTimer'

const Title = () => {
  return (
    <div
      className='flex flex-col justify-center items-center'
      onClick={() => {
        ModalStore.setIsModalOpen('badahamInfoModal')
      }}
    >
      <img
        src='/ads/badahamMission/badahamTitle.png'
        alt='badaham'
        className='w-[60vw]'
      />
      <span className='text-[#4EB8CA] mt-[1vh]'>
        미션을 완료한 만큼 보상을 받을 수 있어요!
      </span>
    </div>
  )
}

const ClearedMissionCountSection = ({ clearedMissionCount = 0 }) => {

  return (
    <div
      className='bg-[#FFF] w-[337px] h-[40px] rounded-3xl flex items-center justify-between pl-[8px] pr-[15px]'
    >
      <div className='flex items-center justify-between w-[300px] px-[8px]'>
      <div className='flex items-center'>
        <span className='font-normal text-[17px] text-[#646464]'>완료 미션</span>
        <div className='w-[6px]' />
          <span className='font-bold text-[18px] text-[#FF6565]'>{clearedMissionCount}번 </span>
        </div>
        <InitializeTimer 
          className='text-[13px] text-[#C1C1C1]' 
          suffix='후 전체 초기화'
          showMilliseconds={false}
        />
      </div>
    </div>
  )
}

const NoteSection = () => {
  return (
    <div
      className='w-[80vw] mx-auto flex flex-col
    text-[#74BAC6] text-[3.5vw]'
    >
      <span className='font-bold text-lg text-[#74BAC6]'>유의사항</span>
      · 바다햄 미션은 일일 미션이며, 매일 초기화돼요.
      <br />
      · '출석하기' 미션은 일 1회씩만 가능해요. <br />      
    </div>
  )
}

const BadahamScreen = observer(() => {
  const [dailyBadahamData, setDailyBadahamData] = useState(null)

  const refetchDailyBadahamData = async () => {
    const res = await backendApis.getDailyEventQuestData({event: 'badaham'})
    setDailyBadahamData(res?.data)
  }

  useEffect(() => {
    refetchDailyBadahamData()
  }, [UserStore.isWatchingAd])

  const clearedMissionCount =  Object.entries(dailyBadahamData?.questInfo || {})
  .filter(
    ([questType]) =>
      !['clear1','clear3','clear6','clear9']?.includes(questType),
  )
  // eslint-disable-next-line no-unused-vars
  .reduce((sum, [_, dateArray]) => sum + dateArray.length, 0)

  // 페이지 뷰 로그
  useEffect(() => {
    AlfarmEventLogger({
      locationType: 'page',
      locationName: 'badahamScreen',
      eventType: 'pageview',
      data: {},
      collection: 'UserAlfarmPageviewLog',
    })
  }, [])

  return (
    <div
      className='w-full h-screen fixed flex flex-col bg-[#DEFAFF] bg-cover 
    bg-no-repeat bg-center overflow-y-scroll pb-8'
    >
      <RewardRecordButton modalType={'badahamRecordModal'} />
      <Title />
      <div className='mb-[8px]'/>
      <div className='bg-[#C2F0F8]'>
        <div
          className='mb-[4vw] py-[20px] flex flex-col items-center justify-start gap-[12px] mx-[16px] overflow-x-hidden'
        >
          <div className='flex flex-col items-center w-full'>
            <ClearedMissionCountSection clearedMissionCount={clearedMissionCount} />
            <div className='w-full max-w-full overflow-x-auto flex items-center'>
                <div className='min-w-max px-4'>
                    <AdditionalRewardSection 
                        dailyBadahamData={dailyBadahamData} 
                        refetchDailyBadahamData={refetchDailyBadahamData} 
                        clearedMissionCount={clearedMissionCount}
                    />
                </div>
            </div>
            <div className='h-[10px]' />
          </div>
          <MissionList dailyBadahamData={dailyBadahamData} refetchDailyBadahamData={refetchDailyBadahamData} clearedMissionCount={clearedMissionCount} />
          {/* <BadahamBanner /> */}
        </div>
      </div>
      
      <div className='h-[21px]' />
      <NoteSection />
      {ModalStore?.isModalOpen === 'badahamInfoModal' && <BadahamInfoModal />}
      {ModalStore?.isModalOpen === 'rewardAdModalWater' && <RewardAdModal />}
      {ModalStore?.isModalOpen === 'pearlReceivedModal' && (
        <PearlReceivedModal />
      )}
    </div>
  )
})

export default BadahamScreen
