import backendApis from 'utils/backendApis'
import { useState, useEffect } from 'react'
import { getCPMReadyModalPropsByType } from 'utils/alfarmCPMConstant/cpmAdConfig'
import { CPM_ENTERING_COMPONENTS } from 'utils/alfarmCPMConstant/cpmAdTypes'
import ModalStore from 'store/ModalStore'

const styles = {
  background: {
    flex: 1,
    position: 'fixed',
    left: 0,
    top: 0,
    zIndex: 5000,
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(0,0,0,0.8)',
  },
  modalFrame: {
    width: '80vw',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    display: 'flex',
    position: 'relative',
    alignItems: 'center',
    flexDirection: 'column',
    zIndex: 5001,
    backgroundSize: 'contain',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
  },
  modalBody: {
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    zIndex: 100,
    width: '60vw',
    height: '70vw',
    justifyContent: 'center',
  },
  modalCloseButtonStyle: {
    width: '8vw',
    right: '0vw',
    top: '-42vw',
    position: 'absolute',
    zIndex: 999,
  },
  buttonImage: {
    position: 'absolute',
    display: 'flex',
    bottom: '-30vw',
    width: '60vw',
    left: '12.5%',
    alignItems: 'center',
  },
  buttonTextStyle: {
    position: 'absolute',
    fontSize: '6.5vw',
    color: '#663F16',
    width: '100%',
    textAlign: 'center',
  },
  headerMessageStyle: {
    position: 'absolute',
    fontSize: '4.8vw',
    width: '60vw',
    textAlign: 'center',
    top: '-14vw',
    height: '18vw',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    lineHeight: '1.3',
    color: '#7C5C35',
    borderRadius: '20px',
    background: '#FFF',
    boxShadow: '0px -6px 0px 0px rgba(151, 176, 195, 0.34) inset',
    whiteSpace: 'pre-line',
  },
  titleStyle: {
    width: '50%',
    top: '-34vw',
    left: '24%',
    zIndex: '101',
    position: 'absolute',
    display: 'flex',
  },
  infoButtonStyle: {
    width: '50%',
    color: 'white',
    fontFamily: 'Maplestory',
    textAlign: 'center',
    fontSize: '5vw',
    marginBottom: '1vw',
    marginTop: '-3vw',
    // textDecoration: 'underline',
    zIndex: 3000,
    position: 'absolute',
    bottom: '-12vw',
    // left: "-15vw",
  },
  infoModalStyle: {
    flex: 1,
    position: 'fixed',
    left: '50%',
    top: '50%',
    zIndex: 5100,
    width: '80%',
    // background: "rgba(0,0,0,0.9)",
    background: 'white',
    borderRadius: 20,
    fontFamily: 'maplestory',
    // fontSize: "4vw",
    padding: '6vw',
    paddingTop: '8vw',
    lineHeight: '170%',
    paddingBottom: '8vw',
    transform: 'translate(-50%,-50%)',
  },
}

const YutnoriAdReadyModal = () => {
  const enteringComponent = CPM_ENTERING_COMPONENTS.YUTNORI_AD
  const [adConfig, setAdConfig] = useState({})
  useEffect(() => {
    setAdConfigByType()
  }, [])

  const setAdConfigByType = () => {
    const adConfig = getCPMReadyModalPropsByType(enteringComponent)
    setAdConfig(adConfig)
  }

  const play = async () => {
    const determineReward = async () => {
      await backendApis.presetCPMRewardByType({
        type: CPM_ENTERING_COMPONENTS.YUTNORI_AD,
        props: {
          userPurchaseFrequencyType: adConfig.userPurchaseFrequencyType,
        },
      })
    }

    const openModal = () => {
      ModalStore.setShowCPMAdAnimation(true)
      ModalStore.setIsModalOpen(adConfig.modalPath)
    }

    await determineReward()
    openModal()
  }

  const ModalCloseButton = () => {
    return (
      <button
        style={{ ...styles.modalCloseButtonStyle }}
        onClick={() => {
          // showRewardToast(
          //   rewardForToast?.rewardAmount,
          //   rewardForToast?.rewardType
          // );
          ModalStore.setIsModalOpen('basic')
        }}
      >
        <img
          style={{
            zIndex: 101,
          }}
          src='/icon/modalCloseButton.png'
          alt=''
        />
      </button>
    )
  }

  const ModalBackgroundImage = () => {
    return (
      <div className='relative'>
        <img
          src={adConfig.modalBackgroundImageUrl}
          alt='배경'
          className='w-[90vw]'
        />
        <img
          src='/minigame/yutnori/yutStartImage.png'
          alt='윷'
          className='absolute w-[50vw] left-[20vw] top-[16vw]'
        />
      </div>
    )
  }

  const GameButton = () => {
    return (
      <button style={{ ...styles.buttonImage }} onClick={play}>
        <img src='../minigame/yutnori/yutnoriButtonImage.png' alt='' />
        <div
          style={{
            ...styles.buttonTextStyle,
          }}
        >
          {adConfig.buttonText}
        </div>
      </button>
    )
  }

  const CenterMessage = () => {
    return (
      <div className='absolute w-[60vw] left-1/2 top-[-6%] -translate-x-1/2 -translate-y-1/2 bg-white/90 px-8 py-4 rounded-2xl text-[#7C5C35] text-[5vw] whitespace-pre-line text-center shadow-[inset_0_-4px_0_0_rgba(151,176,195,0.34)] z-[100]'>
        윷을 던져 보세요!
      </div>
    )
  }

  return (
    <div className='fixed inset-0 z-[5004] flex items-center justify-center bg-black/80'>
      <div className='w-[90vw] relative'>
        <div style={{ ...styles.titleStyle }}>
          <img src='/minigame/yutnori/yutnoriTitle.png' alt='' />
        </div>
        <ModalCloseButton />
        <CenterMessage />
        <ModalBackgroundImage />
        <GameButton />
      </div>
    </div>
  )
}

export default YutnoriAdReadyModal
