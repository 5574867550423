const ModalCloseButton = ({ onClick, style }) => {
  return (
    <button
      type='button'
      className='z-[1101] w-[8%] -top-[10vw] right-[4vw] absolute'
      onClick={onClick}
      style={style}
    >
      <img
        className='z-[1101]'
        src='/icon/modalCloseButton.png'
        alt='close button'
      />
    </button>
  )
}

export default ModalCloseButton
