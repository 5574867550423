import {
  CPM_VIEWER_TYPES,
  CPM_ENTERING_COMPONENTS,
  CPM_PURCHASE_FREQUENCY_TYPES,
  CPM_GOODS_MODAL_TYPES,
  CPM_VIDEO_MODAL_TYPES,
} from './cpmAdTypes'

// 구매 빈도에 따른 configs
const PURCHASE_FREQUENCY_AD_CONFIGS = {
  [CPM_PURCHASE_FREQUENCY_TYPES.LOW]: {
    viewerType: CPM_VIEWER_TYPES.VIDEO, // goods, video, none
    maxViewCount: 2, // 최대 광고 볼 수 있는 횟수
    intervalHours: 6, // 광고 보기 간격
    textMaxRewardAmount: 10, // 보상 최대 크기 (버튼 텍스트)
    browsingSeconds: 30,
  },
  [CPM_PURCHASE_FREQUENCY_TYPES.MEDIUM]: {
    viewerType: CPM_VIEWER_TYPES.GOODS,
    maxViewCount: 2,
    intervalHours: 6,
    textMaxRewardAmount: 10,
    browsingSeconds: 30,
  },
  [CPM_PURCHASE_FREQUENCY_TYPES.HEAVY]: {
    viewerType: CPM_VIEWER_TYPES.GOODS,
    maxViewCount: 3,
    intervalHours: 4,
    textMaxRewardAmount: 10,
    browsingSeconds: 30,
  },
}

// viewerType에 따른 configs
const VIEWER_TYPE_AD_CONFIGS = {
  [CPM_VIEWER_TYPES.GOODS]: {
    buttonTitleText: '낙엽 상품 광고 보기',
    navigationModal: CPM_GOODS_MODAL_TYPES.LEAVES_AD,
  },
  [CPM_VIEWER_TYPES.VIDEO]: {
    buttonTitleText: '낙엽 영상 광고 보기',
    navigationModal: CPM_VIDEO_MODAL_TYPES.LEAVES_AD,
  },
  [CPM_VIEWER_TYPES.NONE]: {
    buttonTitleText: '',
    navigationModal: '',
  },
}

// 버튼 PROPS를 동적으로 반환
const getButtonProps = (userPurchaseFrequencyType) => {
  const purchaseConfig =
    PURCHASE_FREQUENCY_AD_CONFIGS[userPurchaseFrequencyType]

  const viewerType = purchaseConfig.viewerType

  const viewerConfig = VIEWER_TYPE_AD_CONFIGS[viewerType]

  const BUTTON_PROPS = {
    codePushVersionLimit: '6.8.37', // 버전 제한
    bottomEventIconImageUrl: `/nutrimentLeaves/bottomIcon.png`,
    viewerType,
    maxViewCount: purchaseConfig.maxViewCount,
    intervalHours: purchaseConfig.intervalHours,
    navigationModal: viewerConfig.navigationModal,
  }
  return BUTTON_PROPS
}

// 상품 모달 PROPS를 동적으로 반환
const getGoodsModalProps = (userPurchaseFrequencyType) => {
  const purchaseConfig =
    PURCHASE_FREQUENCY_AD_CONFIGS[userPurchaseFrequencyType]

  const GOODS_MODAL_COMMON_PROPS = {
    BROWSING_TIME_SEC: purchaseConfig.browsingSeconds,
    FIRST_BUTTON_TEXT: '준비 중',
    FINAL_BUTTON_TEXT: '상품 구경하고 양분 받기',
    MODAL_IMAGE_URL:
      'https://assets.ilevit.com/072d05d2-1c9c-4b72-a5e6-4e71cfce553f.png',
    CLOSE_BUTTON_POSITION: { top: '18%', right: '15%' }, // TODO: 수정필요 - 레디모달에 맞게
    ENTERING_COMPONENT: CPM_ENTERING_COMPONENTS.LEAVES_AD,
    MAX_REWARD_AMOUNT: purchaseConfig.textMaxRewardAmount, // 최대 보상 양분
    MODAL_TEXT_1: '떨어진 낙엽을 주웠어요',
    MODAL_TEXT_2: `상품을 ${purchaseConfig.browsingSeconds}초 보면 양분을 최대 ${purchaseConfig.textMaxRewardAmount} 받아요`,
  }

  const CPM_PURCHASE_SCREEN_PROPS = {
    title: '낙엽 상품 구경하기',
    image: 'https://assets.ilevit.com/2cfa0fa4-f146-429f-a44c-5a5375f48bf5.png',
    ratio: 99 / 360,
    enteringComponent: CPM_ENTERING_COMPONENTS.LEAVES_AD,
    questTimer: purchaseConfig.browsingSeconds / 60,
    isTimerOn: true,
    userPurchaseFrequencyType: userPurchaseFrequencyType,
    questRewardText: `초간 구경하면 양분을 최대 ${purchaseConfig.textMaxRewardAmount} 받아요!`,
    scrollQuestReward: `양분 최대 ${purchaseConfig.textMaxRewardAmount}`,
    buyingReward: '고급 비료 5개, 물 1,000g',
  }

  const GOODS_MODAL_PROPS = {
    GOODS_MODAL_COMMON_PROPS,
    CPM_PURCHASE_SCREEN_PROPS,
  }

  return GOODS_MODAL_PROPS
}

const getVideoModalProps = (userPurchaseFrequencyType) => {
  const purchaseConfig =
    PURCHASE_FREQUENCY_AD_CONFIGS[userPurchaseFrequencyType]

  return {
    VIDEO_MODAL_COMMON_PROPS: {
      ...purchaseConfig,
      viewerType: CPM_VIEWER_TYPES.VIDEO,
    },
    CPM_PURCHASE_SCREEN_PROPS: {
      ...VIEWER_TYPE_AD_CONFIGS[CPM_VIEWER_TYPES.VIDEO],
      enteringComponent: CPM_ENTERING_COMPONENTS.LEAVES_AD,
      userPurchaseFrequencyType: userPurchaseFrequencyType,
    },
  }
}

export { getButtonProps, getGoodsModalProps, getVideoModalProps }
