import React from 'react'
import CloseButton from '../CloseButton'

function ModalFrame({ children, closeButtonStyle, modalContentStyle, onClose = () => {} }) {
  const styles = {
    modalContainer: {
      flex: 1,
      position: 'fixed',
      left: 0,
      top: 0,
      zIndex: 2000,
      width: '100%',
      height: '100vh',
      backgroundColor: 'rgba(0,0,0,0.8)',
    },
  }

  return (
    <div style={styles.modalContainer}>
      <div style={modalContentStyle}>
        {closeButtonStyle && <CloseButton styles={closeButtonStyle} onClose={onClose} />}
        {children}
      </div>
    </div>
  )
}

export default ModalFrame
