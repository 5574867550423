import { observer } from 'mobx-react-lite'

export const OfferwallContentItemButton = observer(({ itemInfo }) => {
  let buttonText = '더 보기'

  if (itemInfo?.isParticipated) {
    buttonText =
      itemInfo.reward_condition === 'click' ? '참여 완료' : '참여 확인중'
  } else if (itemInfo?.rewardPointInfo) {
    buttonText = itemInfo.rewardPointInfo
      .map((reward) => {
        switch (reward.type) {
          case 'water':
            return `물 ${reward.point.toLocaleString()}g`
          case 'fertilizer':
            return `일반비료 ${reward.point}개`
          case 'fertilizerSet':
            return `고급비료 ${reward.point}개`
          default:
            return ''
        }
      })
      .filter((text) => text)
      .join(' + \n')

    buttonText += ' 받기'
  } else if (itemInfo?.rewardPoint) {
    buttonText = `물 ${itemInfo.rewardPoint.toLocaleString()}g 받기`
  }

  return (
    <button
      type='button'
      className={`max-w-[30vw] break-keep px-2.5 py-2 text-xs font-semibold rounded-lg whitespace-pre-line duration-200 active:brightness-90 active:scale-95 ${
        itemInfo?.isParticipated
          ? 'bg-[#f5f5f5] text-[#9e9e9e]'
          : 'text-[#3388D0] bg-[#E9F5FF] active:brightness-90 active:scale-95 duration-200'
      }`}
      onClick={() => {}}
    >
      {buttonText}
    </button>
  )
})
