import React, { useRef, useState } from 'react'
import { observer } from 'mobx-react-lite'
import dayjs from 'dayjs'
import ModalStore from '../../../store/ModalStore'
import DealAutomationStore from '../../../store/DealAutomationStore'
import TimeStore from '../../../store/TimeStore'
import AlfarmEventLogger from 'utils/alfarmEventLogger'

const ImageSprite = () => {
  if (
    TimeStore.currentDayJS >
      dayjs(DealAutomationStore.firstBrandDealData.timeRangeStart) &&
    TimeStore.currentDayJS <
      dayjs(DealAutomationStore.firstBrandDealData.timeRangeEnd)
  ) {
    return {
      modalImage: DealAutomationStore.firstBrandDealData.background,
      purchaseScreenBanner:
        DealAutomationStore.firstBrandDealData.purchaseScreenBanner,
      purchaseScreenText:
        DealAutomationStore.firstBrandDealData.purchaseScreenText,
    }
  } else if (
    TimeStore.currentDayJS >
      dayjs(DealAutomationStore.secondBrandDealData.timeRangeStart) &&
    TimeStore.currentDayJS <
      dayjs(DealAutomationStore.secondBrandDealData.timeRangeEnd)
  ) {
    return {
      modalImage: DealAutomationStore.secondBrandDealData.background,
      purchaseScreenBanner:
        DealAutomationStore.secondBrandDealData.purchaseScreenBanner,
      purchaseScreenText:
        DealAutomationStore.secondBrandDealData.purchaseScreenText,
    }
  }
}

const BrandDealModal = observer(
  ({ token, farmData, setFarmData, isLoading, userCodePushVersion, codePushVersionAvailable }) => {
    const [isButtonPressed, setIsButtonPressed] = useState(false)
    const throttleRef = useRef({})

    return (
      <>
        <div
          style={{
            flex: 1,
            position: 'fixed',
            left: 0,
            top: 0,
            zIndex: 5004,
            width: '100%',
            height: '100vh',
            backgroundColor: 'rgba(0,0,0,0.8)',
          }}
        >
          <div className='w-[90vw] top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 flex absolute justify-around items-center flex-col'>
            <button
              style={{
                width: '10%',
                top: '-8%',
                right: '8%',
                position: 'absolute',
                zIndex: 1113,
              }}
              onClick={() => {
                ModalStore.setIsModalOpen('basic')
              }}
            >
              <img
                style={{
                  position: 'absolute',
                  zIndex: 101,
                }}
                src='/icon/modalCloseButton.png'
                alt=''
              />
            </button>
            <img
              src={ImageSprite()?.modalImage}
              alt='abc'
              style={{ width: '90vw' }}
            />
            <div
              style={{
                background: isButtonPressed ? '#F7C82A' : '#F7C82A',
                boxShadow: isButtonPressed
                  ? 'inset 3px 3px 4px rgba(79, 19, 0, 0.15)'
                  : '',
                width: '60vw',
                height: '14vw',
                borderRadius: 99,
                color: 'black',
                fontFamily: 'maplestory',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                fontSize: '4.5vw',
                position: 'absolute',
                bottom: '8vw',
                left: '15vw',
              }}
              onClick={() => {
                AlfarmEventLogger({
                  throttleRef,
                  locationType: 'modal',
                  locationName: ModalStore.isModalOpen,
                  eventType: 'click',
                  eventName: 'cta',
                  data: {},
                  collection: 'UserAlfarmClickLog',
                })

                const params = codePushVersionAvailable(userCodePushVersion, '6.8.15') ? {
                  dealType: 'alfarmBrand',
                  enteringLocation: 'modal',
                  enteringComponent: 'Alfarm_BrandDealScreen',
                  title: ImageSprite()?.purchaseScreenText,
                } : {
                  dealType: 'alfarmBrand',
                  title: ImageSprite()?.purchaseScreenText,
                  image: ImageSprite()?.purchaseScreenBanner,
                  ratio: 99 / 360,
                  enteringLocation: 'modal',
                }

                window.location.href = `#${codePushVersionAvailable(userCodePushVersion, '6.8.15') ? 'alfarmNewBrandDealScreen' : 'alfarmDealScreen'}.${JSON.stringify(params)}`
                ModalStore.setIsModalOpen('basic')
              }}
              onPointerDown={() => {
                setIsButtonPressed(true)
              }}
              onPointerCancel={() => {
                setIsButtonPressed(false)
              }}
              onPointerUp={() => {
                setIsButtonPressed(false)
              }}
            >
              상품 구매하고 혜택 받기
            </div>
          </div>
        </div>
      </>
    )
  },
)

export default BrandDealModal
