import { useMutation } from '@tanstack/react-query'
import ModalCloseButton from 'comps/atoms/buttons/ModalCloseButton'
import Dimmed from 'comps/dimmed/Dimmed'
import LoadingIndicator from 'comps/loadingIndicator'
import MissionRewardButton, {
  REWARD_TYPE,
} from 'comps/organisms/SansinEventModal/components/MissionRewardButton'
import MissionShortcutButton, {
  MISSION_STATUS,
} from 'comps/organisms/SansinEventModal/components/MissionShortcutButton'
import useGetMissionEventStatus from 'comps/organisms/SansinEventModal/hooks/useGetMissionEventStatus'
import { useCallback, useEffect } from 'react'
import AlfarmAdStore from 'store/AlfarmAdStore'
import ModalStore from 'store/ModalStore'
import UserStore from 'store/UserStore'
import { CPM_PURCHASE_FREQUENCY_TYPES } from 'utils/alfarmCPMConstant/cpmAdTypes'
import backendApis from 'utils/backendApis'
import {
  checkIntervalTime,
  determineAdAvailability,
} from 'utils/getCPMBottomEventIconButtonProps'

function SansinEventModal({ token, showRewardToast }) {
  const queries = useGetMissionEventStatus({ token })

  const { mutate: giveReward, isPending: isGiveRewardPending } = useMutation({
    mutationFn: async ({ type, amount, rewardName, rewardType }) => {
      await backendApis.giveRewardForSanSinEvent(token, 'PUT', {
        loggingRewardName: rewardName,
        rewardType: type,
        valueAmount: amount,
      })

      await backendApis.recordSellerAdPromotingRewardLog(token, 'PUT', {
        rewardType,
      })
    },
    onSuccess: (_, { type, amount, rewardType }) => {
      showRewardToast(amount, type)

      if (rewardType === REWARD_TYPE.THIRD) {
        AlfarmAdStore.setShowPingForSanSinEvent(null)
      }

      AlfarmAdStore.setGetRendering(!AlfarmAdStore.getRendering)

      queries.forEach((query) => {
        query.refetch()
      })
    },
  })

  const { mutate: createDefaultCPMAdDocument } = useMutation({
    mutationFn: ({ type }) =>
      backendApis.createDefaultCPMAdDocumentByType({ type }),
  })

  const onClickReward = async ({ type, amount, rewardName, rewardType }) => {
    giveReward({
      type,
      amount,
      rewardName,
      rewardType,
    })
  }
  const onClose = () => {
    ModalStore.setIsModalOpen('basic')
  }

  const getMissionStatus = useCallback(
    ({ isClearedToday, adStatus, hasAdStock, type }) => {
      if (isClearedToday) {
        return { status: MISSION_STATUS.COMPLETED }
      }

      if (hasAdStock === false) {
        return { status: MISSION_STATUS.SOLD_OUT }
      }

      const { isIntervalTimeReady, remainedIntervalTime } = checkIntervalTime({
        userAdStatus: adStatus,
        intervalHours:
          UserStore.purchaseFrequency === CPM_PURCHASE_FREQUENCY_TYPES.HEAVY
            ? 4
            : 6,
      })

      if (!isClearedToday && !isIntervalTimeReady) {
        return { status: MISSION_STATUS.TIMER, remainedIntervalTime }
      }

      const { isAdAvailable } = determineAdAvailability({
        hasAdStock,
        hasRemainingViewCounts: true,
        isIntervalTimeReady,
        remainedIntervalTime,
        modalPath: `${type}_goods`,
      })

      if (isAdAvailable) {
        return { status: MISSION_STATUS.AVAILABLE }
      }

      return { status: MISSION_STATUS.LOCKED }
    },
    [UserStore.purchaseFrequency],
  )

  useEffect(() => {
    if (!queries[0].isSuccess || !queries[0]?.data?.length) {
      return
    }

    const filteredAdStatus = queries[0].data.reduce((prev, curr) => {
      if (curr?.adStatus) {
        return prev
      }

      return [...prev, `Alfarm_${curr.missionType}`]
    }, [])

    if (!filteredAdStatus.length) {
      return
    }

    filteredAdStatus.forEach((adType) => {
      createDefaultCPMAdDocument({ type: adType })
    })
  }, [queries[0]?.isSuccess])

  if (queries.some((query) => query.isPending)) {
    return <LoadingIndicator isLoading />
  }

  const userAdStatus = queries[0]?.data || []
  const missionRewardsStatus = queries[1]?.data || []
  const hasAdStock = queries[2]?.data

  return (
    <Dimmed
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <div className='flex-1 flex flex-col justify-center items-center relative'>
        <ModalCloseButton
          onClick={onClose}
          style={{ top: '15vw', right: '5vw' }}
        />
        <div className='flex flex-col gap-[2vw] w-[90vw]'>
          <div className='flex justify-center items-center relative'>
            <img
              src='/farm/SanSinEvent/sansinEventUpperModalNew.png'
              alt='미션 배경 이미지'
            />
            {userAdStatus.map((data, index) => (
              <MissionShortcutButton
                key={data.missionType}
                position={index}
                hasAdStock={hasAdStock}
                type={data.missionType}
                status={
                  getMissionStatus({
                    isClearedToday: data.isClearedToday,
                    adStatus: data.adStatus,
                    hasAdStock,
                    type: data.missionType,
                  }).status
                }
                adStatus={data?.adStatus}
                remainedIntervalTime={
                  getMissionStatus({
                    isClearedToday: data.isClearedToday,
                    adStatus: data.adStatus,
                    hasAdStock,
                    type: data.missionType,
                  })?.remainedIntervalTime || 0
                }
              />
            ))}
          </div>
          <div className='flex justify-center items-center mb-[12vw] relative'>
            <img
              src='/farm/SanSinEvent/sansinEventLowerModalNew.png'
              alt='보상 이미지'
            />
            {missionRewardsStatus.map((rewardType, index) => (
              <MissionRewardButton
                key={rewardType.type}
                position={index}
                type={rewardType.type}
                isLoading={isGiveRewardPending}
                onClick={onClickReward}
                status={rewardType.status}
              />
            ))}
          </div>
        </div>
      </div>
    </Dimmed>
  )
}

export default SansinEventModal
