import React, { useRef, useState, useEffect } from 'react'
import { observer } from 'mobx-react-lite'
import ModalStore from '../../../store/ModalStore'
import AlfarmEventLogger from 'utils/alfarmEventLogger'
import DealAutomationStore from 'store/DealAutomationStore'

// -------중요---------
// 구매 배너 딜마다 바꿔줘야함
// -------------------

const brandImageSrc =
  'https://assets.ilevit.com/75af110b-c2de-4879-8939-2c43bce839fd.png'

//

// -------중요---------
// 구매 배너 딜마다 바꿔줘야함
// -------------------

const GganbuFarmBrandDealModal = observer(
  ({ token, farmData, setFarmData }) => {
    const throttleRef = useRef({})

    const sendToPurchaseScreen = () => {
      AlfarmEventLogger({
        throttleRef,
        locationType: 'modal',
        locationName: ModalStore.isModalOpen,
        eventType: 'click',
        eventName: 'cta',
        collection: 'UserAlfarmClickLog',
      })
      window.location.href = `#generalNavigator.${JSON.stringify({
        params: {
          enteringComponent: 'Alfarm_gganbuFarmBrandDeal',
          reward: '고급비료 5개, 물 1000g',
          title: '올팜 브랜드 초특가',
          webviewPrefixType: 'shop',
          url: '/deals/brand-deal',
          priorityScreen: 'alfarmBallon',
          id: DealAutomationStore?.firstGganbuFarmBalloonAdData?.dealId,
        },
        screen: 'WebviewScreen',
      })}`
      ModalStore.setIsModalOpen('basic')
    }

    return (
      <>
        <div
          style={{
            flex: 1,
            position: 'fixed',
            left: 0,
            top: 0,
            zIndex: 900,
            width: '100%',
            height: '100vh',
            backgroundColor: 'rgba(0,0,0,0.8)',
          }}
        >
          <div
            className='w-[90vw] h-full top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 flex absolute pt-[20vw] justify-center items-center flex-col bg-center bg-contain bg-no-repeat'
            style={{
              backgroundImage: `url(${DealAutomationStore?.firstGganbuFarmBalloonAdData.background})`,
            }}
          >
            <button
              className='-mb-[38vh]'
              style={{
                background: 'rgba(255,253,253,1)',
                borderColor: 'rgba(255,146,164,1)',
                borderWidth: 'medium',
                borderStyle: 'solid',
                width: '55vw',
                height: '14vw',
                borderRadius: 99,
                color: 'black',
                fontSize: '4.5vw',
                fontFamily: 'Maplestory',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                boxShadow: '0px 0px 5px 0px rgba(0,0,0,0.5)',
              }}
              onClick={() => {
                sendToPurchaseScreen()
              }}
            >
              구매하고 보상 받기
            </button>
          </div>
        </div>
        <button
          style={{
            width: '10%',
            transform: 'translate(-50%, -50%)',
            right: '2%',
            top: '-12%',
            position: 'absolute',
            zIndex: 1113,
          }}
          onClick={() => {
            ModalStore.setIsModalOpen('basic')
          }}
        >
          <img
            style={{
              position: 'absolute',
              zIndex: 101,
              top: '70vw',
              right: '1vw',
            }}
            src='/icon/modalCloseButton.png'
            alt=''
          />
        </button>
      </>
    )
  },
)

export default GganbuFarmBrandDealModal
