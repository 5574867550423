import React, { useState, useEffect } from 'react'

const totalFrames = 60
const frameDelays = {
  start: 38, // 시작: 38ms × 20 = 760ms
  middle: 35, // 중간: 35ms × 20 = 700ms
  end: 42, // 끝: 42ms × 20 = 840ms
}

const getFrameDelay = (frameIndex) => {
  if (frameIndex < 20) return frameDelays.start
  if (frameIndex < 40) return frameDelays.middle
  return frameDelays.end
}

const imageStyle = {
  width: '80vw',
  height: '80vw',
  position: 'relative',
}

const YutThrowAnimation = () => {
  const [frameIndex, setFrameIndex] = useState(0)
  const [imagesLoaded, setImagesLoaded] = useState(false)
  const [imageObjects, setImageObjects] = useState([])
  const [loadingProgress, setLoadingProgress] = useState(0)

  const preloadImages = async () => {
    const tempImageObjects = new Array(totalFrames).fill(null)
    let loadedImages = 0

    const loadImage = (index) => {
      return new Promise((resolve) => {
        const image = new Image()
        const imagePath = `/minigame/yutnori/yutThrowMotion/yutThrow${index
          .toString()
          .padStart(2, '0')}.png`

        image.onload = () => {
          loadedImages++
          tempImageObjects[index] = imagePath
          setLoadingProgress((loadedImages / totalFrames) * 100)
          // alert(`Loaded image ${index}: ${imagePath}`)

          if (loadedImages === totalFrames) {
            setImagesLoaded(true)
            setImageObjects(tempImageObjects)
          }
          resolve()
        }

        image.onerror = () => {
          alert(`Failed to load image ${index}: ${imagePath}`)
          tempImageObjects[index] = null
          loadedImages++
          resolve()
        }

        image.src = imagePath
      })
    }

    try {
      const loadPromises = Array.from({ length: totalFrames }, (_, i) =>
        loadImage(i),
      )
      await Promise.all(loadPromises)
    } catch (error) {
      alert('Error loading images:' + error)
    }
  }

  useEffect(() => {
    preloadImages()
  }, [])

  useEffect(() => {
    let timer
    if (imagesLoaded && frameIndex < totalFrames - 1) {
      timer = setTimeout(() => {
        setFrameIndex((prev) => prev + 1)
      }, getFrameDelay(frameIndex))
    }

    return () => {
      if (timer) {
        clearTimeout(timer)
      }
    }
  }, [frameIndex, imagesLoaded, imageObjects])

  if (!imagesLoaded) {
    return (
      <div className='flex flex-col items-center justify-center'>
        <div>로딩중... {loadingProgress.toFixed(1)}%</div>
        <div>
          ({loadingProgress < 100 ? '이미지 로딩 중' : '애니메이션 준비 중'})
        </div>
      </div>
    )
  }

  return (
    <div className='flex items-center justify-center'>
      <img
        src={imageObjects[frameIndex]}
        alt={`윷을 던지고 있어요! (${frameIndex + 1}/${totalFrames})`}
        style={imageStyle}
        className='object-contain'
        onError={(e) => {
          console.error(`Error displaying frame ${frameIndex}:`, e)
        }}
      />
    </div>
  )
}

export default YutThrowAnimation
