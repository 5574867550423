import clsx from 'clsx'
import ModalStore from 'store/ModalStore'
import {
  CPM_GOODS_MODAL_TYPES,
  CPM_READY_MODAL_TYPES,
} from 'utils/alfarmCPMConstant/cpmAdTypes'
import { checkIsTodayWithISODate } from 'utils/utils'

export const MISSION_STATUS = {
  AVAILABLE: 'available',
  COMPLETED: 'completed',
  SOLD_OUT: 'soldOut',
  TIMER: 'timer',
}

const MissionShortcutButton = ({
  type,
  status,
  className,
  adStatus,
  position,
  remainedIntervalTime = 0,
}) => {
  const disabled =
    status === MISSION_STATUS.COMPLETED ||
    status === MISSION_STATUS.SOLD_OUT ||
    status === MISSION_STATUS.TIMER

  const getMarginTopPercentage = (index) => {
    return -23.1 + index * 32.25
  }

  const handleClick = () => {
    const getModalTypeByAdStatus = ({ missionType }) => {
      const readyModals = Object.values(CPM_READY_MODAL_TYPES)

      if (
        readyModals.includes(`${missionType}_ready`) &&
        (!adStatus?.rewardDeterminedAt ||
          !checkIsTodayWithISODate(adStatus?.rewardDeterminedAt) ||
          adStatus?.rewardDeterminedAt <= adStatus?.clearedAt)
      ) {
        return `${missionType}_ready`
      }

      if (
        Object.values(CPM_GOODS_MODAL_TYPES).includes(`${missionType}_goods`)
      ) {
        return `${missionType}_goods`
      }
    }

    const modalName = getModalTypeByAdStatus({
      missionType: type,
    })

    if (modalName) {
      ModalStore.setIsModalOpen(modalName)
    }
  }

  const displayButtonText = () => {
    if (status === MISSION_STATUS.SOLD_OUT) {
      return '광고 마감'
    }

    if (status === MISSION_STATUS.AVAILABLE) {
      return '바로가기'
    }

    if (status === MISSION_STATUS.COMPLETED) {
      return '미션 완료'
    }

    if (status === MISSION_STATUS.TIMER) {
      const remainedHours = Math.floor(remainedIntervalTime / (60 * 60 * 1000))
      const remainedMinutes = Math.floor(
        (remainedIntervalTime % (60 * 60 * 1000)) / (60 * 1000),
      )
      const showRemainedTime =
        remainedHours > 0 ? `${remainedHours} 시간` : `${remainedMinutes} 분`

      return `${showRemainedTime} 남음`
    }
  }

  return (
    <button
      type='button'
      onClick={handleClick}
      className={clsx(
        'absolute w-[20%] h-[8vw] rounded-[5px] font-bold text-[3.5vw] right-[12%]',
        disabled && 'bg-[#94938F] text-white',
        status === MISSION_STATUS.AVAILABLE && 'bg-[#85C836] text-white',
        status === MISSION_STATUS.TIMER && 'bg-[#FFFFFF] !text-[#000000]',
        className,
      )}
      style={{ marginTop: getMarginTopPercentage(position) + '%' }}
      disabled={disabled}
    >
      {displayButtonText()}
    </button>
  )
}

export default MissionShortcutButton
