import React, { useEffect, useState } from 'react'
import ModalStore from 'store/ModalStore'
import './MysteryFlowerGameModalStyle.css'
import { Transition } from 'react-transition-group'
import backendApis from 'utils/backendApis'
import commaNumber from 'comma-number'
let water1, water10

import toast from 'react-hot-toast'
import useCheckCodePushVersion from 'utils/customHooks/useConvertCodePushVersion'
import UserStore from 'store/UserStore'
import { observer } from 'mobx-react-lite'
import LottieGif from '../LottieGif'
import { sleep } from 'utils/utils'

const animationTiming = {
  enter: 400,
  exit: 500,
}

const rewardTypeMap = {
  water: '물',
  fertilizer: '일반비료',
  fertilizerSet: '고급비료',
}

const rewardUnitMap = {
  water: 'g',
  fertilizer: '개',
  fertilizerSet: '개',
}

const REWARDS = ['water', 'fertilizer', 'fertilizerSet']

const TITLEMAP = [
  '조금 아쉽네요..',
  '축하합니다!',
  '축하합니다!!!',
  '축하합니다!',
]

const Title = ({ text }) => (
  <div className='w-full text-white text-[9vw] font-bold  '>{text}</div>
)

const Button = ({ color, text, subText, onClick }) => {
  switch (color) {
    case 'blue':
      return (
        <button
          className='flex-1 h-[16vw] bg-gradient-to-b from-[#2BD9F1] to-[#00A5E1] text-white text-[4.5vw] font-bold flexCol border-[#006278] border-[1vw] border-solid rounded-xl active:scale-95 '
          onClick={(e) => {
            e.stopPropagation()
            onClick()
          }}
        >
          {text}
          <div className='text-[3.5vw]'>{subText}</div>
        </button>
      )
    case 'green':
      return (
        <button
          className='flex-1 h-[16vw] bg-gradient-to-b from-[#9DE64E] to-[#65C001] text-white text-[4.5vw] font-bold flexCol border-[#366600] border-[1vw] border-solid rounded-xl active:scale-95'
          onClick={(e) => {
            e.stopPropagation()
            onClick()
          }}
        >
          {text}
          <div className='text-[3.5vw]'>{subText}</div>
        </button>
      )
    case 'gray':
    default:
      return (
        <button
          className='flex-1 h-[16vw] bg-gradient-to-b from-[#BFBFBF] to-[#A7A7A7] text-white text-[4.5vw] font-bold flexCol border-[#4C4C4C] border-[1vw] border-solid rounded-xl'
          onClick={(e) => e.stopPropagation()}
        >
          {text}
          <div className='text-[3.5vw]'>{subText}</div>
        </button>
      )
  }
}

const HOF = ({ hofRefreshToggle }) => {
  const [hallOfFame, setHallOfFame] = useState([])
  const [translation, setTranslation] = useState(0)
  const [isCirculatedOneFullCycle, setIsCirculatedOneFullCycle] =
    useState(false)
  useEffect(() => {
    async function getMysteryFlowerHOF() {
      const res = await backendApis.getMysteryFlowerHOF()
      if (res.data.length) {
        setHallOfFame([...res.data, res.data[0]])
      }
    }
    getMysteryFlowerHOF()
  }, [hofRefreshToggle])

  useEffect(() => {
    if (hallOfFame.length) {
      if (translation === hallOfFame.length - 1) {
        setTimeout(() => {
          setTranslation(0)
          setIsCirculatedOneFullCycle(true)
        }, 1000)
        return
      }
      if (translation === 0 && isCirculatedOneFullCycle) {
        setTimeout(() => {
          setTranslation(1)
        }, 2000)
        return
      }
      const intervalId = setInterval(() => {
        setTranslation((prev) => (prev + 1) % hallOfFame.length)
      }, 4000)
      return () => clearInterval(intervalId)
    }
  }, [hallOfFame.length, translation, isCirculatedOneFullCycle])

  return (
    <div
      className={`w-full h-[100vh] flex flex-col items-center justify-start gap-[1vw]`}
      style={{
        transition: translation === 0 ? '' : `transform 1s ease-in-out`,
        transform: `translateY(-${translation * 8}vw)`,
      }}
    >
      {hallOfFame?.length ? (
        hallOfFame?.map((hof, index) => (
          <div
            key={hof._id + index}
            className='flex items-center justify-center gap-[1vw]'
          >
            <img
              src='/mysteryFlowerGame/medal.svg'
              className='w-[7vw] h-[7vw]'
            />
            <span className='text-white text-[4vw]'>
              {hof.userName?.substr(0, 6)}님이{' '}
              <span className='font-semibold'>
                {rewardTypeMap?.[hof.rewardType]}{' '}
                {commaNumber(hof.rewardAmount)}
                {rewardUnitMap?.[hof.rewardType]}
              </span>{' '}
              획득! 축하드려요!
            </span>
          </div>
        ))
      ) : (
        <div className='flex items-center justify-center gap-[1vw]'>
          <img src='/mysteryFlowerGame/medal.svg' className='w-[7vw] h-[7vw]' />
          <span className='text-white text-[4vw]'>신비한 화분 명의의 전당</span>
        </div>
      )}
    </div>
  )
}

const Modal = observer((props) => {
  const [screen, setScreen] = useState('main')
  const [isLoading, setIsLoading] = useState(false)
  const [isShowAnimation, setIsShowAnimation] = useState(0) // 0, 1, 10
  const [currentReward, setCurrentReward] = useState({})
  const [hofRefreshToggle, setHofRefreshToggle] = useState(false)
  const [todayAdWatchCount, setTodayAdWatchCount] = useState(10)
  const canWatchReward = UserStore?.isRewardAdReady
  useEffect(() => {
    import('../../../json/lottie/water1.json').then((module) => {
      water1 = module.default
    })
    import('../../../json/lottie/water10.json').then((module) => {
      water10 = module.default
    })
  }, [])
  async function handleWater(numWater = 1) {
    if (isLoading) return
    try {
      setIsLoading(true)
      setIsShowAnimation(numWater)
      const [res] = await Promise.all([
        backendApis.waterMysteryFlower({ numWater }),
        new Promise((resolve) => setTimeout(resolve, 4500)),
      ])
      if (res.data.success) {
        setScreen('success')
        setCurrentReward(res.data.reward)
        if (res.data.reward.code === 2) {
          setHofRefreshToggle((prev) => !prev)
        }
        props.setFarmData((prev) => ({
          ...prev,
          water: prev.water - numWater * 10 + res.data.reward.water,
          fertilizer: prev.fertilizer + res.data.reward.fertilizer,
          fertilizerSet: prev.fertilizerSet + res.data.reward.fertilizerSet,
        }))
      } else {
        props.setFarmData((prev) => ({
          ...prev,
          water: prev.water - numWater * 10,
        }))
        setScreen('fail')
      }
    } catch (error) {
      console.log(error)
      toast.error('다시 시도해 주세요.')
    } finally {
      setIsShowAnimation(0)
      setIsLoading(false)
    }
  }

  async function getTodayAdWatchCount() {
    const res = await backendApis.getTodayMysteryFlowerAdWatchCount()
    setTodayAdWatchCount(res.data)
  }

  useEffect(() => {
    getTodayAdWatchCount()
  }, [])

  async function handleFinishAdWatch() {
    await sleep(1500)
    getTodayAdWatchCount()
    const res = await backendApis.getAlfarmUserData()
    props.setFarmData((prev) => ({
      ...prev,
      water: res.data.water,
      fertilizer: res.data.fertilizer,
      fertilizerSet: res.data.fertilizerSet,
    }))
    toast.success('광고 시청 보상을 받았어요!')
    UserStore.setIsWatchingAd(false)
  }
  useEffect(() => {
    if (UserStore.isWatchingAd) {
      handleFinishAdWatch()
    }
  }, [UserStore.isWatchingAd])

  return (
    <Transition
      in={props.show}
      timeout={animationTiming}
      mountOnEnter
      unmountOnExit
    >
      {(state) => {
        const cssClasses = [
          'Modal',
          state === 'entering'
            ? 'ModalOpen'
            : state === 'exiting'
            ? 'ModalClosed'
            : null,
        ]
        return (
          <div className={cssClasses.join(' ')}>
            {!isLoading && (
              <img
                src='/mysteryFlowerGame/Cancel.svg'
                className='absolute top-[-10vw] right-[0vw] w-[8vw] h-[8vw]'
                onClick={props.closed}
              />
            )}
            <div className='relative top-[5vw] left-0 w-full h-[8vw] overflow-hidden '>
              <HOF hofRefreshToggle={hofRefreshToggle} />
            </div>
            <div className='relative top-[20vw] w-full h-[120vw] flex flex-col items-center justify-between gap-[2vw] px-[6vw] pb-[4vw]'>
              {/* main section */}
              {(() => {
                switch (screen) {
                  case 'fail':
                    return (
                      <>
                        <Title text='아쉬워요...' />
                        {/* info section */}
                        <div className='flex-1 w-full flex flex-col items-center justify-between  '>
                          <div className='text-[4.5vw] text-white'>
                            보상을 획득하지 못했어요 <br />
                            다시 한 번 도전해 보세요!
                          </div>
                          <img
                            src='/mysteryFlowerGame/flower-fail.png'
                            className='w-[55vw] mb-[4vw]'
                          />
                        </div>
                        <div className='w-full flex items-center justify-center'>
                          <Button
                            color='blue'
                            text='다시 도전하기'
                            subText='(10g)'
                            onClick={() => {
                              setScreen('main')
                              handleWater(1)
                            }}
                          />
                        </div>
                      </>
                    )

                  case 'success':
                    return (
                      <>
                        <Title text={TITLEMAP[currentReward.code]} />
                        <div className='flex-1 w-full flex flex-col items-center justify-around '>
                          <div className='text-[4.5vw] text-white flex flex-col items-center'>
                            {currentReward.code !== 3 && (
                              <span>{`${
                                rewardTypeMap?.[currentReward?.type]
                              } ${commaNumber(currentReward?.amount)}${
                                rewardUnitMap?.[currentReward?.type]
                              }를 획득했어요.`}</span>
                            )}
                            {currentReward.code === 0 && (
                              <span>한번 더 도전해보세요!</span>
                            )}
                            {currentReward.code === 1 && canWatchReward && (
                              <span>
                                광고를 보면 2배로 보상 받을 수 있어요!
                              </span>
                            )}
                            {currentReward.code === 2 && (
                              <span>명의의 전당에서 이름을 확인하세요!</span>
                            )}
                          </div>
                          <img
                            src='/mysteryFlowerGame/flower-success.png'
                            className='w-[55vw]'
                          />
                          <div className='w-[60vw] flexCol p-[2vw] bg-[#786F93] rounded-xl'>
                            {REWARDS.map((reward) => {
                              if (currentReward[reward] <= 0) return null
                              return (
                                <div
                                  key={reward}
                                  className='w-full flex items-center justify-center gap-[1vw]'
                                >
                                  <img
                                    src={`/mysteryFlowerGame/${reward}.svg`}
                                    className='w-[5vw] h-[5vw]'
                                  />
                                  <span className='text-white text-[4vw]'>
                                    {rewardTypeMap?.[reward]}{' '}
                                    {currentReward[reward]}
                                    {rewardUnitMap?.[reward]}
                                  </span>
                                </div>
                              )
                            })}
                          </div>
                        </div>
                        <div className='w-full flex items-center justify-center'>
                          {currentReward.code === 0 ? (
                            <Button
                              color='blue'
                              text='다시 도전하기'
                              subText='(10g)'
                              onClick={() => {
                                setScreen('main')
                                handleWater(1)
                              }}
                            />
                          ) : (
                            <Button
                              color='blue'
                              text='보상 받기'
                              onClick={() => {
                                setScreen('main')
                              }}
                            />
                          )}
                          {currentReward.code === 1 && canWatchReward && (
                            <Button
                              color={todayAdWatchCount > 10 ? 'gray' : 'green'}
                              text='광고보고 2배 받기'
                              subText={`(${Math.max(
                                0,
                                10 - todayAdWatchCount,
                              )}/10)`}
                              onClick={() => {
                                window.ReactNativeWebView?.postMessage(
                                  JSON.stringify({
                                    type: 'showRewardedAd',
                                    data: {
                                      adType: 'rewarded',
                                      placement: 'ALFARM_MYSTERY_FLOWER_REWARD',
                                    },
                                  }),
                                )
                                setTimeout(() => setScreen('main'), 1000)
                              }}
                            />
                          )}
                        </div>
                      </>
                    )

                  case 'main':
                  default: {
                    return (
                      <>
                        <Title text='신비한 화분' />
                        {/* info section */}
                        <div className='relative flex-1 w-full flex flex-col items-center justify-between  '>
                          <div className='text-[4.5vw] text-white'>
                            물을 주면 랜덤 확률로 <br />
                            물, 비료 보상을 받아요!
                          </div>
                          {isShowAnimation ? (
                            <div className='absolute w-[62.5vw] bottom-[-10.7vw] left-[7.6vw]'>
                              <LottieGif
                                isClickToPauseDisabled={true}
                                animationData={
                                  isShowAnimation === 1 ? water1 : water10
                                }
                              />
                            </div>
                          ) : (
                            <img
                              src='/mysteryFlowerGame/flower-basic.png'
                              className='w-[55vw] mb-[4vw]'
                            />
                          )}
                        </div>
                        <div className='w-full flex items-center justify-center'>
                          <Button
                            color={
                              props.farmData.water >= 10 && !isLoading
                                ? 'blue'
                                : 'gray'
                            }
                            text='물 1번 주기'
                            subText='(10g)'
                            onClick={() => handleWater(1)}
                          />
                          <Button
                            color={
                              props.farmData.water >= 100 && !isLoading
                                ? 'blue'
                                : 'gray'
                            }
                            text='물 10번 주기'
                            subText='(100g)'
                            onClick={() => handleWater(10)}
                          />
                        </div>
                      </>
                    )
                  }
                }
              })()}
            </div>
          </div>
        )
      }}
    </Transition>
  )
})

export const MysteryFlowerGameModal = ({ farmData, setFarmData }) => {
  const [modalIsOpen, setModalIsOpen] = useState(false)

  useEffect(() => {
    window.ReactNativeWebView?.postMessage(
      JSON.stringify({
        type: 'prepareRewardedAd',
        data: {
          adType: 'rewarded',
          placement: 'ALFARM_MYSTERY_FLOWER_REWARD',
        },
      }),
    )

    setModalIsOpen(true)
  }, [])
  return (
    <div style={styles.background}>
      <Modal
        farmData={farmData}
        setFarmData={setFarmData}
        show={modalIsOpen}
        closed={() => {
          setModalIsOpen(false)
          setTimeout(() => ModalStore.setIsModalOpen('default'), 500)
        }}
      />
    </div>
  )
}

const styles = {
  background: {
    flex: 1,
    position: 'fixed',
    left: 0,
    top: 0,
    zIndex: 900,
    width: '100%',
    height: '100vh',
    backgroundColor: 'rgba(0,0,0,0.8)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  closeButton: {
    width: '8vw',
    right: '2vw',
    top: '-6vw',
    position: 'absolute',
    zIndex: 999,
  },
}
