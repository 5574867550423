import CPMBottomSheetButton from './CPMBottomSheetButton'
import { getCPMButtonPropsByType } from 'utils/alfarmCPMConstant/cpmAdConfig'
import { CPM_ENTERING_COMPONENTS } from 'utils/alfarmCPMConstant/cpmAdTypes'

// 모달 만들고 farm.jsx 통해서 연결해야 함
const TodayDealAdButton = ({ closeBottomSheet }) => {
  const ENTERING_COMPONENT = CPM_ENTERING_COMPONENTS.TODAY_DEAL_AD
  const BUTTON_PROPS = getCPMButtonPropsByType(ENTERING_COMPONENT)

  return (
    <CPMBottomSheetButton
      ENTERING_COMPONENT={ENTERING_COMPONENT}
      BUTTON_PROPS={BUTTON_PROPS}
      closeBottomSheet={closeBottomSheet}
    />
  )
}

export default TodayDealAdButton
